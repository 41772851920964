import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/InfoTooltip';

type MonitoringStepProps = {
  isActive: boolean;
  isDefault: boolean;
  onClick: () => void;
  icon: any;
  label: string;
  tooltipId: string;
  tooltipMessage: string;
};

export const MonitoringMoistureTypeButton = ({
  isActive,
  isDefault,
  onClick,
  icon,
  label,
  tooltipId,
  tooltipMessage,
}: MonitoringStepProps) => {
  const { t } = useTranslation('components');

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="relative w-full h-5 text-center overflow-visible">
        {isActive && !isDefault && (
          <div className="absolute top-0 left-1 text-xs text-brand-orange space-x-1 whitespace-nowrap">
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span>{t('buttons.MonitoringMoistureTypeButton.defaultMonitoringTypeWarning')}</span>
          </div>
        )}
      </div>
      <div
        className={classNames(
          'flex relative justify-between items-center gap-4 cursor-pointer px-4 py-2 rounded-full text-sm bg-white text-brand-blue',
          {
            'ring-4 ring-brand-blue': isActive && isDefault,
            'ring-2 ring-brand-blue-light-1': !isActive,
            'ring-4 ring-brand-orange': isActive && !isDefault,
          },
        )}
        onClick={onClick}
      >
        {!isActive && (
          <div className="absolute inset-0 bg-brand-blue-light-2 opacity-40 rounded-full"></div>
        )}
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={icon} className="text-xl text-brand-blue" />
          <span>{label}</span>
        </div>
        <InfoTooltip id={tooltipId} message={tooltipMessage} />
      </div>
    </div>
  );
};
