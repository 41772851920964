import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import { MutableRefObject } from 'react';

interface SensorSearchProps {
  onSearch: (search: string) => void;
  placeholderText: string;
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

export const BlueprintSearchBar: React.FC<SensorSearchProps> = ({
  onSearch,
  placeholderText,
  inputRef,
}) => (
  <div className="flex items-center w-full text-xs py-2 pt-1 px-2 bg-white rounded focus-within:outline-brand-blue focus-within:outline focus-within:outline-offset-[-2px]">
    <FontAwesomeIcon className="mr-1" icon={faSearch} />
    <input
      ref={inputRef}
      className="h-full grow focus:outline-none"
      placeholder={placeholderText}
      onChange={e => debounce(() => onSearch(e.target.value), 250)()}
    />
  </div>
);
