import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useCustomerAlertRuleSensors } from 'utils/hooks/data/customers';
import { useUserAlertRuleSensors } from 'utils/hooks/data/users';
import HookOptions from 'utils/types/HookOptions';

export const useUserAndCustomerAlertRuleSensors = (
  userId?: string,
  customerId?: string,
  alarmRuleId?: string,
  hookOptions: HookOptions = {},
) => {
  const resultUser = useUserAlertRuleSensors(userId, alarmRuleId, hookOptions);
  const resultCustomer = useCustomerAlertRuleSensors(customerId, alarmRuleId, {
    ignoreAdminCustomerOverwrite: true,
    ...hookOptions,
  });

  const sensors = useMemo(
    () =>
      // We merge the sensors from the user and the customer and remove duplicates.
      uniqBy([...(resultUser.sensors || []), ...(resultCustomer.sensors || [])], 'id'),
    [resultUser.sensors, resultCustomer.sensors],
  );

  return {
    sensors,
    isPending: resultUser.isPending || resultCustomer.isPending,
  };
};
