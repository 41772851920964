import ProgressBar from '@ramonak/react-progress-bar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from 'components/Spinner';
import { brandBlue, brandGrayLight3 } from 'utils/colors';

enum RiskScoreStages {
  LoadingData,
  RunningAICalculations,
  CalculatingRiskAreas,
  GeneratingReport,
  Done,
}

export const RiskScoreLoading = () => {
  const { t } = useTranslation('components');

  const [elipses, setElipses] = useState('');
  const [stage, setStage] = useState(RiskScoreStages.LoadingData);
  const [progress, setProgress] = useState(10);

  const runTimeMs = 15000;
  const finalStretchRuntime = runTimeMs * 0.1;

  const nStages = RiskScoreStages.Done;

  useEffect(() => {
    const interval = setInterval(() => {
      setElipses(prev => (prev.length < 3 ? `${prev}.` : ''));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    for (let i = 1; i < nStages + 1; i++) {
      timeouts.push(
        setTimeout(
          () => {
            setStage(i as RiskScoreStages);
            setProgress((i / nStages) * 100);
            if (i === RiskScoreStages.Done) {
              setProgress(90);
            }
          },
          (runTimeMs / nStages) * i,
        ),
      );
    }
    for (let i = 1; i <= 9; i++) {
      // When we get to 90% start creeping up towards 100% slowly
      // This way if its takes longer than the initial stage time it still
      // looks like its doing something
      timeouts.push(
        setTimeout(
          () => {
            setProgress(90 + i);
          },
          runTimeMs + finalStretchRuntime * i,
        ),
      );
    }
    return () => timeouts.forEach(clearTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingText = (() => {
    switch (stage) {
      case RiskScoreStages.LoadingData:
        return t('blueprints.BlueprintCanvas.RiskScoreLoading.LoadingData');
      case RiskScoreStages.RunningAICalculations:
        return t('blueprints.BlueprintCanvas.RiskScoreLoading.RunningAICalculations');
      case RiskScoreStages.CalculatingRiskAreas:
        return t('blueprints.BlueprintCanvas.RiskScoreLoading.CalculatingRiskAreas');
      case RiskScoreStages.GeneratingReport:
        return t('blueprints.BlueprintCanvas.RiskScoreLoading.GeneratingReport');
      case RiskScoreStages.Done:
        // We'll just hang on this one untill the component is hidden
        // by the data actually loading in the parent canvas
        return t('blueprints.BlueprintCanvas.RiskScoreLoading.GeneratingReport');
    }
  })();

  return (
    <div className="flex flex-col items-center justify-center absolute h-full w-5/6 bg-brand-gray-light-4/75 gap-4">
      <Spinner className="text-brand-blue border-brand-blue-light-1" />
      <ProgressBar
        className="w-1/3 h-fit"
        completed={progress}
        isLabelVisible={false}
        height="5px"
        baseBgColor={brandGrayLight3}
        bgColor={brandBlue}
        animateOnRender={true}
      />
      <div className="flex flex-row w-1/4 justify-center text-brand-blue text-sm">
        <div>{loadingText}</div>
        <div className="w-1">{elipses}</div>
      </div>
    </div>
  );
};
