import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import { SidebarAddSensors } from 'components/BlueprintCanvas/components/Sidebar/SidebarAddSensors/SidebarAddSensors';
import { BlueprintSidebarContext } from 'components/BlueprintCanvas/components/Sidebar/SidebarContext';
import { BlueprintSidebarSensor } from 'components/BlueprintCanvas/components/Sidebar/SidebarSensorRow';
import { ControlledSwitch } from 'components/inputs';
import Spinner from 'components/Spinner';
import SensorPin from 'images/tector-logo-pin.png';
import { brandBlue, brandGrayLight1, brandGreen } from 'utils/colors';
import Permission from 'utils/enums/Permission';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useUserAndCustomerSensors } from 'utils/hooks/data';
import { sortSensors } from 'utils/sensor/sort';
import { BlueprintPosition } from 'utils/types';
import Sensor from 'utils/types/Sensor';

export const BlueprintSidebar: React.FC<{
  sensorsAttachedToBlueprint?: Sensor[];
  positions?: BlueprintPosition[];
  moisturePlotEnabled: boolean;
  setMoisturePlotEnabled: Dispatch<SetStateAction<boolean>>;
  waitingForPositionUpdate?: boolean;
}> = ({
  sensorsAttachedToBlueprint = [],
  positions = [],
  moisturePlotEnabled,
  setMoisturePlotEnabled,
  waitingForPositionUpdate = false,
}) => {
  // Context
  const {
    editModeEnabled,
    setHiddenBlueprintPositionsIds,
    blueprintHeight,
    editModeTooltip,
    setEditModeTooltip,
    isFullscreen,
  } = useContext(BlueprintCanvasContext);

  // State
  const [useScrollbarInSensorList, setUseScrollbarInSensorList] = useState(false);
  const [useScrollbarInHelpText, setUseScrollbarInHelpText] = useState(false);

  const dragImageRef = useRef<HTMLDivElement>(null);
  const sensorsContainerRef = useRef<HTMLDivElement>(null);
  const helpTextContainerRef = useRef<HTMLDivElement>(null);

  // Hooks
  const { t } = useTranslation('components');
  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { sensors: sensorsWithCurrentUserAccess } = useUserAndCustomerSensors(userId, customerId, {
    // We pass down all sensors the user have access to and then let the child components filter out any sensors
    // the user don't have sufficient permissions for. This is to improve transparency and user experience.
    permission: Permission.View,
    // `includeAllSensors` includes sensors user has access to from other customers as well.
    includeAllSensors: true,
    // We need the geographic location to show the description in the sensors select dropdown
    includeGeographicLocation: true,
  });

  const isMutatingBlueprints = useIsMutating({ mutationKey: ['blueprintSensors'] });

  const activeSensorMap = new Map<string, boolean>();
  const sensorPositionMap = new Map<string, BlueprintPosition | undefined>();
  sensorsAttachedToBlueprint.forEach(sensor => {
    const matchedPosition = positions.find(position => position.sensor_id === sensor.id);
    sensorPositionMap.set(sensor.id, matchedPosition);
    activeSensorMap.set(sensor.id, matchedPosition?.isPositionDefined ?? false);
  });
  sensorsAttachedToBlueprint = sortSensors(sensorsAttachedToBlueprint);
  useEffect(() => {
    // We do this here rather than in the SidebarSensor component
    // to avoid race conditions when removing individual sensors from the state array
    setHiddenBlueprintPositionsIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModeEnabled]);

  // These effects show/hide the scrollbar when needed. This is used instead of overflow-y-auto
  // to prevent content jumping inside the containers when the scrollbar pops in/out.
  useEffect(() => {
    if (!sensorsContainerRef.current) return;
    const { scrollHeight, clientHeight } = sensorsContainerRef.current;
    setUseScrollbarInSensorList(scrollHeight >= clientHeight);
  }, [sensorsContainerRef.current?.scrollHeight]);

  useEffect(() => {
    if (!helpTextContainerRef.current) return;
    const { scrollHeight, clientHeight } = helpTextContainerRef.current;
    setUseScrollbarInHelpText(scrollHeight >= clientHeight);
  }, [helpTextContainerRef.current?.scrollHeight]);

  return (
    <div
      className="flex flex-col relative bg-brand-green grow"
      style={isFullscreen ? { maxHeight: blueprintHeight } : {}}
    >
      <BlueprintSidebarContext.Provider value={{ dragImageRef }}>
        {/* Loading blocker, covers the sidebar when we're mutating the blueprint*/}
        {(isMutatingBlueprints > 0 || waitingForPositionUpdate) && (
          <div className="absolute w-full h-full bg-brand-green-light-3/50 text-brand-blue z-50 flex items-center justify-center">
            <Spinner className="text-brand-blue border-brand-blue-light-1" />
          </div>
        )}
        {/* Title */}
        <div className="w-full min-h-[5%] max-h-[7.5%] border-b border-brand-green-light-1 px-2 py-2 text-brand-beige font-semibold overflow-hidden">
          {t('blueprints.Sidebar.title')}
        </div>
        {/* Instrucitons/help text */}
        <div
          className={classNames(
            'relative duration-1000 transition-[height] border-brand-green-light-1',
            {
              'h-0 border-b-0': !editModeEnabled,
              'h-[17.5%] border-b': editModeEnabled,
            },
          )}
        >
          <div
            ref={helpTextContainerRef}
            className={classNames(
              'flex h-[calc(100%-theme(space.2))] text-[0.7rem] text-brand-beige items-center gap-2 my-1 overflow-x-hidden overflow-y-auto px-2',
              '[&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full',
              '[&::-webkit-scrollbar-track]:bg-brand-green [&::-webkit-scrollbar-track]:rounded-full',
              {
                '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3': useScrollbarInHelpText,
                '[&::-webkit-scrollbar-thumb]:bg-transparent': !useScrollbarInHelpText,
              },
            )}
            style={{
              scrollbarColor: `${brandGrayLight1} ${brandGreen}`,
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {editModeTooltip}
          </div>
        </div>
        {/* Attached sensors list */}
        <div
          ref={sensorsContainerRef}
          className={classNames(
            'relative overflow-y-auto transition-max-height duration-1000',
            '[&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full',
            '[&::-webkit-scrollbar-track]:bg-brand-green [&::-webkit-scrollbar-track]:rounded-full',
            {
              '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3': useScrollbarInSensorList,
              '[&::-webkit-scrollbar-thumb]:bg-transparent': !useScrollbarInSensorList,
              'max-h-[30%]': editModeEnabled,
              'max-h-[80%]': !editModeEnabled,
            },
          )}
          style={{
            scrollbarColor: `${brandGrayLight1} ${useScrollbarInSensorList ? brandGreen : 'transparent'}`,
          }}
        >
          {sensorsAttachedToBlueprint.map(sensor => {
            let sensorPosition = undefined;
            if (sensorPositionMap.get(sensor.id)) {
              sensorPosition = sensorPositionMap.get(sensor.id);
            }
            return (
              <BlueprintSidebarSensor
                key={`blueprint-sensor-row-${sensor.id}`}
                sensorId={sensor.id}
                sensorDescription={sensor.geographic_location?.description || ''}
                sensorName={sensor.name}
                blueprintPosition={sensorPosition!}
                isAttached={activeSensorMap.get(sensor.id) ?? false}
              />
            );
          })}
        </div>
        {/* Toggles */}
        <div
          className={classNames('transition-[height] overflow-hidden', {
            'h-24': !editModeEnabled,
            'h-0': editModeEnabled,
          })}
        >
          <div className="text-brand-beige text-sm py-1 px-2 my-2 border-t border-brand-green-light-1 flex-none">
            {t('blueprints.Sidebar.toggles.header')}
          </div>
          <div
            className={classNames(
              'flex flex-row-reverse mt-2 px-4 w-full transition-[height, opacity] duration-150 items-center justify-start gap-2',
            )}
          >
            <ControlledSwitch
              isChecked={moisturePlotEnabled}
              setIsChecked={() => {
                setMoisturePlotEnabled(!moisturePlotEnabled);
              }}
              activeColor={brandBlue}
            />
            <div className="text-brand-beige text-xs w-2/3 grow overflow-wrap">
              {t('blueprints.Sidebar.toggles.moisturePlot')}
            </div>
          </div>
        </div>
        {/* Adding sensors list */}
        <div
          className={classNames('mb-2 grow transition-[height] duration-700', {
            'h-2/5': editModeEnabled,
            'h-0': !editModeEnabled,
          })}
          onMouseEnter={() => setEditModeTooltip(t('blueprints.Sidebar.tooltips.attachSensors'))}
          onMouseLeave={() => setEditModeTooltip(t('blueprints.Sidebar.tooltips.positionSensors'))}
        >
          <SidebarAddSensors
            userSensors={sensorsWithCurrentUserAccess}
            attachedSensors={sensorsAttachedToBlueprint}
          />
        </div>
      </BlueprintSidebarContext.Provider>
      {/* Looks a bit funky with the -translate-y-[%10000], but this is used as the ghost image when 
        we're dragging sensors from the sidebar to the blueprint. */}
      <div ref={dragImageRef} className="w-10 h-fit absolute -translate-y-[10000%]">
        <img src={SensorPin} alt="tector sensor icon" />
      </div>
    </div>
  );
};
