import { t } from 'translations';
import { SensorTagMeasurementPlacement } from 'utils/enums';

export const getSensorTagMeasurementPlacementText = (
  sensorTagMeasurementPlacement: SensorTagMeasurementPlacement,
) => {
  switch (sensorTagMeasurementPlacement) {
    case SensorTagMeasurementPlacement.InternalWMCInternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementText.InternalWMCInternalRHT',
      );
    case SensorTagMeasurementPlacement.InternalWMCExternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementText.InternalWMCExternalRHT',
      );
    case SensorTagMeasurementPlacement.ExternalWMCInternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementText.ExternalWMCInternalRHT',
      );
    case SensorTagMeasurementPlacement.ExternalWMCExternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementText.ExternalWMCExternalRHT',
      );
    default:
      return 'n/a';
  }
};

export const getSensorTagMeasurementPlacementTextShort = (
  sensorTagMeasurementPlacement: SensorTagMeasurementPlacement,
) => {
  switch (sensorTagMeasurementPlacement) {
    case SensorTagMeasurementPlacement.InternalWMCInternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementTextShort.InternalWMCInternalRHT',
      );
    case SensorTagMeasurementPlacement.InternalWMCExternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementTextShort.InternalWMCExternalRHT',
      );
    case SensorTagMeasurementPlacement.ExternalWMCInternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementTextShort.ExternalWMCInternalRHT',
      );
    case SensorTagMeasurementPlacement.ExternalWMCExternalRHT:
      return t(
        'utils:texts.sensor-tags.getSensorTagMeasurementPlacementTextShort.ExternalWMCExternalRHT',
      );
    default:
      return 'n/a';
  }
};
