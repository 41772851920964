import { sortBy } from 'lodash';

import Sensor from 'utils/types/Sensor';

export const sortSensors = (sensors: Sensor[]): Sensor[] => {
  // Extract woodys and flattys and sort them by their number first, then the name
  // if we can't find a number
  const flattys = sensors.filter(sensor => sensor.name.includes('flatty'));
  const woodys = sensors.filter(sensor => sensor.name.includes('woody'));
  const sortedFlattys = sortSensorsByNumber(flattys);
  const sortedWoodys = sortSensorsByNumber(woodys);

  // Sort the rest of the sensors by name with placeholders so we can splice
  // The sorted woodys and flattys
  const sortedOthers = sortBy(
    [
      'flatty_placeholder',
      'woody_placeholder',
      ...sensors.filter(
        sensor => !sensor.name.includes('woody') && !sensor.name.includes('flatty'),
      ),
    ],
    sensor => ((sensor as Sensor).name ? (sensor as Sensor).name : (sensor as string)),
  );

  // Insert
  const flattyIndex = sortedOthers.findIndex(sensor => sensor === 'flatty_placeholder');
  sortedOthers.splice(flattyIndex, 1, ...sortedFlattys);

  const woodyIndex = sortedOthers.findIndex(sensor => sensor === 'woody_placeholder');
  sortedOthers.splice(woodyIndex, 1, ...sortedWoodys);

  // Casting is fine here since we know the only strings are the placeholders, and we've replaced them
  return sortedOthers as Sensor[];
};

const sortSensorsByNumber = (sensors: Sensor[]): Sensor[] => {
  const { numberedSensorsMap, sensorsWithoutNumbers } = sensors.reduce(
    (
      result: { numberedSensorsMap: Map<number, Sensor>; sensorsWithoutNumbers: Sensor[] },
      sensor,
    ) => {
      const sensorNumberString = sensor.name.match(/\d+/);
      if (sensorNumberString) {
        result.numberedSensorsMap.set(parseInt(sensorNumberString[0], 10), sensor);
      } else {
        result.sensorsWithoutNumbers.push(sensor);
      }
      return result;
    },
    { numberedSensorsMap: new Map<number, Sensor>(), sensorsWithoutNumbers: [] },
  );

  const sortedNumbers = Array.from(numberedSensorsMap.keys()).sort((a, b) => a - b);
  const sortedSensorsWithoutNumbers = sortBy(sensorsWithoutNumbers, sensor => sensor.name);
  return sortedNumbers
    .map(number => numberedSensorsMap.get(number)!)
    .concat(sortedSensorsWithoutNumbers);
};
