import { Updater } from '@tanstack/react-table';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sm } from 'utils/breakpoints';
import { useWindowSize } from 'utils/hooks';

export const Pagination: React.FC<{
  colspan?: number;
  pageCount: number;
  currentPage: number;
  setPageIndex: (updater: Updater<number>) => void;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  dataLength: number;
  possiblePageSizes?: number[];
  classNamePaginationControls?: string;
}> = ({ pageCount, currentPage, setPageIndex, getCanPreviousPage, getCanNextPage, pageSize }) => {
  const { t } = useTranslation('components');

  const [windowWidth] = useWindowSize();
  const isMobileView = windowWidth < sm;

  // The pagination index starts from 1
  const getVisiblePages = useCallback(
    (page: number, pageCount: number) => {
      if (isMobileView) {
        // On mobile view only the current page is displayed
        return [page];
      } else {
        if (pageCount < 7) {
          return [1, 2, 3, 4, 5, 6].filter(page => page <= pageCount);
        } else {
          if (page > 4 && page + 2 < pageCount) {
            // If the current page is at least 5, and there are at least 2 pages remaining after the current page
            // Show the current page and its neighboring pages along with first and last pages
            return [1, -1, page - 1, page, page + 1, -1, pageCount];
          } else if (page > 4 && page + 2 >= pageCount) {
            // If the current page is at least 5, but fewer than 2 pages remaining after the current page
            // Show the last 4 pages along with the first page
            return [1, -1, pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
          } else {
            // For all other cases, show pages 1 to 4 and the last page as visible pages
            return [1, 2, 3, 4, 5, -1, pageCount];
          }
        }
      }
    },
    [isMobileView],
  );

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(currentPage, pageCount),
  );

  // The table currentPage index starts from 0
  const changePage = useCallback(
    (page: number) => {
      if (page === currentPage) {
        return;
      }

      const visiblePages = getVisiblePages(page + 1, pageCount);
      setVisiblePages(visiblePages);

      setPageIndex(page);
    },
    [currentPage, getVisiblePages, pageCount, setPageIndex],
  );

  // the list with the visible pages is recomputed when the pageSize changes
  useEffect(() => {
    const newVisiblePages = getVisiblePages(currentPage + 1, pageCount);
    setVisiblePages(newVisiblePages);
  }, [currentPage, pageCount, getVisiblePages, pageSize]);

  return (
    <div className="flex gap-1 md:gap-2 text-brand-gray-light-1">
      {/* First page */}
      {isMobileView && (
        <div className="pt-2">
          <button
            className={classNames('rounded-r-md font-medium w-7 sm:w-10', {
              'cursor-not-allowed': !getCanPreviousPage(),
            })}
            onClick={() => changePage(0)}
            disabled={!getCanPreviousPage()}
          >
            {'<<'}
          </button>
        </div>
      )}

      {/* Previous button */}
      <div className="pt-2">
        <button
          className={classNames('rounded-r-md font-medium w-7 sm:w-10', {
            'cursor-not-allowed': !getCanPreviousPage(),
          })}
          onClick={() => changePage(currentPage - 1)}
          disabled={!getCanPreviousPage()}
        >
          {'<'}
        </button>
      </div>

      {/* Pages buttons */}
      {visiblePages.map((page, index) =>
        page === -1 ? (
          <div key={index} className="w-7 sm:w-10 py-2 text-center pointer-events-none">
            ...
          </div>
        ) : (
          <div key={index}>
            <button
              key={page}
              onClick={() => changePage(page - 1)}
              aria-current="page"
              className={classNames(
                'z-10 transition-colors duration-75 ease-out items-center w-16 sm:w-10 text-center',
                {
                  'font-semibold text-brand-blue sm:border py-[7px] border-opacity-50 border-brand-blue-light-2 rounded-full':
                    page === currentPage + 1,
                  'font-medium text-brand-gray-light-1 py-2': page !== currentPage + 1,
                },
              )}
            >
              <span className="text-center">
                <span>{isMobileView ? `${page} ${t('pagination.outOf')} ${pageCount}` : page}</span>
              </span>
            </button>
          </div>
        ),
      )}

      {/* Next button */}
      <div className="pt-2">
        <button
          className={classNames('rounded-r-md font-medium w-7 sm:w-10', {
            'cursor-not-allowed': !getCanNextPage(),
          })}
          onClick={() => changePage(currentPage + 1)}
          disabled={!getCanNextPage()}
        >
          {'>'}
        </button>
      </div>

      {/* Last page */}
      {isMobileView && (
        <div className="pt-2">
          <button
            className={classNames('rounded-r-md font-medium w-7 sm:w-10', {
              'cursor-not-allowed': !getCanNextPage(),
            })}
            onClick={() => changePage(pageCount - 1)}
            disabled={!getCanNextPage()}
          >
            {'>>'}
          </button>
        </div>
      )}
    </div>
  );
};
