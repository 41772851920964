import { differenceInDays } from 'date-fns/differenceInDays';
import {
  BarSeriesOption,
  BrushOption,
  CustomSeriesOption,
  LegendOption,
  LineSeriesOption,
  MarkAreaOption,
  MarkLineOption,
  ScatterSeriesOption,
  TooltipOption,
  XAXisOption,
  YAXisOption,
} from 'echarts/types/dist/shared';
import max from 'lodash/max';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { NoteModalMode, NotesModal } from 'components/notes/NotesModal';
import { eventsToLineMarkerData } from 'components/plots/helpers';
import BasePlot from 'components/plots/helpers/BasePlot';
import { createNoteToast } from 'components/toasts/NoteToast';
import { lg } from 'utils/breakpoints';
import {
  brandBlue,
  brandBlueLight2,
  brandGray,
  brandGrayLight3,
  brandGreenLight2,
  brandLime,
  brandOrange,
} from 'utils/colors';
import { dateToLocaleString } from 'utils/date';
import { RiskScoreReasonKey } from 'utils/enums';
import { useOnMarkTimePeriod, usePlots, useWindowSize } from 'utils/hooks';
import { SensorMonitoringType, useSensorEvents } from 'utils/hooks/data';
import {
  getStorageBoolean,
  setStorageBoolean,
  STORAGE_PLOTS_DATA_SHOW_ANOMALIES,
} from 'utils/local-storage';
import { getTimeAxisTicks, xAxisFormatter } from 'utils/plots/axis-formatters';
import { axisText } from 'utils/plots/axis-texts';
import createOnBrushSelectedFn, { brushOptions } from 'utils/plots/brush-select';
import { legendFormatter, legendTooltip } from 'utils/plots/legends';
import { grid, gridMobile, itemStyleAnomalies, lineStyle } from 'utils/plots/plot-config';
import { tooltipFormatterSingleSensor } from 'utils/plots/tooltip-texts';
import { calculateMax, calculateMin } from 'utils/plots/yAxisMaxAndMin';
import { getRiskScoreReasonKeyText } from 'utils/texts';
import {
  DataAnomalyTuple,
  DataField,
  DataTuple,
  LegendValue,
  RiskScoreDataTuple,
  WeatherPrecipitationDataTuple,
  WeatherWindDataTuple,
} from 'utils/types/PlotTypes';
import Transmission from 'utils/types/Transmission';
import TransmissionForecast from 'utils/types/TransmissionForecast';
import { groupAndMeanWeatherDataByDate } from 'utils/weather';

const invalidPointSymbolSize = 10;
const defaultMaxMoistureValue = 20;

const MoistureMonitoringPlot: React.FC<{
  transmissions?: Transmission[];
  transmissionForecasts?: TransmissionForecast[];
  weatherDataPrecip?: WeatherPrecipitationDataTuple[];
  weatherDataWind?: WeatherWindDataTuple[];
  emc?: DataTuple[];
  referenceValues?: DataTuple[];
  showToolbar?: boolean;
  showAnimation?: boolean;
  enableZoom?: boolean;
  fixMoistureYAxis?: boolean;
  rotateXAxisLabelsForLargeDateRange?: boolean;
  sensorId?: string;
  sensorName?: string;
  monitoringType?: SensorMonitoringType;
}> = ({
  transmissions = [],
  transmissionForecasts,
  weatherDataPrecip = [],
  weatherDataWind = [],
  emc,
  referenceValues,
  showToolbar = true,
  enableZoom = true,
  fixMoistureYAxis = false,
  rotateXAxisLabelsForLargeDateRange = false,
  sensorId,
  sensorName,
  monitoringType = SensorMonitoringType.Timber,
}) => {
  const [showEvents, setShowEvents] = useState(true);
  const [isEmcEnabled, setIsEmcEnabled] = useState(true);
  const [isRefValEnabled, setIsRefValEnabled] = useState(true);
  const [isMoistureEnabled, setIsMoistureEnabled] = useState(true);

  // adding notes
  const [showSensorTimelineModal, setShowNotesModal] = useState(false);
  const [noteAddedDate, setNoteAddedDate] = useState<Date | undefined>(undefined);
  const [notesModalMode, setNotesModalMode] = useState<NoteModalMode>('create');
  const [activeToast, setActiveToast] = useState<string | undefined>();

  const { t } = useTranslation('components');

  const weatherDataWindGroupedMeaned = groupAndMeanWeatherDataByDate(weatherDataWind);

  const [screenWidth] = useWindowSize();
  const { onMarkTimePeriod, onResetMarkedTimePeriod } = useOnMarkTimePeriod();
  const {
    onTransmissionClick,
    enableAnomalies,
    createToolboxSettings,
    filterAnomalyValidationType,
    timePeriod: [timeFrom, timeTo],
    highlightPeriod,
  } = usePlots();
  const { events: sensorEvents, isPending: sensorEventsPending } = useSensorEvents(sensorId!, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  const fontSize = screenWidth > lg ? 12 : 10;
  const timeAxisTicks = getTimeAxisTicks(screenWidth);

  // Define x-axis interval
  const minDate = new Date(timeFrom);
  const maxForecastsTime = max(transmissionForecasts?.map(x => x.timestamp));
  const maxDate = maxForecastsTime ? new Date(maxForecastsTime) : new Date(timeTo);
  maxDate.setMinutes(maxDate.getMinutes() + 55);

  const daysAmount = differenceInDays(maxDate, minDate);

  // Define y-axis interval
  const riskScoreYAxisMin = 0;
  const riskScoreYAxisMax = 5;

  // Define data series
  const dataMoisture = useMemo(
    () =>
      transmissions
        .map(
          transmission =>
            [transmission.timestamp, transmission.moisture, transmission.id] as DataTuple,
        )
        .filter(([x, y]) => y),
    [transmissions],
  );

  // Extract risk scores
  const riskScores = useMemo(
    () =>
      transmissions.map(
        transmission =>
          [
            transmission.timestamp,
            transmission.risk_score,
            transmission.risk_reasons_int_keys,
          ] as RiskScoreDataTuple,
      ),
    [transmissions],
  );

  // Extract anomalies
  const anomalyPoints = useMemo(
    () =>
      enableAnomalies
        ? transmissions
            .filter(
              transmission =>
                !!transmission.anomaly &&
                (!filterAnomalyValidationType ||
                  transmission.anomaly.validation === filterAnomalyValidationType),
            )
            .map(
              (transmission: Transmission) =>
                [
                  transmission.timestamp,
                  transmission.moisture,
                  transmission.id,
                  transmission.anomaly,
                ] as DataAnomalyTuple,
            )
            .filter(([x, y]: DataAnomalyTuple) => y)
        : [],
    [transmissions, enableAnomalies, filterAnomalyValidationType],
  );

  // Check for invalid transmissions
  const invalidTransmissions = useMemo(
    () => transmissions.filter(x => x.isInvalid),
    [transmissions],
  );

  // destructuring in order to remove width from linestyle
  const { width: x, ...emcLinestyle } = lineStyle;
  const fontFamily = 'Surt, sans-serif';

  const maxForecastMoisture = calculateMax(transmissionForecasts, 'moisture_low');
  const maxTransmissionsMoisture = calculateMax(transmissions, 'moisture');

  const minForecastMoisture = calculateMin(transmissionForecasts, 'moisture_low');
  const minTransmissionsMoisture = calculateMin(transmissions, 'moisture');

  const minMoisture =
    (Math.max(Math.min(Number(minForecastMoisture), Number(minTransmissionsMoisture))), 0);
  const maxMoisture = Math.min(
    Math.max(
      35, // Default max moisture value cannot be less than 35 to be able to show reference values
      Number(maxForecastMoisture || defaultMaxMoistureValue),
      Number(maxTransmissionsMoisture || defaultMaxMoistureValue),
    ),
    100,
  );

  const deltaMoisture = maxMoisture - minMoisture;

  const lineMarkerData = useMemo(
    () =>
      !sensorEventsPending && sensorEvents
        ? eventsToLineMarkerData(sensorEvents, fixMoistureYAxis ? 100 : maxMoisture)
        : [],
    [sensorEventsPending, sensorEvents, fixMoistureYAxis, maxMoisture],
  );

  // For Closed-in sensors, the moisture data is split based on the reference values, red if above, blue if below
  const splitMoistureDataByReference = useMemo(() => {
    const findClosestTimestamp = (timestamp: Date, referenceValues: DataTuple[]) =>
      referenceValues.reduce((prev, curr) =>
        Math.abs(new Date(curr[0]).getTime() - timestamp.getTime()) <
        Math.abs(new Date(prev[0]).getTime() - timestamp.getTime())
          ? curr
          : prev,
      );

    const splitDataByReference = () => {
      if (!referenceValues || referenceValues.length === 0) {
        return [
          {
            data: dataMoisture,
            color: monitoringType === SensorMonitoringType.FlatRoof ? brandBlueLight2 : brandBlue,
          },
        ];
      }

      const segments: { data: DataTuple[]; color: string }[] = [];
      let currentSegment: DataTuple[] = [];
      let currentColor =
        monitoringType === SensorMonitoringType.FlatRoof ? brandBlueLight2 : brandBlue;

      dataMoisture.forEach(([timestamp, moisture, index], it) => {
        const referenceValue =
          referenceValues.find(([refTimestamp]) => refTimestamp === timestamp) ||
          findClosestTimestamp(timestamp, referenceValues);
        const isAboveReference = referenceValue && moisture > referenceValue[1];

        const newColor = isAboveReference
          ? 'rgba(255, 0, 0, 0.5)'
          : monitoringType === SensorMonitoringType.FlatRoof
            ? brandBlueLight2
            : brandBlue;

        if (newColor !== currentColor && currentSegment.length > 0) {
          // If the current segment color is red
          // add the current data point to the segment even tho it is below the reference value
          if (currentColor === 'rgba(255, 0, 0, 0.5)') {
            currentSegment.push([timestamp, moisture, index]);
          }
          // Push the current segment to the segments array with its color
          segments.push({ data: currentSegment, color: currentColor });

          // Reset the current segment
          currentSegment = [];

          // If the new color is red, add the last data point of the previous segment to the new segment
          if (newColor === 'rgba(255, 0, 0, 0.5)') {
            currentSegment.push(
              segments[segments.length - 1].data[segments[segments.length - 1].data.length - 1],
            );
          }
        }

        currentSegment.push([timestamp, moisture, index]);
        currentColor = newColor;
      });

      segments.push({ data: currentSegment, color: currentColor });

      return segments;
    };

    return splitDataByReference;
  }, [monitoringType, dataMoisture, referenceValues]);

  const { series, legend } = useMemo(() => {
    const getEncodedDashedSVG = (color: string) => {
      // Generates an encoded SVG string for a dashed line with the specified color
      // The encoded SVG string is formatted as a data URL, which can be used as an icon in a chart legend
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M5 12h14" stroke="${color}" stroke-width="3" stroke-dasharray="5,5"/></svg>`;
      return `image://data:image/svg+xml,${encodeURIComponent(svg)}`;
    };

    const getEncodedDashedCircleSVG = (color1: string, color2: string) => {
      // Generates an encoded SVG string for a line with a circle in the middle split in two colors
      // The encoded SVG string is formatted as a data URL, which can be used as an icon in a chart legend
      const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24">
        <!-- Left half of the line, stopping before the circle -->
        <line x1="0" y1="12" x2="9" y2="12" stroke="${color1}" stroke-width="3"/>
        <!-- Right half of the line, starting after the circle -->
        <line x1="31" y1="12" x2="40" y2="12" stroke="${color2}" stroke-width="3"/>
        <!-- Circle outline -->
        <circle cx="20" cy="12" r="9" stroke-width="3" stroke="${color1}" fill="none"/>
        <!-- Right half of the circle in different color -->
        <path d="M30 12 A10,10 0 0,0 10,12" stroke="${color2}" stroke-width="3" fill="none"/>
      </svg>`;

      return `image://data:image/svg+xml,${encodeURIComponent(svg)}`;
    };

    const getLegendValues = (monitoringType: SensorMonitoringType): LegendValue[] => {
      let legend: LegendValue[] = [];

      switch (monitoringType) {
        case SensorMonitoringType.FlatRoof:
          if (riskScores.length > 0) {
            legend.push('risk_score');
          }
          if (weatherDataPrecip.length > 0) {
            legend.push('precip');
          }
          if (dataMoisture.length > 0) {
            legend.push({
              name: 'raw_moisture',
              icon: getEncodedDashedSVG(isMoistureEnabled ? brandBlueLight2 : brandGrayLight3),
            });
          }
          break;

        case SensorMonitoringType.ClosedIn:
          if (dataMoisture.length > 0) {
            legend.push({
              name: 'moisture',
              icon: isMoistureEnabled
                ? getEncodedDashedCircleSVG(brandBlue, 'red')
                : getEncodedDashedCircleSVG(brandGrayLight3, brandGrayLight3),
            });
          }
          if (weatherDataPrecip.length > 0) {
            legend.push('precip');
          }
          if (referenceValues && referenceValues.length > 0) {
            legend.push({
              name: 'reference_values',
              icon: getEncodedDashedSVG(isRefValEnabled ? 'red' : brandGrayLight3),
            });
          }
          break;

        case SensorMonitoringType.Timber:
        default:
          if (dataMoisture.length > 0) {
            legend.push('moisture');
          }
          if (weatherDataPrecip.length > 0) {
            legend.push('precip');
          }
          if (emc && emc.length > 0) {
            legend.push({
              name: 'emc',
              icon: getEncodedDashedSVG(isEmcEnabled ? brandGray : brandGrayLight3),
            });
          }
          break;
      }

      if (sensorEvents && sensorEvents.length > 0) {
        legend.push({ name: 'events', icon: 'circle' });
      }

      if (anomalyPoints.length > 0) {
        legend.push('anomalies');
      }

      return legend;
    };

    const legend = getLegendValues(monitoringType);

    const series: (
      | LineSeriesOption
      | ScatterSeriesOption
      | BarSeriesOption
      | CustomSeriesOption
    )[] = [];

    if (
      (monitoringType === SensorMonitoringType.Timber ||
        monitoringType === SensorMonitoringType.ClosedIn) &&
      dataMoisture.length > 0
    ) {
      const moistureSegments =
        monitoringType === SensorMonitoringType.ClosedIn
          ? splitMoistureDataByReference()
          : [
              {
                data: dataMoisture,
                color: brandBlue,
              },
            ];

      series.push(
        ...moistureSegments.map(
          segment =>
            ({
              name: 'moisture',
              type: 'line',
              symbolSize: 5,
              lineStyle,
              textStyle: {
                fontFamily,
              },
              data: segment.data,
              yAxisIndex: 1,
              color: segment.color,
              z: 2,
              markLine: {
                symbol: ['circle', 'circle'],
                data: showEvents ? lineMarkerData : [],
              } as MarkLineOption,
              markArea:
                highlightPeriod &&
                highlightPeriod[0] &&
                highlightPeriod[1] &&
                ({
                  itemStyle: {
                    color: brandLime,
                  },
                  data: [
                    [
                      {
                        xAxis: highlightPeriod[0],
                      },
                      {
                        xAxis: highlightPeriod[1],
                      },
                    ],
                  ],
                } as MarkAreaOption),
            }) as LineSeriesOption,
        ),
      );
    }

    if (monitoringType === SensorMonitoringType.FlatRoof) {
      series.push({
        name: 'raw_moisture',
        type: 'line',
        symbol: 'none',
        lineStyle: { width: 2, type: 'dashed' },
        textStyle: {
          fontFamily,
        },
        data: dataMoisture,
        yAxisIndex: 1,
        color: brandBlueLight2,
        z: 2,
        markLine: {
          symbol: ['circle', 'circle'],
          data: showEvents ? lineMarkerData : [],
        } as MarkLineOption,
        markArea:
          highlightPeriod &&
          highlightPeriod[0] &&
          highlightPeriod[1] &&
          ({
            itemStyle: {
              color: brandLime,
            },
            data: [
              [
                {
                  xAxis: highlightPeriod[0],
                },
                {
                  xAxis: highlightPeriod[1],
                },
              ],
            ],
          } as MarkAreaOption),
      } as LineSeriesOption);
    }

    if (monitoringType === SensorMonitoringType.FlatRoof && riskScores.length > 0) {
      series.push({
        name: 'risk_score',
        type: 'line',
        symbolSize: 10,
        symbol: 'circle',
        z: 4,
        lineStyle: { width: 5 },
        textStyle: {
          fontFamily,
        },
        color: brandGreenLight2,
        data: riskScores,
        yAxisIndex: 0,
      } as LineSeriesOption);
    }

    series.push({
      name: 'precip',
      type: 'bar',
      symbolSize: 5,
      lineStyle,
      data: weatherDataPrecip,
      yAxisIndex: 2,
      color: '#191970',
      z: 1,
    } as BarSeriesOption);

    if (weatherDataPrecip.length === 0) {
      series.splice(1, 1);
      legend.splice(1, 1);
    }

    if (transmissionForecasts && transmissionForecasts.length > 0) {
      series.push(
        {
          name: 'moisture_forecast_low',
          type: 'line',
          data: transmissionForecasts?.map(val => [val.timestamp, val.moisture_low]),
          stack: 'confidence-band',
          lineStyle: {
            opacity: 0,
          },
          showSymbol: false,
        },
        {
          name: 'moisture_forecast' as DataField,
          type: 'line',
          data: transmissionForecasts?.map(val => [
            val.timestamp,
            val.moisture_up - val.moisture_low,
          ]),
          stack: 'confidence-band',
          animation: true,
          areaStyle: {
            color: '#a1a1aa',
          },
          lineStyle: {
            opacity: 0,
          },
          color: '#a1a1aa',
          showSymbol: false,
        },
        {
          name: 'moisture_forecast_mean' as DataField,
          animation: false,
          type: 'line',
          data: transmissionForecasts?.map(val => [val.timestamp, val.moisture_hat]),
          lineStyle: {
            color: '#4682B4',
            ...lineStyle,
          },
          color: '#4682B4',
          showSymbol: false,
        },
      );
    }

    if (monitoringType === SensorMonitoringType.Timber && emc && emc.length > 0) {
      series.push({
        name: 'emc',
        type: 'line',
        yAxisIndex: 1,
        color: brandGray,
        data: emc,
        symbol: 'none',
        lineStyle: { width: 2, ...emcLinestyle, type: 'dashed' },
      } as LineSeriesOption);
    }

    if (
      monitoringType === SensorMonitoringType.ClosedIn &&
      referenceValues &&
      referenceValues.length > 0
    ) {
      series.push({
        name: 'reference_values',
        type: 'line',
        yAxisIndex: 1,
        color: 'red',
        data: referenceValues,
        symbol: 'none',
        lineStyle: { width: 2, ...emcLinestyle, type: 'dashed' },
      } as LineSeriesOption);
    }

    if (invalidTransmissions.length > 0) {
      series.push({
        name: 'moisture_invalid',
        type: 'scatter',
        symbolSize: invalidPointSymbolSize,
        itemStyle: {
          borderColor: '#868e96',
          borderWidth: 1,
          color: '#adb5bd',
          opacity: 1.0,
        },
        z: 3,
        data: invalidTransmissions.map(transmission => [
          transmission.timestamp,
          transmission.moisture,
        ]),
        yAxisIndex: 1,
      } as ScatterSeriesOption);
    }

    // Anomalies
    if (anomalyPoints.length > 0) {
      series.push({
        name: 'anomalies',
        type: 'scatter',
        symbolSize: 7,
        itemStyle: itemStyleAnomalies,
        z: 3,
        data: anomalyPoints,
        yAxisIndex: 1,
      } as ScatterSeriesOption);
    }

    // There may not be any events in the current time period but there could be if the
    // user changes it events may show up, so we always show the icon to avoid it popping in and out
    series.push({
      name: 'events',
      type: 'line',
      itemStyle: { color: brandOrange },
    } as LineSeriesOption);

    return { series, legend };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataMoisture,
    transmissions,
    weatherDataPrecip,
    weatherDataWindGroupedMeaned,
    anomalyPoints,
    invalidTransmissions,
    emc,
    referenceValues,
    emcLinestyle,
    highlightPeriod,
    transmissionForecasts,
    maxMoisture,
    deltaMoisture,
    lineMarkerData,
    showEvents,
  ]);

  const yAxis = [
    {
      type: 'value',
      name: axisText('risk_score'),
      min: riskScoreYAxisMin,
      max: riskScoreYAxisMax,
      show: monitoringType === SensorMonitoringType.FlatRoof,
      position: 'left',
      axisLabel: {
        fontSize,
        fontFamily,
        formatter: '{value}',
      },
      nameTextStyle: {
        fontSize,
        fontFamily,
        fontWeight: 'bold',
      },
      nameLocation: 'middle',
      nameGap: screenWidth > lg ? 50 : 40,
    },
    {
      type: 'value',
      name: axisText('moisture'),
      splitLine: {
        show:
          monitoringType === SensorMonitoringType.Timber ||
          monitoringType === SensorMonitoringType.ClosedIn,
      },
      min: fixMoistureYAxis ? 0 : minMoisture,
      max: fixMoistureYAxis ? 100 : maxMoisture,
      show: true,
      position: monitoringType === SensorMonitoringType.FlatRoof ? 'right' : 'left',
      axisLabel: {
        fontSize,
        fontFamily,
        formatter: '{value}%',
      },
      nameTextStyle: {
        fontSize,
        fontFamily,
        fontWeight: 'bold',
      },
      nameLocation: 'middle',
      nameGap: 40,
    },
    {
      type: 'value',
      name: axisText('precip'),
      splitLine: null,
      show:
        monitoringType === SensorMonitoringType.Timber ||
        monitoringType === SensorMonitoringType.ClosedIn,
      position: 'right',
      min: 0,
      max: 8,
      axisLabel: {
        fontSize,
        fontFamily,
        formatter: '{value}',
      },
      nameTextStyle: {
        fontStyle: fontSize,
        fontFamily,
        fontWeight: 'bold',
      },
      nameLocation: 'middle',
      nameGap: 40,
    },
  ] as YAXisOption[];

  const xAxis = {
    type: 'time',
    name: axisText('time'),
    axisPointer: {
      show: true,
      label: {
        formatter: (params: any) =>
          `${dateToLocaleString(new Date(params.value))}\n${t('plots.CombinedPlots.MoistureMonitoringPlot.xAxis.addNoteOnClick')}`,
      },
    },
    nameGap: rotateXAxisLabelsForLargeDateRange && daysAmount >= 4 ? 50 : 30,
    textStyle: {
      fontFamily,
    },
    min: minDate,
    max: maxDate,
    nameLocation: 'middle',
    nameTextStyle: {
      fontFamily,
      fontWeight: 'bold',
      fontSize,
    },
    axisLabel: {
      fontSize,
      fontFamily,
      hideOverlap: true,
      rotate: rotateXAxisLabelsForLargeDateRange && daysAmount >= 4 ? 65 : 0,
      formatter: (value: string, index: number) =>
        xAxisFormatter(value, index, daysAmount, timeAxisTicks),
    },
  } as XAXisOption;

  const toolbox = createToolboxSettings({
    saveAsImageFilename: t('plots.CombinedPlots.MoistureMonitoringPlot.legend.moisture'),
    hardwareId: transmissions[0]?.hardware_id,
    showBrush: true,
    onTimePeriodResetClick: onResetMarkedTimePeriod,
  });

  // Define brush
  const brushSelected = createOnBrushSelectedFn(onMarkTimePeriod);

  const onZRendererClick = (dateAtClick: Date) => {
    setNoteAddedDate(dateAtClick);
    setShowNotesModal(true);
  };

  const { lastRiskScoreTransmissionValue, lastRiskScoreTransmissionReasonsKeys } = useMemo(() => {
    const lastRiskScoreTransmission = riskScores[0];
    return {
      lastRiskScoreTransmissionValue: lastRiskScoreTransmission?.[1],
      lastRiskScoreTransmissionReasonsKeys: lastRiskScoreTransmission?.[2],
    };
  }, [riskScores]);

  // Define risk score text position
  const riskScoreTextPosition = useMemo(() => {
    if (riskScores.length === 0) return { left: '60%', top: '15%' };

    const riskScoreValue = lastRiskScoreTransmissionValue || 0;

    // Calculate the position based on the risk score value
    const topPosition =
      ((riskScoreYAxisMax - riskScoreValue) / (riskScoreYAxisMax - riskScoreYAxisMin)) * 100;

    // Adjust position to ensure text fits within the plot area
    const adjustedTopPosition = topPosition < 10 ? topPosition + 25 : topPosition - 25;

    return {
      left: '60%',
      top: `${adjustedTopPosition}%`,
    };
  }, [lastRiskScoreTransmissionValue, riskScores.length]);

  return (
    <>
      <BasePlot
        option={{
          xAxis,
          yAxis,
          tooltip: {
            className: 'text-wrap text-xs max-w-48',
            axisPointer: {
              animation: true,
            },
            formatter: tooltipFormatterSingleSensor(sensorName || ''),
          } as TooltipOption,
          ...(monitoringType === SensorMonitoringType.FlatRoof && {
            graphic: [
              {
                type: 'text',
                left: riskScoreTextPosition.left,
                top: riskScoreTextPosition.top,
                z: 100,
                zlevel: 100,
                style: {
                  fill: brandGray,
                  width: 250,
                  overflow: 'break',
                  text: !!lastRiskScoreTransmissionValue
                    ? `${t('plots.CombinedPlots.MoistureMonitoringPlot.FreeText.riskScore', { riskScoreValue: lastRiskScoreTransmissionValue?.toFixed(1) })} ${lastRiskScoreTransmissionReasonsKeys?.length > 0 ? `${t('plots.CombinedPlots.MoistureMonitoringPlot.FreeText.causedBy')}:` : ''}`
                    : '',
                  fontFamily,
                  fontSize,
                  fontWeight: 'bold',
                },
              },
              ...(lastRiskScoreTransmissionReasonsKeys?.length > 0
                ? lastRiskScoreTransmissionReasonsKeys.map(
                    (key: RiskScoreReasonKey, index: number) => ({
                      type: 'text',
                      left: riskScoreTextPosition.left,
                      top: `${parseFloat(riskScoreTextPosition.top) + 5 + index * 5}%`,
                      z: 100,
                      zlevel: 100,
                      style: {
                        fill: brandGray,
                        width: 250,
                        overflow: 'break',
                        text: `• ${getRiskScoreReasonKeyText(key)}`,
                        fontFamily,
                        fontSize,
                      },
                    }),
                  )
                : []),
            ],
          }),
          series,
          grid: screenWidth > lg ? grid : gridMobile,
          animation: false,
          legend: {
            data: legend,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
              fontFamily,
            },
            inactiveColor: brandGrayLight3,
            selected: {
              moisture: isMoistureEnabled,
              raw_moisture: isMoistureEnabled,
              emc: isEmcEnabled,
              anomalies: getStorageBoolean(STORAGE_PLOTS_DATA_SHOW_ANOMALIES),
              reference_values: isRefValEnabled,
              events: showEvents,
              risk_score: monitoringType === SensorMonitoringType.FlatRoof,
            } as Record<DataField, boolean>,
            formatter: legendFormatter,
            tooltip: legendTooltip,
          } as LegendOption,
          ...(enableZoom && { brush: brushOptions as BrushOption }),
          ...(showToolbar && { toolbox }),
        }}
        onEvents={{
          click: useCallback(
            ({ value }: { value?: DataTuple }) => {
              if (!value) return;
              const transmissionId = value[2];
              if (onTransmissionClick) onTransmissionClick(transmissionId);
            },
            [onTransmissionClick],
          ),
          legendselectchanged: useCallback(
            (
              {
                name,
                selected,
              }: {
                name:
                  | 'moisture'
                  | 'raw_moisture'
                  | 'precip'
                  | 'emc'
                  | 'reference_values'
                  | 'wind'
                  | 'events'
                  | 'risk_score';
                selected: any;
              },
              chart: any,
            ) => {
              // Save legend selection in local storage
              setStorageBoolean(STORAGE_PLOTS_DATA_SHOW_ANOMALIES, selected.anomalies);

              const show = selected[name];

              switch (name) {
                case 'events':
                  setShowEvents(show);
                  break;
                case 'emc':
                  setIsEmcEnabled(show);
                  break;
                case 'reference_values':
                  setIsRefValEnabled(show);
                  break;
                case 'raw_moisture':
                case 'moisture':
                  setIsMoistureEnabled(show);

                  // Hide invalid points if any exists
                  const invalidSeries = series.find(
                    x => x.name === 'moisture_invalid',
                  ) as ScatterSeriesOption;
                  if (invalidSeries) {
                    invalidSeries.symbolSize = show ? invalidPointSymbolSize : 0;
                  }
                  chart.setOption({
                    series,
                  });
                  break;
                default:
                  break;
              }
            },
            [series],
          ),
          brushSelected: brushSelected!,
        }}
        onEventsZRenderer={[onZRendererClick]}
      />
      {/* We have to add a new sensor timeline modal, rather than just using the one from the sensor page,
      since we want this to work on the blueprints page as well */}
      {sensorId && (
        <NotesModal
          sensorId={sensorId}
          sensorName={sensorName || ''}
          show={showSensorTimelineModal}
          setShow={setShowNotesModal}
          mode={notesModalMode}
          noteDate={noteAddedDate || new Date()}
          closeOnSubmit={true}
          afterSubmit={(noteAction: NoteModalMode) => {
            if (activeToast) {
              toast.dismiss(activeToast);
            }
            createNoteToast(noteAction, setActiveToast, setNotesModalMode, setShowNotesModal);
            setNotesModalMode('create');
          }}
          onClose={() => {
            setNotesModalMode('create');
          }}
        />
      )}
    </>
  );
};

export default MoistureMonitoringPlot;
