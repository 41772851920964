import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { parseEvent } from 'utils/types';

export const useBlueprintEvents = (
  blueprintId: string,
  {
    fromTimestamp,
    toTimestamp,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
  },
) => {
  fromTimestamp = fromTimestamp && floorToLatest5Minutes(fromTimestamp);
  toTimestamp = toTimestamp && floorToLatest5Minutes(toTimestamp);

  const queryKey = ['blueprintEvents', blueprintId, fromTimestamp, toTimestamp];

  // Sensor Events - Get
  const { data: events, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/blueprints/${blueprintId}/events`, {
        _disableErrorNotifications: true,
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (events: any[]) => events.map(parseEvent),
    enabled: !!blueprintId,
  });

  return {
    events,
    isPending,
  };
};
