import { Dispatch, SetStateAction } from 'react';

import {
  SensorsHealthIndicatorModalMultipleSensors,
  SensorsHealthIndicatorModalSingleSensor,
} from 'components/modals/SensorsHealthIndicatorModal/components';
import { useSensorsBulk, useSensorsBulkTransmissions } from 'utils/hooks/data';
import TimePeriod from 'utils/types/TimePeriod';

export type SensorsHealthIndicatorModalProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sensorIds: string[];
  timePeriod: Required<TimePeriod>;
};

export const SensorsHealthIndicatorModal: React.FC<SensorsHealthIndicatorModalProps> = ({
  show,
  sensorIds,
  timePeriod: [timeFrom, timeTo],
  ...props
}) => {
  const { sensors, isPending: isPendingSensor } = useSensorsBulk(sensorIds);
  const { transmissions, isPending: isPendingTransmissions } = useSensorsBulkTransmissions(
    sensorIds,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
      fields: [
        'timestamp',
        'hardware_id',
        'gateway_id',
        'tx_power',
        'spreading_factor',
        'battery',
        'rssi',
        'snr',
      ],
    },
    {
      enableGet: show,
    },
  );

  const isPending = isPendingSensor || isPendingTransmissions;

  if (!sensors) return <></>;
  return sensors.length > 1 ? (
    <SensorsHealthIndicatorModalMultipleSensors
      sensors={sensors}
      transmissions={transmissions}
      isPending={isPending}
      show={show}
      {...props}
    />
  ) : (
    <SensorsHealthIndicatorModalSingleSensor
      sensor={sensors[0]}
      transmissions={transmissions}
      isPending={isPending}
      show={show}
      {...props}
    />
  );
};
