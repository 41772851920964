import { t } from 'translations';
import { dateToLocaleString } from 'utils/date';

export const getAlarmRulePeriodHtml = (
  activePeriodFrom?: Date | null,
  activePeriodTo?: Date | null,
  repeatYearl?: boolean,
) =>
  activePeriodFrom ? (
    <>
      {t('utils:texts.AlarmRule.getAlarmRulePeriodHtml.periodFrom')}{' '}
      <span className="font-semibold">
        {dateToLocaleString(activePeriodFrom, { dateOnly: true })}
      </span>
      {activePeriodTo && (
        <>
          {` ${t('utils:texts.AlarmRule.getAlarmRulePeriodHtml.to')} `}
          <span className="font-semibold">
            {dateToLocaleString(activePeriodTo, { dateOnly: true })}
          </span>

          {repeatYearl && ` ${t('utils:texts.AlarmRule.getAlarmRulePeriodHtml.repeatsYearly')} `}
        </>
      )}
    </>
  ) : (
    <span>{t('utils:texts.AlarmRule.getAlarmRulePeriodHtml.noneSelected')}</span>
  );
