import {
  faExpand,
  faLock,
  faUnlock,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ActionBarHeader } from 'components/BlueprintCanvas/components/ActionBar/ActionBarHeader';
import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import { BlueprintSettingsDropdown } from 'components/BlueprintCanvas/components/BlueprintSettingsDropdown';
import { MeasurementTypeSelector } from 'components/BlueprintCanvas/components/MeasurementTypeSelect';
import { Button } from 'components/buttons';
import { InfoTooltip } from 'components/InfoTooltip';

export const ActionBar: React.FC<{
  blueprintId: string;
  blueprintTitle: string;
  blueprintGroup: string;
  blueprintGroupId: string;
  blueprintTags: string;
  editModeEnabled: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  blueprintId,
  blueprintTitle,
  blueprintGroup,
  blueprintTags,
  blueprintGroupId,
  editModeEnabled,
  setEditMode,
}) => {
  const { t } = useTranslation('components');
  const location = useLocation();
  const { isFullscreen, setIsFullscreen } = useContext(BlueprintCanvasContext);
  return (
    <div
      className={classNames(
        'flex items-start py-3 gap-2 px-4 w-full h-fit bg-brand-green text-brand-beige border-b-2 border-brand-green-light-2',
        {
          'rounded-t-lg': !isFullscreen,
        },
      )}
    >
      <div className="w-1/3">
        <ActionBarHeader
          blueprintTitle={blueprintTitle}
          blueprintTags={blueprintTags}
          blueprintGroupId={blueprintGroupId}
          blueprintGroup={blueprintGroup}
        />
      </div>
      <div className="flex items-center justify-center w-1/3">
        <MeasurementTypeSelector id={blueprintId} />
      </div>
      <div className="flex self-center relative flex-row-reverse items-center h-full w-1/3 gap-2">
        <div className="flex flex-row-reverse gap-2">
          <BlueprintSettingsDropdown
            blueprintId={blueprintId}
            onDeleteRedirectPath={'user/blueprints'}
          />
          <div
            className="relative w-7 h-7 hover:text-brand-beige-light-2 hover:cursor-pointer"
            onClick={() => setIsFullscreen(!isFullscreen)}
            data-tooltip-id="full-screen-tooltip"
            data-tooltip-content={t('blueprints.ActionBar.fullscreenTooltip')}
          >
            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />
            <FontAwesomeIcon
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2/4 h-2/4"
              icon={faUpRightAndDownLeftFromCenter}
            />
          </div>
          <InfoTooltip
            id="blueprint-action-bar-info-tooltip"
            message={t('blueprints.ActionBar.InfoTooltip.text')}
            link="https://help.tector.com/how-to-upload-blueprints-and-attach-sensors"
            linkText={t('blueprints.ActionBar.InfoTooltip.linkText')}
            iconClassName="h-7 w-7"
          />
        </div>
        {!location.pathname.includes('sensors') && (
          <Button
            className="flex items-center justify-center"
            onClick={() => setEditMode(val => !val)}
          >
            <FontAwesomeIcon className="mr-2" icon={editModeEnabled ? faUnlock : faLock} />
            <div className="text-xs xl:text-sm text-nowrap">
              {editModeEnabled
                ? t('blueprints.ActionBar.confirmChanges')
                : t('blueprints.ActionBar.editBlueprint')}
            </div>
          </Button>
        )}
        {editModeEnabled && (
          <div className="hidden xl:block text-base text-brand-beige">
            {t('blueprints.ActionBar.editMode')}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
