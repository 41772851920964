import { useState } from 'react';

import { BlueprintCanvas } from 'components/BlueprintCanvas';

export const BlueprintSensorPositioning: React.FC<{
  blueprintId: string;
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds }) => {
  const [editModeEnabled, setEditMode] = useState(false);

  return (
    <BlueprintCanvas
      blueprintId={blueprintId}
      onlyEnableSensorIds={onlyEnableSensorIds}
      showHeader
      showSensors
      showPlayableTimeline
      showToolbox
      enableWheelScrolling
      enableDragging
      editModeEnabled={editModeEnabled}
      setEditMode={setEditMode}
    />
  );
};
