import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from 'components';
import { faSquirtyAlertRules } from 'components/icons';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { useAlertRule } from 'utils/hooks/data';
import { notificationWarningConfirm } from 'utils/notifications';
import { getAlarmRuleTypeText } from 'utils/texts/AlarmRuleType';
import AlarmRule, { getAlarmRuleLocalizedName } from 'utils/types/AlarmRule';

export const AlertRuleItem: React.FC<{
  alarmRule: AlarmRule;
  isAdmin: boolean;
  source: string;
}> = ({ alarmRule, isAdmin, source }) => {
  const { i18n } = useTranslation('components');
  const currentLocale = parseI18LanguageToLocale(i18n.language);

  const location = useLocation();

  const pathnameSplit = location.pathname.split('/');
  const id = location.pathname.endsWith('/edit')
    ? pathnameSplit[pathnameSplit.length - 2]
    : pathnameSplit[pathnameSplit.length - 1];

  const { deleteAlertRule: deleteAlarmRule } = useAlertRule(alarmRule.id, { enableGet: false });

  const isEditable =
    alarmRule.type === AlarmRuleType.Custom ||
    (alarmRule.type === AlarmRuleType.BuiltIn && isAdmin);
  const isActive = id === alarmRule.id;

  // TODO: Remove when making reference values alarm rule public available
  if (alarmRule.id === '620a4567d10ab6488de28bbb') {
    return null;
  }

  return (
    <li className="flex items-center justify-between gap-x-2 my-2">
      <div className="flex items-center gap-x-2">
        <FontAwesomeIcon
          icon={faSquirtyAlertRules}
          className="text-brand-green w-4 h-4 lg:w-6 lg:h-6"
        />
        <Link
          className={classNames('text-brand-green hover:text-blue-500 underline', {
            'font-semibold text-brand-blue': isActive,
          })}
          to={`${source}/${alarmRule?.id}`}
        >
          {getAlarmRuleLocalizedName(alarmRule, currentLocale)}
        </Link>
        {alarmRule.type && (
          <Badge
            className={classNames({
              'bg-brand-green text-white': alarmRule.type === AlarmRuleType.Custom,
              'bg-brand-green-light-3 text-white': alarmRule.type === AlarmRuleType.BuiltIn,
            })}
          >
            {getAlarmRuleTypeText(alarmRule.type)}
          </Badge>
        )}
      </div>
      {isEditable && (
        <div className="flex gap-x-2">
          <Link to={`${source}/${alarmRule?.id}/edit`}>
            <FontAwesomeIcon
              icon={faEdit}
              className="w-4 h-4 lg:w-6 lg:h-6 text-brand-gray-light-2"
            />
          </Link>
          <FontAwesomeIcon
            onClick={async () => {
              const confirmed = await notificationWarningConfirm();
              if (!confirmed) return;
              await deleteAlarmRule();
            }}
            icon={faTrashAlt}
            className="w-4 h-4 lg:w-6 lg:h-6 text-brand-gray-light-2 cursor-pointer"
          />
        </div>
      )}
    </li>
  );
};
