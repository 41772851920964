import {
  faEdit,
  faFileContract,
  faKey,
  faMagnifyingGlassChart,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { customAlphabet } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Table, TableAction, TableActionType, TableProps } from 'components';
import { onSubmitValues } from 'components/forms/SensorForm';
import SensorDownlinksModal from 'components/modals/SensorDownlinksModal';
import SensorTagsModal from 'components/modals/SensorTagsModal';
import UpdateSensorAlarmRulesModal from 'components/modals/UpdateSensorAlarmRulesModal';
import UpdateSensorModal from 'components/modals/UpdateSensorModal';
import { confirmSensorDownlinkSubmit } from 'components/notifications';
import { ActionComponents } from 'components/tables/SensorsPaginatedTable/components';
import { configureColumns } from 'components/tables/SensorsPaginatedTable/helpers';
import { useCurrentUser, useSelectedCustomer, useTimePeriod, useWindowSize } from 'utils/hooks';
import {
  useCustomerSensorGroups,
  useSensor,
  useSensorDownlinks,
  useSensorGroupSensors,
  useSensorGroupSubgroups,
  useSensorsBulkLoRaKeys,
  useSensorTags,
  useUserGroups,
} from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import { allCustomer } from 'utils/types/Customer';
import Sensor from 'utils/types/Sensor';
import SensorStates from 'utils/types/SensorStates';
import SensorTags from 'utils/types/SensorTags';

type SensorTableData = Sensor &
  Omit<Partial<SensorTags>, 'sensor_id'> &
  Pick<
    SensorStates,
    | 'activity_state'
    | 'humidity_state'
    | 'mold_growth_risk_state'
    | 'moisture_state'
    | 'signal_strength_state'
    | 'temperature_state'
  > & {
    access_links?: any;
    blueprint?: any;
    description?: string;
    _remove_from_group?: boolean;
    _device_number: number;
  };

export const SensorsPaginatedTable: React.FC<
  {
    sensors: Sensor[];
    isPending?: boolean;
    fromSensorGroupId?: string;
    showSearchBar?: boolean;
    showToggling?: boolean;
    onDeleteClick?: (sensor: Sensor) => void;
    onClick?: (row?: Sensor) => void;
    actionsAvailable?: TableActionType[];
  } & Pick<TableProps<SensorTableData>, 'hideHeader' | 'tableIdentifier'>
> = ({
  tableIdentifier,
  sensors,
  isPending = false,
  fromSensorGroupId,
  showSearchBar = true,
  showToggling = true,
  onDeleteClick,
  onClick,
  actionsAvailable,
  ...props
}) => {
  const [showSensorModal, setShowUpdateModal] = useState(false);
  const [showAlarmRulesModal, setShowAlarmRulesModal] = useState(false);
  const [showSensorTagsModal, setShowSensorTagsModal] = useState(false);
  const [showSensorDownlinksModal, setShowSensorDownlinksModal] = useState(false);
  const [activeSensorId, setActiveSensorId] = useState<string>();
  const [showSensorDownlinkModal, setShowSensorDownlinkModal] = useState(false);
  const [sensorIdsToUpdate, setSensorIdsToUpdate] = useState<string[]>([]);
  const [showCustomerSelect, setShowCustomerSelect] = useState(false);
  const [showSensorTagModal, setShowSensorTagModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [sensorIdsToIncludeInReport, setSensorIdsToIncludeInReport] = useState<string[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const nanoid = customAlphabet('1234567890abcdef', 5);

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();
  const [width] = useWindowSize();
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { sensor, updateSensor } = useSensor(activeSensorId);
  const { updateSensorTags } = useSensorTags(activeSensorId);
  const { createDownlink } = useSensorDownlinks(activeSensorId, { enableGet: false });
  const { createSensorGroupByCustomerId } = useCustomerSensorGroups(customerId, {
    enableGet: false,
  });
  const { addSensorsToGroupById } = useSensorGroupSensors();
  const { addGroupAsSubgroup } = useSensorGroupSubgroups(fromSensorGroupId, { enableGet: false });
  const { createSensorGroupByUserId } = useUserGroups(userId, {
    enableGet: false,
  });
  const { downloadLoRaKeysCSV } = useSensorsBulkLoRaKeys();

  const createGroup = useCallback(
    async (groupName: string) => {
      if (!customerId || customerId === allCustomer.id) {
        return await createSensorGroupByUserId({
          userId,
          name: groupName,
        });
      } else {
        return await createSensorGroupByCustomerId({
          customerId,
          name: groupName,
        });
      }
    },
    [customerId, createSensorGroupByCustomerId, createSensorGroupByUserId, userId],
  );

  const sensorTableData: SensorTableData[] = useMemo(
    () =>
      sensors.map(sensor => ({
        ...sensor,
        ...sensor.tags,
        sensor_id: undefined,
        activity_state: sensor.states.activity_state,
        humidity_state: sensor.states.humidity_state,
        mold_growth_risk_state: sensor.states.mold_growth_risk_state,
        moisture_state: sensor.states.moisture_state,
        signal_strength_state: sensor.states.signal_strength_state,
        temperature_state: sensor.states.temperature_state,
        description: sensor.geographic_location?.description,
        _device_number:
          sensor.ttn_dev_id && sensor.ttn_dev_id.startsWith('woody')
            ? parseInt(sensor.ttn_dev_id.substring(5), 10)
            : 0,
      })),
    [sensors],
  );

  const onTagClick = useCallback(
    (sensorId: string) => {
      setActiveSensorId(sensorId);
      setShowSensorTagsModal(true);
    },
    [setActiveSensorId, setShowSensorTagsModal],
  );

  const columns = useMemo(
    () =>
      configureColumns({
        fromSensorGroupId,
        onDeleteClick,
        onTagClick,
        timeFrom,
        timeTo,
        width,
        setActiveSensorId,
        setShowUpdateModal,
        setShowSensorTagsModal,
        setShowAlarmRulesModal,
        setShowSensorDownlinksModal,
        t,
      }),
    [fromSensorGroupId, onDeleteClick, onTagClick, t, timeFrom, timeTo, width],
  );

  const onUpdateSensorModalSubmit = async ({
    name,
    geographic_location,
    send_alarms,
    tree_type,
  }: onSubmitValues) => {
    await updateSensor({
      name,
      send_alarms,
      tree_type,
      geographic_location,
    });
    notificationSuccess(
      t('components:tables.sensorsPaginatedTable.onUpdateSensorModalSubmit.successText'),
    );
    setActiveSensorId(undefined);
    setShowUpdateModal(false);
  };

  const onUpdateSensorTagsModalSubmit = async (sensorTags: SensorTags) => {
    await updateSensorTags(sensorTags);
    notificationSuccess(
      t('components:tables.sensorsPaginatedTable.onUpdateSensorTagsModalSubmit.successText'),
    );
    setActiveSensorId(undefined);
    setShowSensorTagsModal(false);
  };

  const onUpdateSensorFrequencyModalSubmit = async (frequency: number) => {
    const isConfirmed = await confirmSensorDownlinkSubmit();

    if (isConfirmed) {
      await createDownlink(frequency);
      notificationSuccess(
        t('components:tables.sensorsPaginatedTable.onUpdateSensorFrequencyModalSubmit.successText'),
      );
    }

    setActiveSensorId(undefined);
    setShowSensorDownlinksModal(false);
  };

  const actions = useMemo(() => {
    const actions: TableAction[] = [];

    if (actionsAvailable?.includes('create-new-group')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.createGroup.buttonText'),
        action: async (sensorIds: string[]) => {
          const group = await createGroup(
            t('components:tables.SensorsPaginatedTable.actions.createGroup.newGroupName'),
          );

          await addSensorsToGroupById({ sensorGroupId: group.id, sensorIds });
          notificationSuccess(
            t('components:tables.SensorsPaginatedTable.actions.createGroup.onCreationText'),
          );

          navigate(`/user/groups/${group.id}/overview`);
        },
        icon: faPlus,
      });
    }

    if (actionsAvailable?.includes('create-subgroup')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.createSubgroup.buttonText'),
        action: async (sensorIds: string[]) => {
          const group = await createGroup(`Subgroup ${nanoid()}`);

          await addSensorsToGroupById({ sensorGroupId: group.id, sensorIds });
          notificationSuccess(
            t(
              'components:tables.SensorsPaginatedTable.actions.createSubgroup.onSubgroupCreationText',
            ),
          );

          await addGroupAsSubgroup(group.id);

          navigate(`/user/groups/${group.id}/overview`);
        },
        icon: faPlus,
      });
    }

    if (actionsAvailable?.includes('update-frequency')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.bulkUpdateFrequency.buttonText'),
        action: (sensorIds: string[]) => {
          setShowSensorDownlinkModal(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('update-customer')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.bulkUpdateCustomer.buttonText'),
        action: (sensorIds: string[]) => {
          setShowCustomerSelect(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('update-tags')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.bulkUpdateTags.buttonText'),
        action: (sensorIds: string[]) => {
          setShowSensorTagModal(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('compare-sensors')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.compareSensors.buttonText'),
        action: async (sensorIds: string[]) => {
          navigate(`/user/sensors/compare?sensorIds=${sensorIds.join(',')}`);
        },
        icon: faMagnifyingGlassChart,
      });
    }

    if (actionsAvailable?.includes('create-report')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.createReport.buttonText'),
        action: (sensorIds: string[]) => {
          setShowReportModal(true);
          setSensorIdsToIncludeInReport(sensorIds);
        },
        icon: faFileContract,
      });
    }

    if (actionsAvailable?.includes('download-lora-keys')) {
      actions.push({
        title: t('components:tables.SensorsPaginatedTable.actions.downloadLoRaKeys.buttonText'),
        action: async (sensorIds: string[]) => {
          await downloadLoRaKeysCSV(sensorIds);
        },
        icon: faKey,
      });
    }

    return actions;
  }, [
    actionsAvailable,
    addGroupAsSubgroup,
    addSensorsToGroupById,
    createGroup,
    nanoid,
    navigate,
    downloadLoRaKeysCSV,
    t,
  ]);

  const { initialColumnPinning, initialColumnVisibility } = useMemo(() => {
    const initialColumnPinning = {
      left: ['selection', 'name'],
      right: ['id', '_remove_from_group'],
    };

    const initialColumnVisibility = {
      name: true,
      decription: true,
      moisture_state: true,
      activity_state: false,
      humidity_state: false,
      mold_growth_risk_state: false,
      temperature_state: false,
      send_alarms: true,
      signal_strength_state: false,
      blueprint: false,
      access_links: false,
      setup_completed_at: false,
      primary_element_category: false,
      primary_element_type: false,
      complementary_element: false,
      climate: false,
      ventilation: false,
      construction_phase: false,
      construction_principles: false,
      insulation: false,
      insulation_type: false,
      room_type: false,
      orientation: false,
      vertical_placement: false,
      ttn_dev_id: false,
      _device_number: false,
      hardware_id: false,
      heartbeat_interval: false,
      latest_transmission_received_at: false,
      _remove_from_group: true,
      id: true,
    };

    return { initialColumnPinning, initialColumnVisibility };
  }, []);

  return (
    <div data-testid="sensors-paginated-table-div">
      <Table
        key={tableIdentifier}
        tableIdentifier={`sensors-paginated-table-${tableIdentifier}`}
        data={sensorTableData}
        columns={columns}
        loading={isPending}
        showSearchBar={showSearchBar}
        showTogglingColumns={showToggling}
        showTogglingFilters
        sortBy={[{ id: 'moisture_state', desc: true }]}
        placeholder={t('components:tables.SensorsPaginatedTable.searchBar.placeholder')}
        onClick={onClick}
        initialColumnPinning={initialColumnPinning}
        initialColumnVisibility={initialColumnVisibility}
        actions={actions}
        showInfoTooltip={true}
        {...props}
      />

      {activeSensorId && (
        <>
          <UpdateSensorModal
            onSubmit={onUpdateSensorModalSubmit}
            show={showSensorModal}
            setShow={setShowUpdateModal}
            values={sensor}
            showName
            showDescription
            showTreeType
            showEnableAlarms
            useLinkAsTitle
          />
          <UpdateSensorAlarmRulesModal
            sensorId={activeSensorId}
            show={showAlarmRulesModal}
            setShow={setShowAlarmRulesModal}
            hideToggleAlarmsButton
          />
          <SensorDownlinksModal
            sensorId={activeSensorId}
            show={showSensorDownlinksModal}
            setShow={setShowSensorDownlinksModal}
            onSubmit={onUpdateSensorFrequencyModalSubmit}
          />
          {sensor?.tags && (
            <SensorTagsModal
              onSubmit={onUpdateSensorTagsModalSubmit}
              setShow={setShowSensorTagsModal}
              show={showSensorTagsModal}
              sensorTags={sensor.tags}
              sensorId={activeSensorId}
              showCopyFromSensorButton
            />
          )}
        </>
      )}

      {/* Action components */}
      <ActionComponents
        sensors={sensors}
        sensorIdsToUpdate={sensorIdsToUpdate}
        sensorIdsToIncludeInReport={sensorIdsToIncludeInReport}
        showCustomerSelect={showCustomerSelect}
        setShowCustomerSelect={setShowCustomerSelect}
        showSensorDownlinkModal={showSensorDownlinkModal}
        setShowSensorDownlinkModal={setShowSensorDownlinkModal}
        showSensorTagModal={showSensorTagModal}
        setShowSensorTagModal={setShowSensorTagModal}
        showReportModal={showReportModal}
        setShowReportModal={setShowReportModal}
      />
    </div>
  );
};
