import classNames from 'classnames';
import { sortBy, uniq } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import {
  SensorSearchbar,
  SensorsLists,
} from 'components/BlueprintCanvas/components/Sidebar/SidebarAddSensors/components';
import Permission from 'utils/enums/Permission';
import {
  useCurrentUserCustomers,
  useUtilsBulkSensorPositionCheck,
  useUtilsPermissionsSensors,
} from 'utils/hooks/data';
import Customer from 'utils/types/Customer';
import Sensor from 'utils/types/Sensor';

interface SidebarAddSensorsProps {
  userSensors: Sensor[];
  attachedSensors: Sensor[];
}

export const SidebarAddSensors: React.FC<SidebarAddSensorsProps> = ({
  userSensors = [],
  attachedSensors = [],
}) => {
  /*
  Sidebar Component for attaching sensors to a blueprint.
  
  Features: 
    - Searchbar
    - List of sensors sorted by owning organisation
        - Attach button for each sensor
    - Show/Hide based on edit mode being enabled. 

  Data Used: 
    - List of sensors available to the user
    - List of sensors currently attached to the blueprint
    - List of organizations the user has access to 
  */
  // Context
  const { editModeEnabled, blueprintId } = useContext(BlueprintCanvasContext);

  // State
  const [searchTerm, setSearchTerm] = useState('');
  // Hooks
  const { t } = useTranslation('components');
  const { customers: organisations } = useCurrentUserCustomers();

  // Sensor > Blueprint hooks used to remove sensors that are either:
  // - Already placed on the current blueprint
  // - Already attached to the current blueprint
  // - Already attached to another blueprint
  const { sensorHasBlueprintPosition: sensorIsAttachedAnyBlueprint } =
    useUtilsBulkSensorPositionCheck(userSensors);

  const { sensorHasBlueprintPosition: sensorIsPlacedOnCurrentBlueprint } =
    useUtilsBulkSensorPositionCheck(userSensors, {
      onlyValidPositions: true,
      blueprintId,
    });
  const { sensorHasBlueprintPosition: sensorIsAttachedCurrentBlueprint } =
    useUtilsBulkSensorPositionCheck(userSensors, {
      blueprintId,
    });

  // Sensor permissions used to narrow the sensor list down to only sensors the user has permission to attach
  const { sensorPermissions } = useUtilsPermissionsSensors(userSensors, Permission.Edit);

  // Memoisation
  const unattachedSensorsByOrganisationName = useMemo(() => {
    // Sort organisations and map them by id
    const sortedOrganisations = sortBy(organisations, org => org.name);
    const organisationByOrganisationId = new Map<string, Customer>();
    sortedOrganisations.forEach(organisation => {
      organisationByOrganisationId.set(organisation.id, organisation);
    });

    // Filter out sensors that are already attached to the blueprint
    const attachedSensorIds = attachedSensors.map(sensor => sensor.id);
    const unattachedSensors = sortBy(
      userSensors.filter(sensor => !attachedSensorIds.includes(sensor.id)),
      sensor => sensor.name,
    );

    // Map unattached sensors by organisation name
    const unattachedSensorsByOrganisationName = new Map<string, Sensor[]>();
    sortedOrganisations.forEach(organisation => {
      unattachedSensorsByOrganisationName.set(organisation.name, []);
    });
    unattachedSensorsByOrganisationName.set(t('inputs.SensorsSelectInput.ownedByOther'), []);

    unattachedSensors.forEach(sensor => {
      const organisationName: string =
        organisationByOrganisationId.get(sensor.customer_id as string)?.name ??
        t('inputs.SensorsSelectInput.ownedByOther');
      unattachedSensorsByOrganisationName.get(organisationName)?.push(sensor);
    });

    // Remove empty organisations from unattached sensors map
    Array.from(unattachedSensorsByOrganisationName.keys()).forEach(organisationName => {
      if (unattachedSensorsByOrganisationName.get(organisationName)?.length === 0) {
        unattachedSensorsByOrganisationName.delete(organisationName);
      }
    });

    const searchedUnattachedSensorsByOrganisationName = searchTerm
      ? searchSensorsByOrganisationMap(searchTerm, unattachedSensorsByOrganisationName)
      : unattachedSensorsByOrganisationName;

    return searchedUnattachedSensorsByOrganisationName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedSensors, organisations, userSensors, searchTerm]);

  const { sensorIdsToTooltips, disabledSensorIds } = useMemo(() => {
    const sensorIdsWithoutSufficientPermission = userSensors?.filter(
      sensor => sensorPermissions && !sensorPermissions[sensor.id],
    );

    // Sensors currently placed on this blueprint
    const sensorIdsPlacedOnCurrentBlueprint = userSensors?.filter(
      sensor => sensorIsPlacedOnCurrentBlueprint && sensorIsPlacedOnCurrentBlueprint[sensor.id],
    );

    // Sensors attached to another blueprint (with or without position)
    const sensorIdsAttachedAnotherBlueprint = userSensors?.filter(
      sensor =>
        sensorIsPlacedOnCurrentBlueprint &&
        sensorIsAttachedAnyBlueprint &&
        sensorIsAttachedCurrentBlueprint &&
        !sensorIsPlacedOnCurrentBlueprint[sensor.id] &&
        !sensorIsAttachedCurrentBlueprint[sensor.id] &&
        sensorIsAttachedAnyBlueprint[sensor.id],
    );

    // Create tooltips
    const sensorIdsToTooltips: Map<string, string> = new Map<string, string>();
    (sensorIdsWithoutSufficientPermission || []).forEach(sensor => {
      sensorIdsToTooltips.set(
        sensor.id,
        t('blueprints.BlueprintAttachedSensorsField.notSufficientPermission'),
      );
    });
    (sensorIdsPlacedOnCurrentBlueprint || []).forEach(sensor => {
      sensorIdsToTooltips.set(
        sensor.id,
        t('blueprints.BlueprintAttachedSensorsField.placedOnCurrentBlueprint'),
      );
    });
    (sensorIdsAttachedAnotherBlueprint || []).forEach(sensor => {
      sensorIdsToTooltips.set(
        sensor.id,
        t('blueprints.BlueprintAttachedSensorsField.attachedAnotherBlueprint'),
      );
    });
    //Disable unattachable sensors
    const disabledSensorIds = uniq([
      ...(sensorIdsWithoutSufficientPermission || []),
      ...(sensorIdsPlacedOnCurrentBlueprint || []),
      ...(sensorIdsAttachedAnotherBlueprint || []),
    ]).map(sensor => sensor.id);

    return { sensorIdsToTooltips, disabledSensorIds };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userSensors,
    sensorIsAttachedAnyBlueprint,
    sensorIsAttachedCurrentBlueprint,
    sensorIsPlacedOnCurrentBlueprint,
    sensorPermissions,
  ]);

  return (
    <div
      className={classNames(
        'relative overflow-hidden transition-[height]',
        editModeEnabled ? 'duration-700 h-full' : 'h-0 duration-500',
      )}
    >
      <div className="w-full h-full flex flex-col">
        <div className="text-brand-beige text-sm py-1 px-2 my-2 border-t border-brand-green-light-1 flex-none">
          {t('blueprints.Sidebar.addSensors.header')}
        </div>
        <div className="px-2 h-44 grow">
          <div className="flex flex-col w-full h-full bg-brand-beige rounded">
            <SensorSearchbar onSearch={search => setSearchTerm(search)} />
            <div
              className={classNames(
                'flex flex-col items-center text-brand-green grow text-xs w-full overflow-hidden',
              )}
            >
              {unattachedSensorsByOrganisationName.size === 0 && (
                <div className="w-full h-full flex items-center justify-center text-wrap px-4">
                  {t('blueprints.Sidebar.addSensors.noSensorsFound')}
                </div>
              )}
              <div
                className={classNames(
                  'w-full h-full',
                  '[&::-webkit-scrollbar]:w-2',
                  '[&::-webkit-scrollbar-track]:bg-brand-beige [&::-webkit-scrollbar-track]:rounded-full',
                  '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3 [&::-webkit-scrollbar-thumb]:rounded-full',
                )}
              >
                {unattachedSensorsByOrganisationName.size > 0 && (
                  <SensorsLists
                    sensorsByOrganisation={unattachedSensorsByOrganisationName}
                    disabledSensorIds={disabledSensorIds}
                    sensorIdsToTooltips={sensorIdsToTooltips}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const searchSensorsByOrganisationMap = (
  searchTerm: string,
  sensorsByOrganisationMap: Map<string, Sensor[]>,
): Map<string, Sensor[]> => {
  searchTerm = searchTerm.toLowerCase();
  const searchedSensorsByOrganisationMap = new Map<string, Sensor[]>();

  for (const [organisationName, sensors] of sensorsByOrganisationMap) {
    if (organisationName.toLowerCase().includes(searchTerm)) {
      searchedSensorsByOrganisationMap.set(organisationName, sensors);
      continue;
    }
    searchedSensorsByOrganisationMap.set(
      organisationName,
      sensors.filter(
        sensor =>
          sensor.name.toLowerCase().includes(searchTerm) ||
          sensor.geographic_location?.description?.toLocaleLowerCase().includes(searchTerm),
      ),
    );

    if (searchedSensorsByOrganisationMap.get(organisationName)?.length === 0)
      searchedSensorsByOrganisationMap.delete(organisationName);
  }

  return searchedSensorsByOrganisationMap;
};
