import Konva from 'konva';
import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { StageState } from 'components/CanvasStage/types';

type CanvasStageContextProps = {
  stageState: StageState;
  setStageState: Dispatch<SetStateAction<StageState>>;
  mainStageRef: MutableRefObject<Konva.Stage> | undefined;
};

export const CanvasStageContext = React.createContext<CanvasStageContextProps>({
  stageState: {
    scale: 0.01,
    x: 0,
    y: 0,
  },
  setStageState: () => {},
  mainStageRef: undefined,
});
