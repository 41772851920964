import { forwardRef, Ref, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import { CustomSelectControl, CustomSelectInput } from 'components/inputs/components';

export interface Option<T> {
  readonly value: T;
  readonly label: string;
}

export interface SelectProps<T extends unknown> {
  onSelect: (option: T, { clear }: { clear: () => void }) => void;
  onClear?: () => void;
  initialSelectedOption?: T;
  className?: string;
  id?: string;
  name?: string;
  isPending?: boolean;
  placeholder?: string;
  optionsToSelect?: T[];
  valueToTextFn?: (value: T) => string;
  valueToIdFn?: (value: T) => string;
  noOptionsText?: string;
  loadingText?: string;
  isDisabled?: boolean;
}

export const GenericSelectInput = forwardRef(
  <T extends unknown>(
    {
      initialSelectedOption,
      onSelect,
      onClear,
      className,
      id,
      name,
      placeholder,
      isPending,
      optionsToSelect,
      valueToTextFn = (value: T) => String(value),
      valueToIdFn = (value: T) => String(value),
      noOptionsText,
      loadingText,
      ...props
    }: SelectProps<T>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [selectedOption, setSelectedOption] = useState<Option<T> | null>(null);

    const { t } = useTranslation('components');

    const clear = () => setSelectedOption(null);

    const onChange = (selectedOptionValue: SingleValue<Option<T>>) => {
      const _selectedOption = options.find(option => option.value === selectedOptionValue?.value);
      setSelectedOption(_selectedOption || null);

      const selectedValue = optionsToSelect?.find(option => option === selectedOptionValue?.value);

      if (selectedValue) {
        return onSelect(selectedValue, { clear });
      }
      if (onClear) {
        return onClear();
      }
    };

    const options = useMemo(
      () =>
        optionsToSelect
          ? optionsToSelect.map(
              value =>
                ({
                  label: valueToTextFn(value),
                  value,
                }) as Option<T>,
            )
          : [],
      [optionsToSelect, valueToTextFn],
    );

    useEffect(() => {
      if (initialSelectedOption) {
        const selectedOption = options.find(
          option => valueToIdFn(option.value) === valueToIdFn(initialSelectedOption),
        );
        setSelectedOption(selectedOption || null);
      } else {
        setSelectedOption(null);
      }
    }, [options, initialSelectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Select<Option<T>, false>
        /* @ts-ignore */
        ref={ref}
        autoFocus={false}
        defaultMenuIsOpen={false}
        noOptionsMessage={() => noOptionsText || t('inputs.GenericSelect.noOptionsText')}
        loadingMessage={() => loadingText || t('inputs.GenericSelect.loadingMessage')}
        className={className}
        classNamePrefix="select"
        placeholder={placeholder}
        name={name}
        inputId={id}
        isPending={isPending}
        value={selectedOption}
        formatOptionLabel={option => <>{option.label}</>}
        components={{
          Input: CustomSelectInput,
          Control: CustomSelectControl,
        }}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        menuShouldScrollIntoView
        menuPosition="fixed"
        openMenuOnClick
        openMenuOnFocus
        {...props}
      />
    );
  },
) as <T extends unknown>(props: SelectProps<T> & { ref: Ref<HTMLInputElement> }) => JSX.Element;
