import classNames from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Tree, { RawNodeDatum } from 'react-d3-tree';

import { GroupHierarchyNode } from 'components/GroupHierarchy/components';
import GroupHierarchyTree, { parseGroupHierarchyD3Tree } from 'utils/types/GroupHierarchyTree';

export type GroupHierarchyProps = {
  groupId: string;
  groupHierarchyTree: GroupHierarchyTree;
  isPreview?: boolean;
};

export const GroupHierarchy = ({
  groupId,
  groupHierarchyTree,
  isPreview = false,
}: GroupHierarchyProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const [groupsData, setGroupsData] = useState<RawNodeDatum>(
    parseGroupHierarchyD3Tree(groupHierarchyTree) ?? { name: '', attributes: {}, children: [] },
  );

  useEffect(() => {
    setGroupsData(parseGroupHierarchyD3Tree(groupHierarchyTree));
  }, [groupHierarchyTree]);

  useLayoutEffect(() => {
    // places the tree in the center of the container
    if (containerRef.current !== null) {
      const { width, height } = containerRef.current.getBoundingClientRect();

      const newPoint = {
        x: width / 2,
        y: height / 4,
      };

      setTranslate(newPoint);
    }
  }, []);

  const sortNodes = (node: RawNodeDatum): RawNodeDatum => {
    if (node.children) {
      node.children = node.children.map(sortNodes).sort((a, b) => {
        const nameA = a.name?.toLowerCase() || '';
        const nameB = b.name?.toLowerCase() || '';
        return nameA.localeCompare(nameB);
      });
    }
    return node;
  };

  return (
    <div
      ref={containerRef}
      className={classNames('flex flex-col w-full', {
        'h-full pointer-events-none': isPreview,
        'h-[800px]': !isPreview,
      })}
    >
      <Tree
        translate={translate}
        zoom={isPreview ? 0.3 : 0.6}
        pathFunc={'step'}
        pathClassFunc={() => 'stroke-current text-brand-green-light-2 bg-blue-500'}
        leafNodeClassName="text-brand-gray"
        branchNodeClassName="text-brand-gray"
        rootNodeClassName="text-brand-gray"
        enableLegacyTransitions={true}
        initialDepth={4}
        data={sortNodes(groupsData)}
        orientation="vertical"
        scaleExtent={isPreview ? { min: 0.1, max: 0.2 } : { min: 0.5, max: 0.8 }}
        dimensions={{
          width: containerRef.current?.clientWidth || 0,
          height: containerRef.current?.clientHeight || 0,
        }}
        separation={{ siblings: 1.8, nonSiblings: 2.0 }}
        nodeSize={{ x: 200, y: 320 }}
        renderCustomNodeElement={({ nodeDatum, toggleNode }) => (
          <GroupHierarchyNode
            key={nodeDatum.attributes?.id.toString()}
            node={nodeDatum}
            groupId={groupId}
            toggleNode={toggleNode}
            isRootNode={groupHierarchyTree?.id === nodeDatum.attributes?.id}
          />
        )}
      />
    </div>
  );
};
