import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { useSensorsBulk } from 'utils/hooks/data/sensors-bulk/useSensorsBulk';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import HookOptions from 'utils/types/HookOptions';
import Transmission from 'utils/types/Transmission';

type TransmissionsResult = {
  [key in keyof Transmission]: Transmission[key][];
};

export const useSensorsBulkTransmissions = (
  sensorIds: string[],
  {
    fromTimestamp = undefined,
    toTimestamp = undefined,
    limit = undefined,
    fields,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
    limit?: number;
    fields: (keyof Transmission)[];
  } = { fields: [] },
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp!);
  toTimestamp = floorToLatest5Minutes(toTimestamp!);

  const queryKey = [
    'useSensorsBulkTransmissions',
    sensorIds,
    fromTimestamp,
    toTimestamp,
    limit,
    fields,
  ];

  const { data, isPending, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk/transmissions', {
        sensor_ids: sensorIds,
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
        limit,
        fields,
      }),
    select: (transmissionsResultRaw: any) => {
      transmissionsResultRaw.timestamp = transmissionsResultRaw.timestamp.map(
        (timestampRaw: string) => new Date(timestampRaw),
      );
      return transmissionsResultRaw as TransmissionsResult;
    },
    enabled: enableGet && sensorIds && sensorIds.length > 0 && !!fromTimestamp && !!toTimestamp,
  });

  const { sensors } = useSensorsBulk(sensorIds);

  const transmissions = useMemo(() => {
    if (!data) return [];
    const keys = Object.keys(data) as (keyof Transmission)[];
    if (keys.length === 0) return [];
    const n = data[keys[0]]?.length;
    if (!n) return [];
    const transmissions: Transmission[] = [];
    for (let i = 0; i < n; i++) {
      const transmission: any = {};
      for (const key of keys) {
        transmission[key] = data[key]![i];
      }
      transmissions.push(transmission);
    }

    return parseTransmissionsMixed({
      transmissions,
      sensors: sensors || [],
      anomalies: [],
    });
  }, [data, sensors]);

  return {
    transmissions,
    isPending: isPending || isLoading || isFetching,
  };
};
