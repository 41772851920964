import {
  faCircleQuestion,
  faCompass,
  faDoorOpen,
  faFan,
  faHome,
  faMicroscope,
  faRulerVertical,
  faScrewdriverWrench,
  faThermometerHalf,
} from '@fortawesome/free-solid-svg-icons';

import {
  faRHTemperatureSensor,
  faSquirtyBricks2,
  faSquirtyCasing,
  faSquirtyCrane,
  faSquirtyHouse1,
  faSquirtyInsulation,
  faSquirtyKitchen,
  faSquirtyRoomType,
  faSquirtyScrew,
  faSquirtyWall,
} from 'components/icons';
import { SensorTagDescriptionName, SensorTagName } from 'utils/types/SensorTags';

export const getSensorTagIcon = (sensorTagName: SensorTagName | SensorTagDescriptionName) => {
  switch (sensorTagName) {
    case 'primary_element_category':
    case 'primary_element_category_description':
      return faHome;
    case 'primary_element_type':
      return faSquirtyWall;
    case 'primary_element_type_description':
      return faSquirtyBricks2;
    case 'complementary_element':
      return faDoorOpen;
    case 'complementary_element_description':
      return faSquirtyHouse1;
    case 'orientation':
      return faCompass;
    case 'room_type':
      return faSquirtyRoomType;
    case 'room_type_description':
      return faSquirtyKitchen;
    case 'vertical_placement':
      return faRulerVertical;
    case 'casing':
      return faSquirtyCasing;
    case 'climate':
      return faThermometerHalf;
    case 'construction_phase':
      return faSquirtyCrane;
    case 'construction_principles':
      return faScrewdriverWrench;
    case 'insulation':
      return faSquirtyInsulation;
    case 'insulation_type':
      return faSquirtyInsulation;
    case 'screw_type':
      return faSquirtyScrew;
    case 'ventilation':
      return faFan;
    case 'usage':
      return faMicroscope;
    case 'measurement_placement':
      return faRHTemperatureSensor;
    default:
      return faCircleQuestion;
  }
};
