import { ComponentPropsWithoutRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonRows: React.FC<
  { rows?: number; rowHeight?: number } & ComponentPropsWithoutRef<'td'>
> = ({ rows = 10, rowHeight = 50, ...props }) => (
  <>
    {[...Array(rows)].map((x, i) => (
      <tr key={i}>
        <td {...props} style={{ height: `${rowHeight}px` }}>
          <Skeleton duration={1} />
        </td>
      </tr>
    ))}
  </>
);
