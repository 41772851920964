import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';

export type BlueprintSnippetLocation = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

export const useSensorBlueprintSnippetLocation = (sensorId?: string) => {
  const queryKey = ['sensorBlueprintSnippetLocation', sensorId];

  const {
    data: snippetLocation,
    isPending,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: async () => await api.get(`/sensors/${sensorId}/blueprints/snippet_location`),
    select: (results: any) => {
      const { x_range, y_range } = results;

      return {
        xMin: x_range[0],
        xMax: x_range[1],
        yMin: y_range[0],
        yMax: y_range[1],
      } as BlueprintSnippetLocation;
    },
    enabled: !!sensorId,
  });

  return {
    snippetLocation,
    isPending: isPending || isFetching || isLoading,
  };
};
