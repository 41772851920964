import { CallbackDataParams } from 'echarts/types/dist/shared';

import { t } from 'translations';
import { brandGray, brandGrayLight2 } from 'utils/colors';
import { echarts } from 'utils/echarts';
import { RiskScoreReasonKey } from 'utils/enums';
import AnomalyValidationType from 'utils/enums/AnomalyValidationType';
import { dateFormat } from 'utils/plots/dateFormat';
import { getRiskScoreReasonKeyText } from 'utils/texts';
import { getAnomalyTypeText } from 'utils/texts/AnomalyType';
import { degrees2worldCornerReverseRounded, radians2degrees } from 'utils/trigonometry';
import {
  DataAnomalyTuple,
  DataField,
  DataTuple,
  GatewayHeartbeatDataField,
  MarkedAreaDataObject,
  RiskScoreDataTuple,
  WeatherWindDataTuple,
} from 'utils/types/PlotTypes';
import TransmissionAnomaly from 'utils/types/TransmissionAnomaly';

// We need this parsing as a result of data not being typed
const parseEventTooltipText = (tooltipData: any) => tooltipData.text;
const parseEventTooltipTimestamp = (tooltipData: any) => tooltipData.xAxis;

// See `src/utils/types/PlotTypes.ts` for why `data` cannot be typed here

export const tooltipFormatterSingleSensor =
  (sensorName: string) =>
  ({
    data: _data,
    seriesName,
    ...props
  }: Omit<CallbackDataParams, 'seriesName'> & {
    seriesName: DataField;
  }) => {
    // Do not show tooltips for marked areas
    const dataObj = _data as MarkedAreaDataObject | undefined;
    if (
      dataObj &&
      typeof dataObj?.coord?.[0]?.[0] === 'object' &&
      typeof dataObj?.coord?.[0]?.[1] === 'number'
    )
      return;
    if (
      (seriesName === 'moisture' || seriesName === 'raw_moisture') &&
      parseEventTooltipText(_data) &&
      parseEventTooltipTimestamp(_data)
    ) {
      return tooltipTextEvent(parseEventTooltipText(_data), parseEventTooltipTimestamp(_data));
    }

    const data = _data as DataTuple | DataAnomalyTuple | WeatherWindDataTuple | RiskScoreDataTuple;

    const sensorText = t('utils:sensorWord');
    const sensorLabel = sensorName || sensorText;
    if (seriesName === 'moisture') return tooltipTextMoistureValid(data[0], sensorLabel, data[1]);
    if (seriesName === 'moisture_invalid') {
      const date = data[0];
      const y = data?.[1];
      if (y === 2) {
        return tooltipTextMoistureInvalidResistanceTooHigh(date, sensorLabel);
      } else if (y === 100) {
        return tooltipTextMoistureInvalidResistanceTooLow(date, sensorLabel);
      } else {
        return tooltipTextMoistureInvalid(date, sensorLabel);
      }
    }
    if (seriesName === 'precip') return tooltipTextPrecipitation(data[0], undefined, data[1]);
    if (seriesName === 'temperature') return tooltipTextTemperature(data[0], sensorLabel, data[1]);
    if (seriesName === 'humidity') return tooltipTextHumidity(data[0], sensorLabel, data[1]);
    if (seriesName === 'voc') return tooltipTextVOC(data[0], sensorLabel, data[1]);
    if (seriesName === 'co2') return tooltipTextCO2(data[0], sensorLabel, data[1]);
    if (seriesName === 'humidity_weather')
      return tooltipTextHumidityWeather(data[0], undefined, data[1]);
    if (seriesName === 'temperature_weather')
      return tooltipTextTemperatureWeather(data[0], undefined, data[1]);
    if (seriesName === 'anomalies') {
      const dataAnomaly = data as DataAnomalyTuple;
      return tooltipTextAnomaly(dataAnomaly[3]);
    }
    if (seriesName === 'wind') {
      const dataWind = data as WeatherWindDataTuple;
      return tooltipTextWindWeather(dataWind[0], dataWind[1], dataWind[2]);
    }
    if (seriesName === 'risk_score') {
      const riskScoreData = data as RiskScoreDataTuple;
      return tooltipTextRiskScore(riskScoreData[0], riskScoreData[1], riskScoreData[2]);
    }

    return '';
  };
export const tooltipTextEvent = (eventText: string, timestamp: string): string => `<div>
      <div
        style="color:${brandGray};font-size:0.8rem;padding-bottom:0.4rem;"
      >
        ${eventText}
      </div>
      <div 
        style="color:${brandGrayLight2};font-size:0.7rem;text-align:right;"
      >
        ${timestamp.replace('T', ' ')}
      </div>
    </div>`;

export const tooltipTextMoistureInvalid = (date: Date, sensorName: string) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const invalidMeasurement: string = t('utils:plots.tooltipTexts.tooltipTextMoistureInvalid', {
    dateString,
    timeString,
    sensorName,
  });
  const explanation: string = t(
    'utils:plots.tooltipTexts.tooltipTextMoistureInvalidUnknownExplanation',
  );
  return `${invalidMeasurement}</br>${explanation}`;
};

export const tooltipTextMoistureInvalidResistanceTooHigh = (date: Date, sensorName: string) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const invalidMeasurement: string = t('utils:plots.tooltipTexts.tooltipTextMoistureInvalid', {
    dateString,
    timeString,
    sensorName,
  });
  const explanation: string = t(
    'utils:plots.tooltipTexts.tooltipTextMoistureInvalidResistanceTooHighExplanation',
  );
  return `${invalidMeasurement}</br>${explanation}`;
};

export const tooltipTextMoistureInvalidResistanceTooLow = (date: Date, sensorName: string) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const invalidMeasurement: string = t('utils:plots.tooltipTexts.tooltipTextMoistureInvalid', {
    dateString,
    timeString,
    sensorName,
  });
  const explanation: string = t(
    'utils:plots.tooltipTexts.tooltipTextMoistureInvalidResistanceTooLowExplanation',
  );
  return `${invalidMeasurement}</br>${explanation}`;
};

export const tooltipTextMoistureValid = (
  date: Date,
  sensorName?: string,
  moisture?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextMoistureValid', {
    y: moisture?.toFixed(1),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextHumidity = (
  date: Date,
  sensorName?: string,
  humidity?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextHumidity', {
    y: humidity?.toFixed(1),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextHumidityWeather = (
  date: Date,
  sensorName?: string,
  humidity?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextHumidityWeather', {
    y: humidity?.toFixed(1),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextTemperature = (
  date: Date,
  sensorName?: string,
  temperature?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextTemperature', {
    y: temperature?.toFixed(1),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextTemperatureWeather = (
  date: Date,
  sensorName?: string,
  temperature?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextTemperatureWeather', {
    y: temperature?.toFixed(1),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextPrecipitation = (
  date: Date,
  sensorName?: string,
  precipitation?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const xAnHourAgo = new Date(date.getTime());
  xAnHourAgo.setHours(xAnHourAgo.getHours() - 1);
  const timeAnHourAgoString = echarts.time.format(xAnHourAgo, '{HH}:{mm}', false);

  return t('utils:plots.tooltipTexts.tooltipTextPrecipitation', {
    y: precipitation?.toFixed(1),
    dateString,
    timeAnHourAgoString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextEMC = (emc: number) =>
  t('utils:plots.tooltipTexts.tooltipTextEMC', {
    y: emc.toFixed(2),
  });

export const tooltipTextCO2 = (
  date: Date,
  sensorName?: string,
  co2?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextCO2', {
    y: co2?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextVOC = (
  date: Date,
  sensorName?: string,
  voc?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextVOC', {
    y: voc?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextSmappee = (seriesName: string, value: [Date, number]) => {
  switch (seriesName) {
    case 'temperature_weather':
      return tooltipTextTemperature(value[0], undefined, value[1]);
    default:
      return tooltipTextEnergyConsumption(value[0], value[1]);
  }
};

export const tooltipTextEnergyConsumption = (date: Date, energy: number) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextEnergyConsumption', {
    y: Math.round(energy / 1000).toLocaleString(),
    dateString,
    timeString,
  });
};

export const tooltipTextAnomaly = (anomaly: TransmissionAnomaly) => {
  const dateString = echarts.time.format(anomaly.timestamp, dateFormat, false);
  const timeString = echarts.time.format(anomaly.timestamp, '{HH}:{mm}', false);
  const typeText = getAnomalyTypeText(anomaly.type);
  const options = {
    dateString,
    timeString,
    typeText,
  };

  // return 'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum\n<br/>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum\n<br/>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum\n<br/>';

  if (anomaly.validation === AnomalyValidationType.AnomalyCorrectlyMarked) {
    return t('utils:plots.tooltipTexts.tooltipTextAnomalyCorrectlyMarked', options);
  } else if (anomaly.validation === AnomalyValidationType.AnomalyIncorrectlyMarked) {
    return t('utils:plots.tooltipTexts.tooltipTextAnomalyIncorrectlyMarked', options);
  }
  return t('utils:plots.tooltipTexts.tooltipTextAnomaly', options);
};

export const tooltipTextWindWeather = (date: Date, windSpeed?: number, windDirRadians?: number) => {
  const dateString = echarts.time.format(date, dateFormat, false);

  const windSpeedFormatted = windSpeed ? windSpeed.toFixed(1) : 'n/a';
  const windDirDegrees = windDirRadians ? radians2degrees(windDirRadians) : NaN;
  const windDirDegreesFormatted = isNaN(windDirDegrees) ? 'n/a' : windDirDegrees.toFixed(0);
  const worldCorner = isNaN(windDirDegrees)
    ? 'n/a'
    : degrees2worldCornerReverseRounded(windDirDegrees, 5);

  return t('utils:plots.tooltipTexts.tooltipTextWindWeather', {
    dateString,
    windSpeed: windSpeedFormatted,
    worldCorner,
    degrees: windDirDegreesFormatted,
  });
};

export const tooltipTextRSSI = (
  date: Date,
  sensorName?: string,
  rssi?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextRSSI', {
    y: rssi?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextSpreadingFactor = (
  date: Date,
  sensorName?: string,
  spreadingFactor?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextSpreadingFactor', {
    y: spreadingFactor?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextSNR = (
  date: Date,
  sensorName?: string,
  snr?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextSNR', {
    y: snr?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextTXPower = (
  date: Date,
  sensorName?: string,
  txPower?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextTXPower', {
    y: txPower?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextBattery = (
  date: Date,
  sensorName?: string,
  battery?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextBattery', {
    y: battery?.toFixed(0),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

export const tooltipTextOhms = (
  date: Date,
  sensorName?: string,
  ohms?: number,
  description?: string,
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  return t('utils:plots.tooltipTexts.tooltipTextOhms', {
    y: ohms?.toFixed(3),
    dateString,
    timeString,
    sensorName: sensorName || '',
    description: formatDescription(description),
  });
};

const tooltipTextRiskScore = (
  date: Date,
  riskScore: number,
  riskScoreReasonsKeys: RiskScoreReasonKey[],
) => {
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const riskScoreText = t('utils:plots.tooltipTexts.tooltipTextRiskScore', {
    riskScore: parseFloat(riskScore.toFixed(2)),
    dateString,
    timeString,
  });
  const riskScoreReasonsText = riskScoreReasonsKeys
    ? t('utils:plots.tooltipTexts.tooltipTextRiskScoreReasons', {
        reasons: riskScoreReasonsKeys
          .map((key: RiskScoreReasonKey) => getRiskScoreReasonKeyText(key))
          .join(', '),
      })
    : '';
  return `${riskScoreText} ${riskScoreReasonsText}`;
};

// Gateways
// export const tooltipFormatterGateways = (
//   { data: _data, seriesName, ...props }: CallbackDataParams,
//   dataField: GatewayHeartbeatDataField
// ) => {
export const tooltipFormatterGateways = (
  data: DataTuple,
  seriesName: string,
  dataField: GatewayHeartbeatDataField,
) => {
  const date = data[0];
  const dateString = echarts.time.format(date, dateFormat, false);
  const timeString = echarts.time.format(date, '{HH}:{mm}', false);
  const gatewayId = seriesName;

  if (dataField === 'meta_cpu_usage')
    return t('utils:plots.tooltipTexts.tooltipFormatterGatewaysCpuUsage', {
      y: data[1].toFixed(1),
      dateString,
      timeString,
      gatewayId,
    });
  if (dataField === 'meta_memory_usage')
    return t('utils:plots.tooltipTexts.tooltipFormatterGatewaysMemoryUsage', {
      y: data[1].toFixed(1),
      dateString,
      timeString,
      gatewayId,
    });
  if (dataField === 'meta_sd_card_usage')
    return t('utils:plots.tooltipTexts.tooltipFormatterGatewaysSDCardUsage', {
      y: data[1].toFixed(1),
      dateString,
      timeString,
      gatewayId,
    });
  if (dataField === 'meta_rootfs_usage')
    return t('utils:plots.tooltipTexts.tooltipFormatterGatewaysRootfsUsage', {
      y: data[1].toFixed(1),
      dateString,
      timeString,
      gatewayId,
    });
  if (dataField === 'meta_modem_signal_rssi')
    return t('utils:plots.tooltipTexts.tooltipFormatterGatewaysRssi', {
      y: data[1].toFixed(0),
      dateString,
      timeString,
      gatewayId,
    });

  return '';
};

const formatDescription = (description?: string) =>
  description ? ` - ${description.slice(0, 30)}` : undefined;
