import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlarmRulesSelectInput, LockSwitch } from 'components';
import { faSquirtyAdmin } from 'components/icons';
import { useSelectedCustomer } from 'utils/hooks';
import { useAdminAlarmRules, useCustomerAlertRules } from 'utils/hooks/data';

export const AdminAlertRulesSelect: React.FC<{
  currentAlarmRuleIds?: string[];
}> = ({ currentAlarmRuleIds }) => {
  const [enableAlarmRules, setEnableAlarmRules] = useState(false);

  const { t } = useTranslation('components');

  const { customer } = useSelectedCustomer();

  const { alarmRules: allAlarmRules } = useAdminAlarmRules();

  const { isPending, adminAttachAlarmRuleById, adminDetatchAlarmRuleById } = useCustomerAlertRules(
    customer?.id,
    {
      ignoreAdminCustomerOverwrite: true,
    },
  );

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start gap-2 items-center">
          <FontAwesomeIcon
            className="text-brand-gray-light-1"
            icon={faSquirtyAdmin}
            size="sm"
            data-tooltip-content={t('AdminOnlyDiv.dataTip')}
            data-tooltip-id="route-tooltip"
          />
          <p className="flex gap-1">
            <span>{t('alarmRuleSettings.AlarmRulesSelectInput.text')}</span>
            <strong>{customer?.name}</strong>
          </p>
        </div>

        <LockSwitch isChecked={enableAlarmRules} setIsChecked={setEnableAlarmRules} />
      </div>
      <AlarmRulesSelectInput
        onBlur={async ({ alarmRuleIds }) => {
          // Determine deleted and newly added IDs
          const newAlarmRuleIds = alarmRuleIds.filter(
            alarmRuleId => !currentAlarmRuleIds?.includes(alarmRuleId),
          );
          const deletedAlarmRuleIds = (currentAlarmRuleIds || []).filter(
            alarmRuleId => !alarmRuleIds.includes(alarmRuleId),
          );

          // Add new alarm rules
          for (const alarmRuleId of newAlarmRuleIds) {
            await adminAttachAlarmRuleById(alarmRuleId);
          }

          // Remove alarm rules
          for (const alarmRuleId of deletedAlarmRuleIds) {
            await adminDetatchAlarmRuleById(alarmRuleId);
          }
        }}
        initialSelectedAlarmRuleIds={currentAlarmRuleIds}
        disabled={!enableAlarmRules}
        isPending={isPending}
        alarmRulesToSelect={allAlarmRules}
      />
    </div>
  );
};
