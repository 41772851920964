import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import AlarmRuleForm, { AlarmRuleFormValues } from 'components/forms/AlarmRuleForm';
import Modal from 'components/modals/Modal';
import AlarmRuleType from 'utils/enums/AlarmRuleType';

export const AlertRuleModal: React.FC<{
  showType?: boolean;
  showTranslations?: boolean;
  alarmRuleType: AlarmRuleType;
  showCreateModal: boolean;
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (alarmRule: AlarmRuleFormValues) => Promise<void>;
}> = ({
  showType,
  showTranslations,
  alarmRuleType,
  showCreateModal,
  setShowCreateModal,
  onSubmit,
}) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('alarmRuleSettings.createModal.title')}
      setShow={setShowCreateModal}
      show={showCreateModal}
    >
      <AlarmRuleForm
        onSubmit={onSubmit}
        resetFormAfterSubmission
        values={{
          type: alarmRuleType,
        }}
        showTranslations={showTranslations}
        showType={showType}
      />
    </Modal>
  );
};
