import classNames from 'classnames';
import React from 'react';
import Carousel, { DotProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FileBase from 'utils/types/FileBase';

export const ImageCarousel: React.FC<{ images?: FileBase[] }> = ({ images = [] }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: images?.length > 1 ? 2 : 1,
    },
  };
  const CustomDot = ({ index, active, onClick }: DotProps) => (
    <button
      className={classNames('rounded-full border-2 w-3 h-3 ml-1', {
        'bg-brand-blue border-brand-beige': active && images.length > 1,
        'border-brand-beige': !active && images.length > 1,
        'border-transparent bg-transparent': images.length <= 2,
      })}
      onClick={() => {
        console.log(index);
        onClick!();
      }}
    ></button>
  );

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      keyBoardControl={true}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style mb-4"
      itemClass="px-2 h-80 "
      customDot={<CustomDot />}
    >
      {images?.map((imageFile, index) => (
        <img
          className={classNames('rounded-lg w-full h-full object-center overflow-hidden', {
            'object-contain': images.length <= 1,
            'object-cover': images.length > 1,
          })}
          src={imageFile.storage_url_thumbnail}
          alt={imageFile.description}
          data-testid="image-card"
          draggable={false}
        />
      ))}
    </Carousel>
  );
};
