import { Column } from '@tanstack/react-table';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from 'utils/hooks';

export const ToggleColumnsVisibilityModal = <T extends {}>({
  columsToToggle,
  setIsToggleColumnsVisibilityModalOpen,
  getIsAllColumnsVisible,
  getToggleAllColumnsVisibilityHandler,
}: {
  columsToToggle: Column<T>[];
  setIsToggleColumnsVisibilityModalOpen: Dispatch<SetStateAction<boolean>>;
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation('components');

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsToggleColumnsVisibilityModalOpen(false));

  const sortedColumnsToToggle = columsToToggle
    .filter(column => column.columnDef.meta?.hideFromToggleModal !== true)
    .map(column => {
      const x =
        typeof column.columnDef.header === 'function'
          ? (column.columnDef.header as () => string | ReactNode)
          : (column.columnDef.header as string);
      const label = typeof x === 'function' ? x() : x;
      return { column, label };
    })
    .sort((a, b) => {
      const labelA = typeof a.label === 'string' ? a.label : '';
      const labelB = typeof b.label === 'string' ? b.label : '';
      return labelA.localeCompare(labelB);
    });

  return (
    <div
      ref={ref}
      className="absolute z-50 bg-white right-1 top-2 rounded max-h-96 overflow-y-auto shadow             
                [&::-webkit-scrollbar]:w-2 
              [&::-webkit-scrollbar-track]:bg-brand-gray-light-4 
              [&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3
                [&::-webkit-scrollbar-track]:rounded-full
                [&::-webkit-scrollbar-thumb]:rounded-full"
    >
      <div>
        <label className="flex text-brand-gray text-xs items-center text-left p-1 m-1">
          <input
            type="checkbox"
            checked={getIsAllColumnsVisible()}
            onChange={getToggleAllColumnsVisibilityHandler()}
            className="mr-1"
          />{' '}
          {t('table.Table.ToggleColumnsVisibilityModal.all')}
        </label>
      </div>
      {sortedColumnsToToggle.map(({ column, label }) => (
        <div key={column.id} className="px-1">
          <label className="flex text-brand-gray text-xs items-center text-left p-1 m-1">
            <input
              type="checkbox"
              checked={column.getIsVisible()}
              disabled={!column.getCanHide()}
              onChange={column.getToggleVisibilityHandler()}
              className="mr-1"
            />{' '}
            <span className="whitespace-nowrap">{label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};
