import { useMemo } from 'react';

import { SensorJoinAcceptsTable } from 'components/tables/SensorJoinAcceptsTable';
import { useSensorJoinAccepts } from 'utils/hooks/data';
import TimePeriod from 'utils/types/TimePeriod';

export const SensorJoinAcceptsTableSelfContained: React.FC<{
  sensorId: string;
  timePeriod: Required<TimePeriod>;
  expandable?: boolean;
}> = ({ sensorId, timePeriod: [timeFrom, timeTo], ...props }) => {
  const { sensorJoinAccepts } = useSensorJoinAccepts(sensorId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  const sensorJoinAcceptsData = useMemo(() => sensorJoinAccepts || [], [sensorJoinAccepts]);

  return (
    <SensorJoinAcceptsTable
      tableIdentifier={sensorId || 'sensor-values'}
      sensorJoinAccepts={sensorJoinAcceptsData}
      {...props}
    />
  );
};
