import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertCard, AlertsContainer } from 'components';
import { faSquirtyAlert } from 'components/icons';
import { sortAlerts } from 'utils/sortAlerts';
import { Alarm } from 'utils/types';

export const AlertsOverview = ({ alerts, isPending }: { alerts: Alarm[]; isPending: boolean }) => {
  const { t } = useTranslation('components');

  const sortedAlerts = useMemo(() => sortAlerts(alerts) ?? [], [alerts]);

  return (
    <div className="flex flex-col mb-10">
      <h1 className="mb-4">
        <FontAwesomeIcon className="mr-3" icon={faSquirtyAlert} />
        {t('Alerts.AlertsOverview.title')}
      </h1>

      <div className="flex flex-row justify-between pb-2 items-end text-brand-gray text-sm">
        {t('Alerts.AlertsOverview.alertsFound', { counterAlerts: alerts.length })}
      </div>

      {!!sortedAlerts.length && (
        <AlertsContainer
          alerts={sortedAlerts}
          className="max-h-[400px]"
          renderAlertCard={alert => <AlertCard alert={alert} isPending={isPending} />}
        />
      )}
    </div>
  );
};
