import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import Alarm, { parseAlarm } from 'utils/types/Alarm';

export const useAlert = (alarmId: string) => {
  const queryKey = ['alarms', alarmId];

  const queryClient = useQueryClient();

  // Get
  const { data: alert, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alarmId}`),
    select: (alarm: any) => parseAlarm(alarm),
    enabled: !!alarmId,
  });

  // Resolving
  const { mutateAsync: resolveAlert } = useMutation({
    mutationFn: async ({
      resolvedReason,
      fileIds,
    }: {
      resolvedReason: string;
      fileIds?: string[];
    }): Promise<Alarm> => {
      const alertRaw = await api.post(`/alarms/${alarmId}/resolve`, {
        resolved_reason: resolvedReason,
        resolved_file_ids: fileIds || [],
      });
      return parseAlarm(alertRaw);
    },
    onSuccess: async (alert, resolvedReason) => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // Responsible
  const { mutateAsync: assignResponsible } = useMutation({
    mutationFn: async (responsibleUserId: string): Promise<Alarm> => {
      const alertRaw = await api.post(`/alarms/${alarmId}/responsible`, {
        responsible_user_id: responsibleUserId,
      });
      return parseAlarm(alertRaw);
    },
    onSuccess: async (alert, responsibleUserId) => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    alert,
    isPending,
    resolveAlert,
    assignResponsible,
  };
};
