import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import { BlueprintSidebarContext } from 'components/BlueprintCanvas/components/Sidebar/SidebarContext';
import { useBlueprintSensors } from 'utils/hooks/data';
import { BlueprintPosition } from 'utils/types';

export const BlueprintSidebarSensor: React.FC<{
  sensorId: string;
  sensorDescription: string;
  sensorName: string;
  blueprintPosition: BlueprintPosition;
  isAttached: boolean;
}> = ({ sensorId, sensorDescription, sensorName, blueprintPosition, isAttached }) => {
  const { t } = useTranslation('components');

  const {
    blueprintId,
    activeBlueprintPosition,
    setActiveBlueprintPosition,
    setModifyingAttachedSensors,
    hiddenBlueprintPositionIds,
    setHiddenBlueprintPositionsIds,
    editModeEnabled,
  } = useContext(BlueprintCanvasContext);

  const { dragImageRef } = useContext(BlueprintSidebarContext);
  const { removeSensorFromBlueprintById, removeSensorFromBlueprintPending } =
    useBlueprintSensors(blueprintId);

  const [isHidden, setIsHidden] = useState(false);
  const [positioningSensorOnBlueprint, setPositioningSensorOnBlueprint] = useState(false);

  const isHighlighted = activeBlueprintPosition === blueprintPosition;

  const onHide = () => {
    setIsHidden(true);
    if (blueprintPosition && !hiddenBlueprintPositionIds.includes(blueprintPosition.id)) {
      setHiddenBlueprintPositionsIds([...hiddenBlueprintPositionIds, blueprintPosition.id]);
    }
  };

  const onReveal = () => {
    setIsHidden(false);
    if (hiddenBlueprintPositionIds.includes(blueprintPosition.id)) {
      setHiddenBlueprintPositionsIds(
        hiddenBlueprintPositionIds.filter(position => position !== blueprintPosition.id),
      );
    }
  };

  useEffect(() => {
    if (editModeEnabled) setIsHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModeEnabled]);

  const onDrag = (event: React.DragEvent<HTMLDivElement>): void => {
    setPositioningSensorOnBlueprint(true);
    event.dataTransfer.setData('blueprint-position-id', blueprintPosition.id);
    event.dataTransfer.setData('blueprint-sensor-id', blueprintPosition.sensor_id);

    if (dragImageRef?.current)
      event.dataTransfer.setDragImage(
        dragImageRef.current,
        dragImageRef.current.offsetWidth / 2,
        dragImageRef.current.offsetHeight,
      );
    setActiveBlueprintPosition(blueprintPosition);
  };

  const buttonTooltip = (): string => {
    if (editModeEnabled) {
      return t('blueprints.Sidebar.dataTip.detachSensor');
    } else {
      if (isHidden) {
        return t('blueprints.Sidebar.dataTip.revealSensor');
      } else {
        return t('blueprints.Sidebar.dataTip.hideSensor');
      }
    }
  };

  return (
    <>
      <div
        className={classNames(
          'flex flex-row items-center text-brand-beige w-full text-xs px-2 hover:bg-brand-green-light-1 border-b border-brand-green-light-1/50 py-1',
          {
            'text-brand-beige': isAttached,
            'text-brand-beige/50': !isAttached,
            'bg-brand-green-light-1': isHighlighted,
            'cursor-grab': editModeEnabled,
            'cursor-default': !editModeEnabled,
            'cursor-grabbing': positioningSensorOnBlueprint,
          },
        )}
        onMouseEnter={() => {
          if (!editModeEnabled) setActiveBlueprintPosition(blueprintPosition);
        }}
        onMouseLeave={() => {
          if (!editModeEnabled) setActiveBlueprintPosition(undefined);
        }}
        draggable={editModeEnabled}
        onDragStart={onDrag}
        onDragEnd={() => {
          setPositioningSensorOnBlueprint(false);
          setActiveBlueprintPosition(undefined);
        }}
      >
        <div className="grid grid-cols-10 w-full">
          <span
            className={classNames('text-brand-beige text-ellipsis text-nowrap overflow-hidden', {
              'col-span-4': sensorDescription,
              'col-span-10': !sensorDescription,
              'text-brand-beige': isAttached,
              'text-brand-beige/50': !isAttached,
            })}
          >
            {!editModeEnabled && (
              <Link
                className="hover:text-brand-beige-light-1"
                target="_blank"
                rel="noreferrer noopener"
                to={{
                  pathname: `/user/sensors/${sensorId}`,
                }}
              >
                {sensorName}
              </Link>
            )}
            {editModeEnabled && sensorName}
          </span>
          {sensorDescription && (
            <span
              className="col-span-6 text-brand-beige/50 mx-2 text-ellipsis text-nowrap overflow-hidden"
              data-tooltip-content={sensorDescription}
              data-tooltip-id="route-tooltip"
            >
              {sensorDescription}
            </span>
          )}
        </div>{' '}
        <div className="flex grow flex-row-reverse pr-8">
          <div
            className="relative h-fit cursor-pointer"
            data-tooltip-content={buttonTooltip()}
            data-tooltip-id="route-tooltip"
            onClick={() => {
              if (editModeEnabled) {
                if (!removeSensorFromBlueprintPending) {
                  setModifyingAttachedSensors(true);
                  removeSensorFromBlueprintById(sensorId).then(() =>
                    setModifyingAttachedSensors(false),
                  );
                }
              } else {
                if (isHidden) {
                  onReveal();
                } else {
                  onHide();
                }
              }
            }}
          >
            <FontAwesomeIcon
              className={classNames('text-white/0 pointer-events-none')}
              icon={faMinusCircle}
            />
            <div className="absolute top-0 left-0 overflow-hidden ">
              <FontAwesomeIcon
                className={classNames(
                  ' text-brand-orange hover:text-brand-orange-light-1 transition-all duration-300 cursor-pointer',
                  editModeEnabled ? 'translate-x-0' : 'translate-x-[150%]',
                )}
                icon={faMinusCircle}
              />
            </div>
            <div className="absolute top-0 left-0 overflow-hidden">
              <div
                className={classNames(
                  'transition-all duration-300',
                  editModeEnabled ? '-translate-x-[150%]' : 'translate-x-0',
                )}
              >
                <FontAwesomeIcon
                  className="text-brand-beige/50 hover:text-brand-beige/75"
                  icon={isHidden ? faEyeSlash : faEye}
                />
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            className={classNames('text-brand-lime my-auto pr-2', { invisible: isAttached })}
            icon={faExclamationCircle}
            data-tooltip-content={
              editModeEnabled
                ? t('blueprints.Sidebar.dataTip.sensorNotAttachedEditMode')
                : t('blueprints.Sidebar.dataTip.sensorNotAttached')
            }
            data-tooltip-id="route-tooltip"
          />
        </div>
      </div>
    </>
  );
};
