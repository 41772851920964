import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, SubmittingButton, StaticTextInput } from 'components';

const SensorFrequencyUpdate: React.FC<{
  value?: number;
  onSubmit: (frequency: number) => Promise<void>;
}> = ({ value, onSubmit }) => {
  const [formValue, setFormValue] = useState<number>(value || 240);

  const { t } = useTranslation('components');

  return (
    <>
      <label>
        <>
          {t('modals.SensorFrequencyUpdate.label.text.1')} <strong>60</strong>{' '}
          {t('modals.SensorFrequencyUpdate.label.text.2')}
          <br />
          {t('modals.SensorFrequencyUpdate.label.text.3')} <strong>60</strong>{' '}
          {t('modals.SensorFrequencyUpdate.label.text.4')} <strong>2880</strong>{' '}
          {t('modals.SensorFrequencyUpdate.label.text.5')}
        </>
      </label>

      <StaticTextInput
        className="my-5"
        id="frequencyInputGroup"
        prepend={<p>{t('modals.SensorFrequencyUpdate.InputGroup.Prepend.text')}</p>}
        append={<p>{t('modals.SensorFrequencyUpdate.InputGroup.Append.text')}</p>}
        aria-label={t('modals.SensorFrequencyUpdate.InputGroup.ariaLabel')}
        type="number"
        defaultValue={value || 240}
        readOnly={false}
        min="1"
        max="2880"
        placeholder={t('modals.SensorFrequencyUpdate.InputGroup.placeholder')}
        onChange={e => setFormValue(Number(e.target.value))}
      />

      <Alert className="shadow rounded bg-brand-green-light-2 mb-3">
        <small>
          <>
            <strong>{t('modals.SensorFrequencyUpdate.Alert.text.1')}</strong>:{' '}
            {t('modals.SensorFrequencyUpdate.Alert.text.2')}
            <br />
            {t('modals.SensorFrequencyUpdate.Alert.text.3')}{' '}
            {t('modals.SensorFrequencyUpdate.Alert.text.4')}
          </>
        </small>
      </Alert>

      <SubmittingButton
        variant="primary"
        buttonText={t('modals.SensorFrequencyUpdate.Button.buttonText')}
        submittingText={t('modals.SensorFrequencyUpdate.Button.submittingText')}
        submitting={false}
        onClick={async () => await onSubmit(formValue)}
      />
    </>
  );
};

export default SensorFrequencyUpdate;
