import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

export const useUserAlertRuleSensors = (
  userId?: string,
  alarmRuleId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['useUserAlertRuleSensors', userId, alarmRuleId];

  // Get
  const {
    data: sensors,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/users/${userId}/alert_rules/${alarmRuleId}/sensors`),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: !!userId && !!alarmRuleId && enableGet,
  });

  return {
    sensors,
    isPending: isPending || isLoading || isFetching,
  };
};
