import classNames from 'classnames';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  CustomerSelectInput,
  FormCheckboxInput,
  FormTextInput,
  StaticTextInput,
  SubmittingButton,
} from 'components';
import AdminOnlyDiv from 'components/AdminOnlyDiv';
import Form, { FormProps } from 'components/forms/Form';
import TreeTypeSelect from 'components/forms/TreeTypeSelectForm';
import TreeType from 'utils/enums/TreeType';
import Customer from 'utils/types/Customer';
import DeviceLoRaDetails from 'utils/types/DeviceLoRaDetails';
import Sensor from 'utils/types/Sensor';

export type onSubmitValues = Partial<
  Pick<Sensor, 'id' | 'name' | 'customer_id' | 'geographic_location' | 'send_alarms' | 'tree_type'>
>;

export type SensorFormProps = {
  onSubmit: (values: onSubmitValues) => Promise<void>;
  values?: Partial<Sensor>;
  deviceLoRaDetails?: DeviceLoRaDetails;
  showName?: boolean;
  showDescription?: boolean;
  showTreeType?: boolean;
  showEnableAlarms?: boolean;
  showSensorId?: boolean;
  showCustomerId?: boolean;
  showCustomer?: boolean;
  showLoRaDetails?: boolean;
} & Pick<FormProps<Sensor>, 'innerRef'>;

const SensorForm: React.FC<SensorFormProps> = ({
  onSubmit,
  values,
  deviceLoRaDetails,
  showName = false,
  showDescription = false,
  showTreeType = false,
  showEnableAlarms = false,
  showSensorId = false,
  showCustomerId = false,
  showCustomer = false,
  showLoRaDetails = false,
  innerRef,
}) => {
  const { t } = useTranslation('components');

  const isLoraSensor = values?.isLoraSensor;

  let schemaObj = {
    name: yup.string().required(),
    customer_id: yup.string().required(),
    tree_type: yup.string().nullable(),
    geographic_location: yup.object({
      id: yup.string().nullable(),
      description:
        isLoraSensor && showDescription
          ? yup
              .string()
              .required(t('forms.SensorDetailsForm.errors.location_description'))
              .typeError(t('forms.SensorDetailsForm.errors.location_description'))
          : yup.string().nullable(),
    }),
    send_alarms: yup.boolean().nullable().default(true),
  };

  if (showName) {
    schemaObj.name = yup.string().required();
  }

  const _onSubmit = async (values: onSubmitValues) => {
    // Only return values that are visible in the form
    const propertiesToOmit: (keyof onSubmitValues)[] = [];
    if (!showName) propertiesToOmit.push('name');
    if (!showDescription) propertiesToOmit.push('geographic_location');
    if (!showTreeType) propertiesToOmit.push('tree_type');
    if (!showEnableAlarms) propertiesToOmit.push('send_alarms');
    if (!showCustomerId) propertiesToOmit.push('customer_id');

    const valuesStripped = omit(values, propertiesToOmit);
    return await onSubmit(valuesStripped);
  };

  return (
    <Form<Sensor>
      schemaObj={schemaObj}
      onSubmit={async values => await _onSubmit(values)}
      values={values}
      innerRef={innerRef}
      body={({ handleSubmit, values: formikValues, errors, setFieldValue, isSubmitting }) => {
        const onCustomerSelect = async (customer: Customer) => {
          setFieldValue('customer_id', customer.id);
        };

        const onTreeTypeSelect = (treeType?: TreeType) => {
          setFieldValue('tree_type', treeType);
        };

        return (
          <form noValidate onSubmit={handleSubmit}>
            <div
              className={classNames('grid grid-cols-1 gap-x-8', {
                'lg:grid-cols-2': showSensorId,
                'lg:grid-cols-1': !showSensorId,
              })}
            >
              {showName && <FormTextInput label={t('forms.SensorForm.name.label')} name="name" />}

              {showSensorId && (
                <FormTextInput label={t('forms.SensorForm.id.label')} value={values?.id} disabled />
              )}
            </div>

            {(showCustomer || showCustomerId) && (
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-8">
                {showCustomer && (
                  <div className="mb-3">
                    <CustomerSelectInput
                      label={t('forms.SensorForm.customer.label')}
                      onSelect={onCustomerSelect}
                      initialSelectedCustomerId={formikValues.customer_id}
                    />
                    <p className="text-brand-orange">{errors.customer_id}</p>
                  </div>
                )}
                {showCustomerId && (
                  <FormTextInput
                    label={t('forms.SensorForm.customer.id.label')}
                    name="customer_id"
                    disabled
                  />
                )}
              </div>
            )}

            {showDescription && (
              <FormTextInput
                label={t('forms.SensorForm.location_description.label')}
                name="geographic_location.description"
              />
            )}

            {showTreeType && <TreeTypeSelect name="tree_type" onSelect={onTreeTypeSelect} />}

            {showEnableAlarms && (
              <div className="mb-4">
                <FormCheckboxInput
                  label={t('forms.SensorForm.send_alarms.label')}
                  name="send_alarms"
                />
              </div>
            )}

            <SubmittingButton
              submittingText={t('forms.SensorForm.button.submittingText')}
              buttonText="Update Sensor"
              submitting={isSubmitting}
            />

            {showLoRaDetails && isLoraSensor && (
              <AdminOnlyDiv useCard={false}>
                <h2 className="mt-10 mb-3">LoRa Details</h2>
                <StaticTextInput label="APP EUI" value={values?.ttn_app_eui} />
                <StaticTextInput label="APP ID" value={values?.ttn_app_id} />
                <StaticTextInput label="DEV EUI" value={values?.ttn_dev_eui} />
                <StaticTextInput label="DEV ID" value={values?.ttn_dev_id} />
                <StaticTextInput label="Software version" value={values?.sw_version} />
                <StaticTextInput label="Hardware version" value={values?.hw_version} />

                <h2 className="mt-10 mb-3">Other details</h2>
                <StaticTextInput
                  label="At Tector office"
                  value={String(values?.at_woodsense_office)}
                />
                <StaticTextInput label="Type" value={values?.type} />
                <StaticTextInput label="Subtype" value={values?.subtype} />

                {deviceLoRaDetails && (
                  <>
                    <h2 className="mt-10 mb-3">LoRa Details (new)</h2>
                    <StaticTextInput label="AppEui" value={deviceLoRaDetails.app_eui} />
                    <StaticTextInput label="DevEui" value={deviceLoRaDetails.dev_eui} />
                    <StaticTextInput label="AppKey" value={deviceLoRaDetails.app_key} />

                    <StaticTextInput
                      label="TTN Server URL"
                      value={deviceLoRaDetails.ttn_server_url}
                    />
                    <StaticTextInput label="TTN App ID" value={deviceLoRaDetails.ttn_app_id} />
                    <StaticTextInput label="TTN Dev ID" value={deviceLoRaDetails.ttn_dev_id} />
                  </>
                )}
              </AdminOnlyDiv>
            )}
          </form>
        );
      }}
    />
  );
};

export default SensorForm;
