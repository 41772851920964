import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from 'components/badges';
import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/';
import { BlueprintSearchBar } from 'components/BlueprintCanvas/components/BlueprintSearchBar';
import { faSquirtyGroup } from 'components/icons';
import GroupType from 'utils/enums/GroupType';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useBlueprint, useCustomerSensorGroups, useUserGroups } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import SensorGroup from 'utils/types/SensorGroup';

interface Props {
  blueprintTitle: string;
  blueprintTags: string;
  blueprintGroupId: string;
  blueprintGroup: string;
}

export const ActionBarHeader: React.FC<Props> = ({
  blueprintTitle,
  blueprintTags,
  blueprintGroupId,
  blueprintGroup,
}) => {
  // State
  const [showGroupsDropdown, setShowGroupsDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const groupsDropdownSearchRef = useRef<HTMLInputElement | null>(null);
  // Hooks
  const { t } = useTranslation('components');
  const location = useLocation();
  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { blueprintId, blueprintCanvasRef } = useContext(BlueprintCanvasContext);
  const { updateBlueprint } = useBlueprint(blueprintId);

  const { groups: groupsUser } = useUserGroups(userId);
  const { sensorGroups: groupsCustomer } = useCustomerSensorGroups(customerId);

  // Logic
  const projectGroups = useMemo(
    () =>
      uniqBy([...(groupsUser || []), ...(groupsCustomer || [])], 'id').filter(
        group =>
          group.type === GroupType.Project &&
          group.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [groupsUser, groupsCustomer, searchTerm],
  );

  const onProjectGroupSelect = async (group: SensorGroup) => {
    await updateBlueprint({ project_group_id: group.id });
    notificationSuccess(t('user.Blueprint.onProjectGroupSelect.successText'));
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-start gap-2 items-center text-xl font-bold">
        {blueprintTitle}
        {blueprintTags
          .split(',')
          .map((tag: string) => tag.trim())
          .filter((tag: string) => tag.length > 0)
          .map((tag: string, key: number) => (
            <Badge
              key={key}
              className="bg-blue-500 text-white inline-block hidden md:block"
              size="xs"
            >
              {tag}
            </Badge>
          ))}
      </div>
      <div className="flex items-center text-sm font-light text-brand-beige-light-1 hover:text-brand-beige-light-2">
        {blueprintGroup && (
          <Link to={`/user/groups/${blueprintGroupId}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="mr-1" icon={faSquirtyGroup} />
            {blueprintGroup}
          </Link>
        )}
        {!blueprintGroup && !location.pathname.includes('sensors') && (
          <div className="relative">
            <div
              className="flex items-center gap-1 text-brand-orange hover:cursor-pointer hover:text-brand-orange-light-1"
              onClick={() => {
                const previousShowGroupsDropdown = showGroupsDropdown;
                if (!previousShowGroupsDropdown) {
                  setShowGroupsDropdown(true);
                  groupsDropdownSearchRef.current?.focus();
                }
              }}
            >
              <FontAwesomeIcon className="mr-1" icon={faSquirtyGroup} />
              <div className="text-xs">
                {t('blueprints.ActionBar.Header.addToGroup.addToProjectGroup')}
              </div>
            </div>
            {blueprintCanvasRef?.current && (
              <div
                className={classNames(
                  'absolute top-5 rounded flex flex-col bg-brand-beige border-brand-gray w-56 transition-[height, opacity] duration-300 overflow-hidden z-20',
                  {
                    'h-40 border': showGroupsDropdown,
                    'h-0 border-none': !showGroupsDropdown,
                  },
                )}
                onBlur={() => {
                  // Timeout is used here to avoid race conditions with clicking to reveal/hide the dropdown
                  setTimeout(() => {
                    if (showGroupsDropdown) setShowGroupsDropdown(false);
                  }, 100);
                }}
              >
                <BlueprintSearchBar
                  onSearch={search => setSearchTerm(search)}
                  placeholderText={t('blueprints.ActionBar.Header.addToGroup.searchPlaceholder')}
                  inputRef={groupsDropdownSearchRef}
                />
                <div className="grow overflox-x-hidden overflow-y-scroll w-full text-brand-gray">
                  {projectGroups.map(group => (
                    <div
                      key={group.id}
                      className="w-full h-fit text-xs overflow-hidden py-1 px-2 border-b-1 hover:bg-brand-beige-light-2 hover:cursor-pointer border-b border-brand-beige-light-1"
                      onClick={() => onProjectGroupSelect(group)}
                    >
                      {group.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
