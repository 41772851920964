export const enum RiskScoreReasonKey {
  point_increase_unhandled = 11,
  point_increase_handled = 12,
  trend_increase = 13,
  trend_increase_summer = 14,
  nearby_point_increase = 21,
  bursting_sensor = 31,
  inactive_sensor_during_rain = 41,
  inactive_sensor = 42,
}
