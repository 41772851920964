import classNames from 'classnames';

import { Badge } from 'components';
import FileBase from 'utils/types/FileBase';

const ImageCard: React.FC<{
  file: FileBase;
  onClick?: (file: FileBase) => void;
}> = ({ file, onClick }) => {
  const tags = (file?.tags || '').split(',').filter(tag => tag.length > 0);

  return (
    <div className="relative">
      <img
        onClick={e => {
          e.preventDefault();
          if (onClick) onClick(file);
        }}
        className={classNames('rounded-sm w-full', {
          'card-hover-effect cursor-pointer': !!onClick,
        })}
        src={file.storage_url_thumbnail}
        alt={file.description}
        data-testid="image-card"
      />
      <div className="absolute bottom-2 left-2 flex flex-wrap gap-0.5">
        {tags.map((tag, i) => (
          <Badge size="sm" className="bg-blue-500 text-white" key={i}>
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );
};
export default ImageCard;
