import classNames from 'classnames';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { Alarm } from 'utils/types';

export const AlertsContainer = ({
  alerts,
  className,
  renderAlertCard,
}: {
  alerts: Alarm[];
  className?: string;
  renderAlertCard: (alert: Alarm) => React.ReactNode;
}) => {
  const refScrollContainer = useRef<HTMLDivElement>() as
    | MutableRefObject<HTMLDivElement>
    | undefined;

  const { t } = useTranslation('components');

  return (
    <div
      ref={refScrollContainer}
      className={classNames(
        className,
        'flex flex-col overflow-y-auto space-y-1 p-1 w-full h-full',
        '[&::-webkit-scrollbar]:w-2',
        '[&::-webkit-scrollbar-track]:bg-brand-gray-light-4 [&::-webkit-scrollbar-track]:rounded-full',
        '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3 [&::-webkit-scrollbar-thumb]:rounded-full',
      )}
    >
      {alerts.length === 0 && (
        <span className="flex text-brand-gray-light-1 text-sm items-center justify-center w-full h-full">
          {t('Alerts.AlertsContainer.noAlertsFound')}
        </span>
      )}

      {alerts.map(alert => (
        <LazyLoad
          key={alert.id}
          scrollContainer={refScrollContainer?.current}
          height={64}
          overflow={true}
          once
        >
          {renderAlertCard(alert)}
        </LazyLoad>
      ))}
    </div>
  );
};
