import { t } from 'translations';
import { DataField, GatewayHeartbeatDataField } from 'utils/types/PlotTypes';

export const axisText = (name: DataField | 'time' | GatewayHeartbeatDataField) => {
  switch (name) {
    case 'time':
      return t('utils:plots.axisTexts.axisTextTime');
    case 'moisture':
      return t('utils:plots.axisTexts.axisTextMoisture');
    case 'humidity':
      return t('utils:plots.axisTexts.axisTextHumidity');
    case 'temperature':
      return t('utils:plots.axisTexts.axisTextTemperature');
    case 'precip':
      return t('utils:plots.axisTexts.axisTextPrecipitation');
    case 'humidity_weather':
      return t('utils:plots.axisTexts.axisTextHumidityWeather');
    case 'temperature_weather':
      return t('utils:plots.axisTexts.axisTextTemperatureWeather');
    case 'co2':
      return t('utils:plots.axisTexts.axisTextCo2');
    case 'voc':
      return t('utils:plots.axisTexts.axisTextVoc');
    case 'risk_score':
      return t('utils:plots.axisTexts.axisTextRiskScore');
    case 'rssi':
      return t('utils:plots.axisTexts.rssi');
    case 'spreading_factor':
      return t('utils:plots.axisTexts.spreading_factor');
    case 'snr':
      return t('utils:plots.axisTexts.snr');
    case 'tx_power':
      return t('utils:plots.axisTexts.tx_power');
    case 'ohms':
      return t('utils:plots.axisTexts.ohms');
    case 'battery':
      return t('utils:plots.axisTexts.battery');
    case 'meta_cpu_usage':
      return t('utils:plots.axisTexts.metaCpuUsage');
    case 'meta_memory_usage':
      return t('utils:plots.axisTexts.metaMemoryUsage');
    case 'meta_sd_card_usage':
      return t('utils:plots.axisTexts.metaSdCardUsage');
    case 'meta_rootfs_usage':
      return t('utils:plots.axisTexts.metaRootfsUsage');
    case 'meta_modem_signal_rssi':
      return t('utils:plots.axisTexts.metaModemSignalRssi');
    default:
      return name;
  }
};
