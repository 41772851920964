import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { InfoTooltip } from 'components/InfoTooltip';
import { BlueprintViewStateContext } from 'utils/contexts';
import { BlueprintViewStateType } from 'utils/types';

export const MeasurementTypeSelector = ({ id }: { id: string }) => {
  const { t } = useTranslation('components');

  const { blueprintViewState, setBlueprintViewState } = useContext(BlueprintViewStateContext);

  return (
    <div className="flex flex-col gap-1">
      <div className="w-full flex justify-center">{t('inputs.SliderSwitch.header')}</div>
      <div
        className="flex gap-2"
        data-tooltip-content={t('inputs.SliderSwitch.helperText')}
        data-tooltip-id="route-tooltip"
      >
        <Button
          id={`${BlueprintViewStateType.Moisture}${id}`}
          onClick={() => setBlueprintViewState(BlueprintViewStateType.Moisture)}
          variant={
            blueprintViewState === BlueprintViewStateType.Moisture
              ? 'canvas-info'
              : 'canvas-outline-info'
          }
          size="sm"
        >
          {t('inputs.SliderSwitch.moisture')}
        </Button>
        <Button
          id={`${BlueprintViewStateType.SignalStrength}${id}`}
          size="sm"
          onClick={() => setBlueprintViewState(BlueprintViewStateType.SignalStrength)}
          variant={
            blueprintViewState === BlueprintViewStateType.SignalStrength
              ? 'canvas-info'
              : 'canvas-outline-info'
          }
        >
          {t('inputs.SliderSwitch.signalStrength')}
        </Button>
        <Button
          id={`${BlueprintViewStateType.RiskScore}${id}`}
          onClick={() => setBlueprintViewState(BlueprintViewStateType.RiskScore)}
          size="sm"
          variant={
            blueprintViewState === BlueprintViewStateType.RiskScore
              ? 'canvas-info'
              : 'canvas-outline-info'
          }
          className="flex items-center gap-1"
        >
          {t('inputs.SliderSwitch.riskScore')}
          <InfoTooltip
            id="info-tooltip-risk-score"
            message={t('inputs.SliderSwitch.RiskScore.InfoTooltip.text')}
            link="https://help.tector.com/risk-score"
            linkText={t('inputs.SliderSwitch.RiskScore.InfoTooltip.linkText')}
          />
        </Button>
      </div>
    </div>
  );
};
