import { faCaretDown, faCaretUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender, HeaderGroup, Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnFilter,
  DataRow,
  NoDataRow,
  PlaceholderRow,
  SkeletonRows,
} from 'components/tables/Table/components/TableContent/components';
import { getColumnPinningStyles, getPinningClassNames } from 'utils/tables/format-functions';

interface TableContentProps<T extends {}> {
  headerGroups: HeaderGroup<T>[];
  rows: Row<T>[];
  coreRows: Row<T>[];
  colspan: number;
  pageSize: number;
  isFiltersVisible: boolean;
  noDataPlaceholder?: string;
  loading?: boolean;
  hideHeader?: boolean;
  isMobileView: boolean;
  skeletonRowsLength: number;
  onClick?: (row: T) => void;
  resetPageIndex: (defaultState?: boolean) => void;
}

export const TableContent = <T extends { id?: string }>({
  headerGroups,
  rows,
  coreRows,
  colspan,
  pageSize,
  isMobileView,
  noDataPlaceholder,
  loading,
  hideHeader,
  isFiltersVisible,
  skeletonRowsLength,
  onClick,
  resetPageIndex,
}: TableContentProps<T>) => {
  const { t } = useTranslation();
  const [cellIdHovering, setCellIdHovering] = useState('');

  const rowHeight = 50;

  return (
    <div
      className={classNames(
        'text-xs lg:text-base overflow-y-auto relative',
        '[&::-webkit-scrollbar]:h-3',
        '[&::-webkit-scrollbar-track]:bg-brand-gray-light-4 ',
        '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3 [&::-webkit-scrollbar-thumb]:rounded-full',
      )}
    >
      <table
        {...{
          style: {
            width: colspan,
          },
        }}
        className="min-w-full"
      >
        {!hideHeader && (
          <thead className="bg-brand-gray-light-4">
            {headerGroups.map(headerGroup => (
              <tr
                key={headerGroup.id}
                className={'text-brand-gray-light-2 d pt-2.5'}
                style={{ height: `${rowHeight}px` }}
              >
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classNames(
                      'bg-brand-gray-light-4',
                      header.column.columnDef.meta?.className,
                      !isMobileView && getPinningClassNames(header.column),
                    )}
                    style={{
                      ...(!isMobileView && getColumnPinningStyles(header.column)),
                      width: header.column.getSize(),
                    }}
                  >
                    <div className="flex items-center p-2">
                      <div
                        className={classNames({
                          'text-brand-orange': header.column.getIsFiltered(),
                        })}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>

                      {/* Sorting columns functionality */}
                      {header.column.getCanSort() && (
                        <button onClick={header.column.getToggleSortingHandler()}>
                          {header.column.getIsSorted() ? (
                            ({
                              asc: (
                                <FontAwesomeIcon
                                  className="inline-flex top-0 right-0 ml-2 text-brand-orange"
                                  icon={faCaretUp}
                                />
                              ),
                              desc: (
                                <FontAwesomeIcon
                                  className="inline-flex top-0 right-0 ml-2 text-brand-orange"
                                  icon={faCaretDown}
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null)
                          ) : (
                            <FontAwesomeIcon
                              className="inline-flex top-0 right-0 ml-2"
                              icon={faSort}
                            />
                          )}
                        </button>
                      )}
                    </div>

                    {/* Filtering columns functionality */}
                    {isFiltersVisible && header.column.getCanFilter() && (
                      <div className="flex justify-start p-2">
                        <ColumnFilter
                          column={header.column}
                          rows={coreRows}
                          transform={header.column.columnDef.meta?.transform}
                          resetPageIndex={resetPageIndex}
                        />
                      </div>
                    )}

                    {/* Resizing columns functionality */}
                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={classNames(
                          'absolute right-0 top-0 h-full w-[5px] bg-black bg-opacity-50 cursor-col-resize select-none opacity-0 hover:opacity-100 transition-opacity',
                          {
                            'bg-rich-coffee opacity-100': header.column.getIsResizing(),
                          },
                        )}
                        onClick={e => e.stopPropagation()}
                      ></div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="bg-white ">
          {loading ? (
            <SkeletonRows colSpan={colspan} rows={skeletonRowsLength} rowHeight={rowHeight} />
          ) : rows.length === 0 ? (
            <NoDataRow
              colSpan={colspan}
              placeholder={noDataPlaceholder || t('components:tables.Table.noData')}
              height={pageSize * rowHeight}
            />
          ) : (
            <>
              {rows.map((row, rowIndex) => (
                <DataRow
                  key={row.id}
                  row={row}
                  rowIndex={rowIndex}
                  onClick={onClick}
                  isMobileView={isMobileView}
                  cellIdHovering={cellIdHovering}
                  setCellIdHovering={setCellIdHovering}
                />
              ))}
              {Array.from({
                length: pageSize - rows.length,
              }).map((_, index) => (
                <PlaceholderRow
                  key={index}
                  colSpan={colspan}
                  cellTemplates={rows[rows.length - 1]?.getVisibleCells() || []}
                  rowIndex={index}
                  isMobileView={isMobileView}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
