import { Field } from 'formik';
import { ChangeEvent, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  ArrowUpDownSwitch,
  DatePickerInput,
  FormCheckboxInput,
  FormTextInput,
  SubmittingButton,
} from 'components';
import AdminOnlyDiv from 'components/AdminOnlyDiv';
import Form from 'components/forms/Form';
import AlarmRuleSubType from 'utils/enums/AlarmRuleSubType';
import AlarmRuleType, { getAlarmRuleTypeSubtypes } from 'utils/enums/AlarmRuleType';
import Locale, { getLocaleFlagCountryCode } from 'utils/enums/Locale';
import { getAlarmRulePeriodHtml } from 'utils/texts/AlarmRule';
import { getAlarmRuleSubtypeText } from 'utils/texts/AlarmRuleSubtype';
import { getAlarmRuleTypeText } from 'utils/texts/AlarmRuleType';
import AlarmRule from 'utils/types/AlarmRule';

export type AlarmRuleFormValues = Pick<
  AlarmRule,
  'id' | 'name' | 'type' | 'subtype' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
> & {
  time_restricted?: boolean;
  add_end_date?: boolean;
  alarm_rule_locale_details: {
    id?: string;
    locale: Locale;
    name?: string;
  }[];
};

const allowedLocales = [Locale.DANISH, Locale.ENGLISH];

const AlarmRuleForm: React.FC<{
  onSubmit?: (alarmRule: AlarmRuleFormValues) => Promise<void>;
  resetFormAfterSubmission?: boolean;
  values?: Partial<AlarmRuleFormValues>;
  showTranslations?: boolean;
  showType?: boolean;
  showSubtype?: boolean;
}> = ({
  onSubmit = () => { },
  resetFormAfterSubmission,
  values,
  showTranslations = false,
  showType = false,
  showSubtype = false,
}) => {
    const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
    const subTypeControlId = useMemo(() => Math.random().toString(36).substring(7), []);

    const [showLocaleDetails, setShowLocaleDetails] = useState(
      !!(values?.alarm_rule_locale_details && values?.alarm_rule_locale_details.length > 0),
    );

    const [openDatePickerCalendar, setOpenDatePickerCalendar] = useState(false);

    const { t } = useTranslation('components');

    const schema = {
      name: yup.string().required(t('forms.AlarmRuleForm.schema.name')),
      type: yup.number().integer().default(AlarmRuleType.Custom).nullable(),
      subtype: yup.number().integer().default(AlarmRuleSubType.Custom).nullable(),
      time_restricted: yup.boolean().default(false),
      add_end_date: yup.boolean().default(false),
      active_period_from: yup.date().nullable(),
      active_period_to: yup.date().nullable(),
      repeat_yearly: yup.boolean().default(false).nullable(),
      alarm_rule_locale_details: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().nullable(),
            locale: yup.string().oneOf(allowedLocales),
            name: yup.string().nullable(),
          }),
        )
        .default(allowedLocales.map(locale => ({ locale }))),
    };

    // Make sure we always have correct number of locale details
    const initialValues = useMemo(() => {
      const initialValues = { ...values };
      if (!initialValues?.alarm_rule_locale_details) {
        initialValues.alarm_rule_locale_details = [];
      } else {
        initialValues.alarm_rule_locale_details = [...initialValues.alarm_rule_locale_details];
      }
      const existingLocales = initialValues.alarm_rule_locale_details.map(x => x.locale);

      allowedLocales.forEach(locale => {
        if (!existingLocales.includes(locale)) {
          initialValues.alarm_rule_locale_details?.push({
            locale,
          });
        }
      });

      return initialValues;
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Form<AlarmRuleFormValues>
        enableReinitialize
        onSubmit={async values => await onSubmit(values)}
        values={initialValues}
        schemaObj={schema}
        resetFormAfterSubmission={resetFormAfterSubmission}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        body={({ handleSubmit, values, setFieldValue, setValues, isSubmitting }) => {
          // If time restriction is toggle off -> Reset all time-restriction values
          if (
            !values.time_restricted &&
            (values.active_period_from ||
              values.active_period_to ||
              values.add_end_date ||
              values.repeat_yearly)
          ) {
            setValues(
              {
                ...values,
                time_restricted: false,
                active_period_from: null,
                active_period_to: null,
                repeat_yearly: false,
                add_end_date: false,
              },
              false,
            );
          }

          // If end date is disabled -> Reset end date
          if (!values.add_end_date && values.active_period_to) {
            setValues(
              {
                ...values,
                active_period_to: null,
              },
              false,
            );
          }

          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormTextInput
                className="mb-1.5"
                name="name"
                label={t('forms.AlarmRuleForm.name.label')}
                placeholder={t('forms.AlarmRuleForm.name.placeholder')}
                data-testid="name"
                required
              />

              {showType && (
                <AdminOnlyDiv useCard={false}>
                  <label className="mb-2" htmlFor={controlId}>
                    {t('forms.AlarmRuleForm.type.label')}
                  </label>
                  <Field
                    as="select"
                    name="type"
                    id={controlId}
                    className="form-control mb-3"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setFieldValue('type', parseInt(e.target.value, 10))
                    }
                  >
                    {[AlarmRuleType.Custom, AlarmRuleType.BuiltIn].map((alarmRuleType, i) => (
                      <option key={i} value={alarmRuleType}>
                        {getAlarmRuleTypeText(alarmRuleType)}
                      </option>
                    ))}
                  </Field>

                  {showSubtype && values.type === AlarmRuleType.BuiltIn && (
                    <>
                      <label className="mb-2" htmlFor={subTypeControlId}>
                        {t('forms.AlarmRuleForm.subtype.label')}
                      </label>
                      <Field
                        as="select"
                        name="subtype"
                        id={subTypeControlId}
                        className="form-control mb-3"
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setFieldValue('subtype', parseInt(e.target.value, 10))
                        }
                      >
                        {getAlarmRuleTypeSubtypes(values.type).map((alarmRuleSubType, i) => (
                          <option key={i} value={alarmRuleSubType}>
                            {getAlarmRuleSubtypeText(alarmRuleSubType)}
                          </option>
                        ))}
                      </Field>
                    </>
                  )}
                </AdminOnlyDiv>
              )}

              {showTranslations && (
                <p className="mb-3">
                  <span
                    className="mr-2 cursor-pointer"
                    onClick={() => setShowLocaleDetails(!showLocaleDetails)}
                  >
                    {t('forms.AlarmRuleForm.localeDetails.label')}
                  </span>
                  <ArrowUpDownSwitch
                    className="text-brand-gray-light-1"
                    isChecked={showLocaleDetails}
                    setIsChecked={setShowLocaleDetails}
                  />
                </p>
              )}

              {showLocaleDetails &&
                values.alarm_rule_locale_details?.map((localeDetail, i) => (
                  <div className="mb-3" key={i}>
                    <Field
                      type="hidden"
                      name={`alarm_rule_locale_details[${i}].id`}
                      value={localeDetail.id}
                    />
                    <Field
                      type="hidden"
                      name={`alarm_rule_locale_details[${i}].locale`}
                      value={localeDetail.locale}
                    />

                    <FormTextInput
                      name={`alarm_rule_locale_details[${i}].name`}
                      prepend={
                        <ReactCountryFlag
                          className="emojiFlag ws-language-selector__flag"
                          countryCode={getLocaleFlagCountryCode(localeDetail.locale)!}
                          svg
                        />
                      }
                    />
                  </div>
                ))}

              <div className="mb-3">
                <FormCheckboxInput
                  label={t('forms.AlarmRuleForm.timeRestricted')}
                  name="time_restricted"
                />
                {values.time_restricted && (
                  <>
                    <FormCheckboxInput
                      label={t('forms.AlarmRuleForm.addEndDate')}
                      name="add_end_date"
                    />
                    {values.add_end_date && (
                      <FormCheckboxInput
                        label={t('forms.AlarmRuleForm.repeatYearly')}
                        name="repeat_yearly"
                      />
                    )}
                  </>
                )}
              </div>

              {values.time_restricted && (
                <div className="flex flex-col mb-3 w-full">
                  <div className="flex w-full">
                    <DatePickerInput
                      key={`${values.active_period_from}-${values.active_period_to}`}
                      className="border-2 border-brand-gray-light-3"
                      onSelect={({ start, end }) => {
                        setFieldValue('active_period_from', start);
                        if (values.add_end_date) {
                          setFieldValue('active_period_to', end);
                        }
                      }}
                      initialStartDate={values.active_period_from!}
                      initialEndDate={values.add_end_date ? values.active_period_to! : undefined}
                      openDatePickerCalendar={openDatePickerCalendar}
                      setOpenDatePickerCalendar={setOpenDatePickerCalendar}
                      singleDate={!values.add_end_date}
                    />
                  </div>
                  <p className="text-center mt-1">
                    {getAlarmRulePeriodHtml(
                      values.active_period_from,
                      values.active_period_to,
                      values.repeat_yearly,
                    )}
                  </p>
                </div>
              )}

              <SubmittingButton
                submitting={isSubmitting}
                buttonText={
                  initialValues.id
                    ? t('forms.AlarmRuleForm.SubmittingButton.buttonText.update')
                    : t('forms.AlarmRuleForm.SubmittingButton.buttonText.create')
                }
              />
            </form>
          );
        }}
      />
    );
  };

export default AlarmRuleForm;
