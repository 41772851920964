import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatISO9075 } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/Checkbox';
import SingleTextareaForm from 'components/forms/SingleTextareaForm';
import { InfoTooltip } from 'components/InfoTooltip';
import Modal from 'components/modals/Modal';
import { useCurrentUser } from 'utils/hooks';
import { useSensorNotes } from 'utils/hooks/data';
import Note from 'utils/types/Note';

export type NoteModalMode = 'create' | 'edit' | 'delete_previous';

export const NotesModal: React.FC<{
  sensorId: string;
  sensorName: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  noteDate: Date;
  mode: NoteModalMode;
  closeOnSubmit?: boolean;
  showInternalNotesCheckbox?: boolean;
  afterSubmit?: (mode: NoteModalMode) => void;
  onClose?: () => void; // We use this to reset the mode to 'create' when the modal is closed
}> = ({
  sensorId,
  sensorName,
  show,
  setShow,
  noteDate,
  mode,
  showInternalNotesCheckbox = false,
  afterSubmit,
  onClose,
}) => {
  const { t } = useTranslation('components');
  const { isAdmin } = useCurrentUser();

  const [lastCreatedNote, setLastCreatedNote] = useState<Note | null>(null);
  const [internalNote, setInternalNote] = useState(false);
  const [buttonText, setButtonText] = useState(t('notesModal.create'));
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { createNote, createInternalNote, updateNote, deleteNote } = useSensorNotes(
    sensorId,
    {
      includeInternalNotes: isAdmin,
    },
    { enableGet: false },
    lastCreatedNote?.id,
  );

  const onDeleteNote = async () => {
    await deleteNote(() => {
      if (afterSubmit) afterSubmit(mode);
    });
  };

  useEffect(() => {
    if (mode === 'delete_previous') {
      // We shouldn't show the modal if we're just deleting the note, so just reset the button text
      setButtonText(t('notesModal.create'));
      onDeleteNote();
    } else if (mode === 'edit') {
      setButtonText(t('notesModal.edit'));
    } else {
      setButtonText(t('notesModal.create'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    // Reset the mode to 'create' when the modal is closed
    if (!show) {
      const newButtonText = t('notesModal.create');
      if (buttonText !== newButtonText) {
        setButtonText(newButtonText);
      }
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = async ({ text }: { text: string }) => {
    if (mode === 'create') {
      let note: Partial<Note> = { text };
      if (noteDate) note.timestamp = noteDate;

      if (internalNote) {
        setLastCreatedNote(await createInternalNote(note));
      } else {
        setLastCreatedNote(await createNote(note));
      }
    } else if (mode === 'edit' && lastCreatedNote) {
      let noteToSubmit = lastCreatedNote;
      noteToSubmit.text = text;
      setLastCreatedNote(await updateNote(noteToSubmit));
    }
    setShow(false);
    if (afterSubmit) afterSubmit(mode);
  };

  const handleTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value === '') {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, []);

  return (
    <Modal
      title={
        <div className="flex items-center gap-1">
          <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
          {t('notesModal.title', { sensorName, noteDate: formatISO9075(noteDate) })}
          <InfoTooltip
            id={'info-tooltip-notes-and-events'}
            message={t('notes.InfoTooltip.text')}
            link={'https://help.tector.com/adding-and-managing-notes-on-sensor-plots'}
            linkText={t('notes.InfoTooltip.linkText')}
          />
        </div>
      }
      titleClassName="flex items-center text-lg grow"
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
      hideScrollBar={false}
      hasUnsavedChanges={useCallback(() => hasUnsavedChanges, [hasUnsavedChanges])}
    >
      <SingleTextareaForm
        initialValue={mode === 'edit' && lastCreatedNote?.text ? lastCreatedNote.text : ''}
        buttonText={buttonText}
        onSubmit={onSubmit}
        placeholder={t('notes.placeholder')}
        lengthError={t('notes.noteError')}
        requiredError={t('notes.noteRequired')}
        elementBeforeSubmitButton={
          <>
            {showInternalNotesCheckbox && (
              <div className="mb-3">
                <Checkbox
                  checked={internalNote}
                  onChange={({ target: { checked } }) => setInternalNote(checked)}
                  id="internal_note"
                  label={t('notes.Notes.internalNote')}
                />
              </div>
            )}
          </>
        }
        maxLength={1024}
        autoFocus={true}
        onTextAreaChange={handleTextAreaChange}
      />
    </Modal>
  );
};
