import {
  faCircleNodes,
  faCog,
  faCubes,
  faDiagramProject,
  faDraftingCompass,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faGlobe,
  faImages,
  faLocationArrow,
  faPlayCircle,
  faSatelliteDish,
  faSitemap,
  faSkullCrossbones,
  faTable,
  faTruck,
  faUser,
  faUsers,
  faUserShield,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';

import faCircleQuestionLine from 'components/icons/faCircleQuestionLine';
import faHubSpotLogo from 'components/icons/faHubSpotLogo';
import faRHTemperatureSensor from 'components/icons/faRHTemperatureSensor';
import faSquirtyAlertOff from 'components/icons/faSquirtyAlertOff';
import faSquirtyAlertRules from 'components/icons/faSquirtyAlertRules';
import faSquirtyBlueprint from 'components/icons/faSquirtyBlueprint1';
import faSquirtyBricks1 from 'components/icons/faSquirtyBricks1';
import faSquirtyBricks2 from 'components/icons/faSquirtyBricks2';
import faSquirtyBuildings from 'components/icons/faSquirtyBuildings';
import faSquirtyCasing from 'components/icons/faSquirtyCasing';
import faSquirtyChartDown from 'components/icons/faSquirtyChartDown';
import faSquirtyChartForecast from 'components/icons/faSquirtyChartForecast';
import faSquirtyChartUp from 'components/icons/faSquirtyChartUp';
import faSquirtyCompass from 'components/icons/faSquirtyCompass';
import faSquirtyConstructionCrane from 'components/icons/faSquirtyConstructionCrane';
import faSquirtyCrane from 'components/icons/faSquirtyCrane';
import faSquirtyDryRoom from 'components/icons/faSquirtyDryRoom';
import faSquirtyFlatRoof from 'components/icons/faSquirtyFlatRoof';
import faSquirtyHouse1 from 'components/icons/faSquirtyHouse1';
import faSquirtyInsulation from 'components/icons/faSquirtyInsulation';
import faSquirtyKitchen from 'components/icons/faSquirtyKitchen';
import faSquirtyRoomType from 'components/icons/faSquirtyRoomType';
import faSquirtyScrew from 'components/icons/faSquirtyScrew';
import faSquirtySensor from 'components/icons/faSquirtySensor';
import faSquirtySwitch from 'components/icons/faSquirtySwitch';
import faSquirtyTimberPlanks from 'components/icons/faSquirtyTimberPlanks';
import faSquirtyWall from 'components/icons/faSquirtyWall';
import { getSensorTagIcon } from 'components/icons/SensorTags';

// Tector icons
const faSquirtyAdmin = faUserShield;
const faSquirtyAlert = faExclamationTriangle;
const faSquirtyAnomaly = faSkullCrossbones;
const faSquirtyCircleNodes = faCircleNodes;
const faSquirtyCustomer = faUserTie;
const faSquirtyDemoApplication = faPlayCircle;
const faSquirtyDocumentChange = faFile;
const faSquirtyGateway = faSatelliteDish;
const faSquirtyGroup = faCubes;
const faSquirtyGroupTypeDelivery = faTruck;
const faSquirtyGroupTypeProject = faDiagramProject;
const faSquirtyGroupTypeUnknown = faSquirtyGroup;
const faSquirtyImages = faImages;
const faSquirtyOrganization = faSitemap;
const faSquirtyOverview = faGlobe;
const faSquirtyPosition = faDraftingCompass;
const faSquirtyReport = faFileAlt;
const faSquirtySettings = faCog;
const faSquirtyTags = faLocationArrow;
const faSquirtyTransmissions = faTable;
const faSquirtyUser = faUser;
const faSquirtyUsers = faUsers;

export {
  faCircleQuestionLine,
  faHubSpotLogo,
  faRHTemperatureSensor,
  faSquirtyAdmin,
  faSquirtyAlert,
  faSquirtyAlertOff,
  faSquirtyAlertRules,
  faSquirtyAnomaly,
  faSquirtyBlueprint,
  faSquirtyBricks1,
  faSquirtyBricks2,
  faSquirtyBuildings,
  faSquirtyCasing,
  faSquirtyChartDown,
  faSquirtyChartForecast,
  faSquirtyChartUp,
  faSquirtyCircleNodes,
  faSquirtyCompass,
  faSquirtyConstructionCrane,
  faSquirtyCrane,
  faSquirtyCustomer,
  faSquirtyDemoApplication,
  faSquirtyDocumentChange,
  faSquirtyDryRoom,
  faSquirtyFlatRoof,
  faSquirtyGateway,
  faSquirtyGroup,
  faSquirtyGroupTypeDelivery,
  faSquirtyGroupTypeProject,
  faSquirtyGroupTypeUnknown,
  faSquirtyHouse1,
  faSquirtyImages,
  faSquirtyInsulation,
  faSquirtyKitchen,
  faSquirtyOrganization,
  faSquirtyOverview,
  faSquirtyPosition,
  faSquirtyReport,
  faSquirtyRoomType,
  faSquirtyScrew,
  faSquirtySensor,
  faSquirtySettings,
  faSquirtySwitch,
  faSquirtyTags,
  faSquirtyTimberPlanks,
  faSquirtyTransmissions,
  faSquirtyUser,
  faSquirtyUsers,
  faSquirtyWall,
  getSensorTagIcon,
};
