import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';

export enum SensorMonitoringType {
  Timber = 'TIMBER',
  FlatRoof = 'FLATROOF',
  ClosedIn = 'CLOSEDIN',
}

export type SensorMonitoringTypes = {
  [sensorId: string]: SensorMonitoringType;
};

export const useSensorsMonitoringTypes = (
  sensorIds: string[],
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['useSensorsMonitoringTypes', sensorIds];

  const {
    data: monitoringTypesRaw,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk/sensor_monitoring_type', {
        sensor_ids: sensorIds,
      }),
    select: (monitoringTypes: any) => monitoringTypes as SensorMonitoringTypes,
    enabled: enableGet && sensorIds && sensorIds.length > 0,
  });

  // Parse monitoring types
  const monitoringTypes = useMemo(() => {
    if (!monitoringTypesRaw || !sensorIds) return {};

    return Object.entries(monitoringTypesRaw).reduce((acc, [sensorId, sensorMonitoringType]) => {
      acc[sensorId] = sensorMonitoringType as SensorMonitoringType;
      return acc;
    }, {} as SensorMonitoringTypes);
  }, [monitoringTypesRaw, sensorIds]);

  return {
    monitoringTypes,
    isPending: isPending || isLoading || isFetching,
  };
};
