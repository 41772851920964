import {
  CanvasStage,
  MAX_SCALE,
  MIN_SCALE,
  PERFECT_DRAW_ENABLED,
} from 'components/CanvasStage/CanvasStage';
import { ImageSize, LabelProps, StageState } from 'components/CanvasStage/types';

export { MAX_SCALE, MIN_SCALE, PERFECT_DRAW_ENABLED };
export type { ImageSize, LabelProps, StageState };
export default CanvasStage;
export { CanvasStageContext } from 'components/CanvasStage/CanvasStageContext';
export { DEFAULT_STAGE_STATE } from 'components/CanvasStage/const';
