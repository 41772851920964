import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { TransmissionsTable, TransmissionsTableProps } from 'components/tables/TransmissionsTable';
import { useSensorsBulk, useSensorsBulkTransmissions } from 'utils/hooks/data';
import TimePeriod from 'utils/types/TimePeriod';
import Transmission from 'utils/types/Transmission';

export const TransmissionsTableSelfContained: React.FC<
  Omit<TransmissionsTableProps, 'data' | 'sensors'> & {
    sensorIds: string[];
    timePeriod: TimePeriod;
  }
> = ({ sensorIds, timePeriod: [timeFrom, timeTo], loading, visibleColumns, ...props }) => {
  const [transmissionsLimit, setTransmissionsLimit] = useState(100_000);

  const { t } = useTranslation('components');

  const visibleColumnsUpdated: (keyof Transmission)[] = visibleColumns
    ? visibleColumns.includes('id')
      ? visibleColumns
      : [...visibleColumns, 'id']
    : ['id'];

  const { sensors, isPending: isPendingSensor } = useSensorsBulk(sensorIds);
  const { transmissions, isPending: isPendingTransmissions } = useSensorsBulkTransmissions(
    sensorIds,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
      fields: visibleColumnsUpdated,
      limit: transmissionsLimit,
    },
  );

  return (
    <>
      <TransmissionsTable
        data={transmissions}
        sensors={sensors}
        loading={loading || isPendingSensor || isPendingTransmissions}
        visibleColumns={visibleColumnsUpdated}
        {...props}
      />
      {transmissions?.length === transmissionsLimit && (
        <>
          <p className="inline mr-1.5 ">
            {t('tables.TransmissionsTableSelfContained.showingOnlyText')}{' '}
            <strong>
              {transmissionsLimit}
              {'.'}
            </strong>
            {t('tables.TransmissionsTableSelfContained.showingOnlyText2')}
          </p>

          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => setTransmissionsLimit(prevValue => 2 * prevValue)}
          >
            {t('tables.TransmissionsTableSelfContained.fetchMoreButtonText')}
          </Button>
        </>
      )}
    </>
  );
};
