import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import AlarmRule, { parseAlarmRule } from 'utils/types/AlarmRule';
import HookOptions from 'utils/types/HookOptions';

export const useUserAlertRules = (userId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['useUserAlertRules', userId];

  const queryClient = useQueryClient();

  const {
    data: alertRules,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/users/${userId}/alert_rules`),
    select: (alertRules: any[]) => alertRules.map(parseAlarmRule),
    enabled: !!userId && enableGet,
  });

  const { mutateAsync: createAlertRuleForUser } = useMutation({
    mutationFn: async (
      values: Pick<
        AlarmRule,
        'name' | 'type' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
      >,
    ) => {
      const alertRules: any[] = await api.post(`/users/${userId}/alert_rules`, values);
      return alertRules.map(parseAlarmRule);
    },
    onSuccess: alertRules => {
      queryClient.setQueryData(queryKey, alertRules);
    },
  });

  return {
    alertRules,
    createAlertRuleForUser,
    isPending: isPending || isLoading || isFetching,
  };
};
