import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryUpdate } from 'utils/queries/update';
import HookOptions from 'utils/types/HookOptions';
import Sensor, { parseSensor } from 'utils/types/Sensor';

export const useSensor = (sensorId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['sensors', sensorId];

  const invalidateQueryKeys = [
    ['sensors'],
    ['sensorGroupSensors'],
    ['userSensors'],
    ['customerSensors'],
    ['gatewaySensors'],
    ['blueprintSensors'],
    ['reportRevisionSensors'],
    ['sensorAccessLinks', sensorId],
    ['sensorTransmissions', sensorId],
  ];

  // Sensor - Get
  const {
    data: sensor,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}`),
    select: (sensor: any) => parseSensor(sensor),
    enabled: enableGet && !!sensorId,
  });

  // Sensor - Update
  const { mutateAsync: updateSensor } = useQueryUpdate<Sensor>({
    api,
    queryKey,
    endpoint: `/sensors/${sensorId}`,
    parseFn: parseSensor,
    onSuccess: (data, variables) => {
      // TODO: Invalidate other queries dependent on what has been updated
    },
    invalidateQueryKeys,
  });

  return {
    sensor,
    isPending: isPending || isLoading || isFetching,
    updateSensor,
  };
};
