import classnames from 'classnames';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TreeType from 'utils/enums/TreeType';
import { getTreeTypeText } from 'utils/sensor/texts';

const TreeTypeSelect: React.FC<{
  onSelect: (treeType?: TreeType) => void;
  name: string;
}> = ({ onSelect, name }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);

  const hasError = !!meta.error;

  const { t } = useTranslation('components');

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectElement = e.target;
    const optionIdx = selectElement.selectedIndex;
    if (optionIdx === 0) return onSelect(undefined);
    const optionElement = selectElement.options[optionIdx];
    const treeType = optionElement.value as TreeType;
    onSelect(treeType);
  };

  return (
    <div className="mb-3">
      <label htmlFor="tree-type-select-control">{t('inputs.TreeTypeSelect.title')}</label>
      <select
        name={name}
        className={classnames('relative', {
          'border-brand-orange ring-0': hasError,
        })}
        onChange={onChange}
        data-testid="tree-type-select-dropdown"
        value={meta.value || undefined}
        id="tree-type-select-control"
      >
        <option value={undefined}>{t('inputs.TreeTypeSelect.placeholder')}</option>
        {Object.values(TreeType)
          .filter(treeType => treeType !== TreeType.OSB_BOARD) // Leave out the OSB option for now
          .map((treeType, i) => (
            <option key={i} value={treeType}>
              {getTreeTypeText(treeType)}
            </option>
          ))}
      </select>

      {hasError && <p className="text-brand-orange">{meta.error}</p>}
    </div>
  );
};

export default TreeTypeSelect;
