import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AddButton, Button } from 'components';
import {
  AdminAlertRulesSelect,
  AlertRuleItem,
  AlertRuleModal,
} from 'components/AlertRuleSettings/components';
import { AlarmRuleFormValues } from 'components/forms/AlarmRuleForm';
import InfoText from 'components/InfoText';
import { InfoTooltip } from 'components/InfoTooltip';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useUserAndCustomerAlertRules } from 'utils/hooks/data';
import { sortAlarmRules } from 'utils/sorting';

export const AlertRuleSettings: React.FC<{
  showType?: boolean;
  showTranslations?: boolean;
  alarmRuleType?: AlarmRuleType;
  source: string;
}> = ({ showType, showTranslations, alarmRuleType = AlarmRuleType.Custom, source }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('components');
  const currentLocale = parseI18LanguageToLocale(i18n.language);

  const { userId, isAdmin } = useCurrentUser();
  const { customer } = useSelectedCustomer();

  const { alertRules, createAlertRuleForCustomer, createAlertRuleForUser } =
    useUserAndCustomerAlertRules(userId, customer?.id);

  const currentAlarmRuleIds = useMemo(
    () => alertRules?.map(alertRule => alertRule.id),
    [alertRules],
  );

  const queryParams = queryString.parse(location.search);
  const fromSensorId = queryParams.fromSensorId;
  const fromLink = queryParams.fromLink;

  const alertRulesSorted = useMemo(
    () => sortAlarmRules(alertRules, currentLocale),
    [alertRules, currentLocale],
  );

  useEffect(() => {
    if (alertRulesSorted.length > 0) {
      if (source && !fromLink && !fromSensorId) {
        navigate(`${source}/${alertRulesSorted[0].id}`, { replace: true });
      }
    } else {
      if (source) {
        navigate(source, { replace: true });
      }
    }
  }, [alertRulesSorted, source]); //eslint-disable-line

  const onSubmit = async ({
    name,
    type,
    active_period_from,
    active_period_to,
    repeat_yearly,
  }: AlarmRuleFormValues) => {
    // If a customer is selected we create the alert rule on behalf of the customer
    // If no customer is selected we create the alert rule on behalf of the user
    const alarmRules = customer?.id
      ? await createAlertRuleForCustomer({
          name,
          type,
          active_period_from,
          active_period_to,
          repeat_yearly,
        })
      : await createAlertRuleForUser({
          name,
          type,
          active_period_from,
          active_period_to,
          repeat_yearly,
        });
    setShowCreateModal(false);
    const newAlarmRule = sortBy(alarmRules, ['created_date']).slice(-1)[0];
    if (source) {
      navigate(`${source}/${newAlarmRule.id}`, { replace: true });
    }
  };

  return (
    <div className="p-3">
      <h2 className="flex flex-row items-center gap-1">
        {t('alarmRuleSettings.title')}
        <InfoTooltip
          id="info-tooltip-alerts-settings"
          message={t('alarmRuleSettings.InfoTooltip.text')}
          link="https://help.tector.com/how-to-setup-alerts"
          linkText={t('alarmRuleSettings.InfoTooltip.linkText')}
        />
      </h2>

      {fromSensorId && (
        <div className="my-4">
          <Link
            to={`/user/sensors/${fromSensorId}/alarms#sensor-alarm-rules`}
            preventScrollReset={true}
            onClick={e => e.preventDefault()}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
            {t('alarmRuleSettings.goToSensor')}
          </Link>
        </div>
      )}

      <InfoText
        className="mb-4"
        text={t('alarmRuleSettings.infoText')}
        data-tooltip-content={t('alarmRuleSettings.infoIcon')}
        data-tooltip-id="route-tooltip"
      />

      <Button className="mb-10" onClick={() => setShowCreateModal(true)}>
        {t('alarmRuleSettings.createAlarmRule')}
      </Button>

      <p className="font-semibold text-brand-gray mb-1">{t('alarmRuleSettings.rulesList')}</p>

      <ul className="mb-4">
        {alertRulesSorted.map(alarmRule => (
          <AlertRuleItem
            key={alarmRule?.id}
            alarmRule={alarmRule}
            isAdmin={isAdmin}
            source={source}
          />
        ))}
      </ul>

      <AlertRuleModal
        showType={showType}
        showTranslations={showTranslations}
        alarmRuleType={alarmRuleType}
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        onSubmit={onSubmit}
      />

      <AddButton
        tooltipText={t('alarmRuleSettings.createAlarmRule')}
        onClick={() => setShowCreateModal(true)}
      />

      {isAdmin && <AdminAlertRulesSelect currentAlarmRuleIds={currentAlarmRuleIds} />}
    </div>
  );
};
