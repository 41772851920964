import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ResolveAlertForm, ResolveAlertFormProps } from 'components';
import Modal from 'components/modals/Modal';

export const ResolveAlertModal: React.FC<
  {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
  } & ResolveAlertFormProps
> = ({ show, setShow, ...props }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('forms.ResolveAlertModal.title')}
      show={show}
      setShow={setShow}
      onClick={e => e.stopPropagation()}
    >
      <ResolveAlertForm {...props} />
    </Modal>
  );
};
