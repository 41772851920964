import { t } from 'translations';
import { RiskScoreReasonKey, RiskScoreState } from 'utils/enums';

export const getRiskScoreReasonKeyText = (riskScoreReasonKey: RiskScoreReasonKey) => {
  switch (riskScoreReasonKey) {
    case RiskScoreReasonKey.bursting_sensor:
      return t('utils:texts.getRiskScoreReasonKeyText.bursting_sensor');
    case RiskScoreReasonKey.inactive_sensor:
      return t('utils:texts.getRiskScoreReasonKeyText.inactive_sensor');
    case RiskScoreReasonKey.inactive_sensor_during_rain:
      return t('utils:texts.getRiskScoreReasonKeyText.inactive_sensor_during_rain');
    case RiskScoreReasonKey.nearby_point_increase:
      return t('utils:texts.getRiskScoreReasonKeyText.nearby_point_increase');
    case RiskScoreReasonKey.point_increase_handled:
      return t('utils:texts.getRiskScoreReasonKeyText.point_increase_handled');
    case RiskScoreReasonKey.point_increase_unhandled:
      return t('utils:texts.getRiskScoreReasonKeyText.point_increase_unhandled');
    case RiskScoreReasonKey.trend_increase:
      return t('utils:texts.getRiskScoreReasonKeyText.trend_increase');
    case RiskScoreReasonKey.trend_increase_summer:
      return t('utils:texts.getRiskScoreReasonKeyText.trend_increase_summer');
    default:
      return 'n/a';
  }
};

export const getRiskScoreGaugeKeyText = (riskState?: RiskScoreState) => {
  if (riskState !== undefined) {
    if (riskState === RiskScoreState.RISK_SCORE_VERY_LOW) {
      return t('utils:texts.getRiskScoreGaugeKeyText.noRisk');
    } else if (
      riskState === RiskScoreState.RISK_SCORE_LOW ||
      riskState === RiskScoreState.RISK_SCORE_MODERATE
    ) {
      return t('utils:texts.getRiskScoreGaugeKeyText.lowRisk');
    } else if (
      riskState === RiskScoreState.RISK_SCORE_HIGH ||
      riskState === RiskScoreState.RISK_SCORE_VERY_HIGH
    ) {
      return t('utils:texts.getRiskScoreGaugeKeyText.potentialRisk');
    }
  }
  return t('utils:texts.getRiskScoreGaugeKeyText.notAvailable');
};
