import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyTimberPlanks: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    16, // width
    16, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M9.08879 0.0777779C8.90657 0.183333 2.23998 8.54444 2.17775 8.73889C2.15553 8.82778 2.13331 9.03889 2.13331 9.21667V9.53889L1.34665 10.45C0.911101 10.95 0.457773 11.4722 0.337774 11.6111C0 12 0 12 0 13.8167C0 15.6167 0.0133332 15.7167 0.311108 15.9C0.457773 15.9944 0.755548 16 4.67551 16C7.49325 16 8.92435 15.9833 9.00879 15.9444C9.17768 15.8667 15.8576 7.52778 15.9376 7.29444C15.9865 7.15556 15.9998 6.76667 15.9998 5.51667C15.9998 3.26111 16.0443 3.33333 14.7021 3.33333H13.8665V1.95555C13.8665 0.405555 13.8443 0.277777 13.5554 0.0999994C13.4132 0.0111105 13.2132 0 11.311 0C9.64434 0.00555556 9.19546 0.0222223 9.08879 0.0777779ZM9.3999 4.66667L6.73326 8H5.44439H4.15551L6.82215 4.66667L9.48879 1.33333H10.7777H12.0665L9.3999 4.66667ZM10.1466 6.78889L7.48881 10.1111L7.47548 9.51667L7.46214 8.92222L10.1199 5.6L12.7776 2.27778L12.791 2.87222L12.8043 3.46667L10.1466 6.78889ZM11.5332 8L8.86657 11.3333H8.42213H7.97769L10.6443 8L13.311 4.66667H13.7554H14.1998L11.5332 8ZM12.2799 10.1222L9.62212 13.4444L9.60879 12.85L9.59545 12.2556L12.2532 8.93333L14.911 5.61111L14.9243 6.20555L14.9376 6.8L12.2799 10.1222ZM6.39993 10.3333V11.3333H4.79995H3.19997V10.3333V9.33333H4.79995H6.39993V10.3333ZM4.26662 13.6667V14.6667H2.66664H1.06666V13.6667V12.6667H2.66664H4.26662V13.6667ZM8.53324 13.6667V14.6667H6.93326H5.33328V13.6667V12.6667H6.93326H8.53324V13.6667Z',
  ],
};

export default faSquirtyTimberPlanks;
