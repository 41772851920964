import { Formik, FormikHelpers } from 'formik';
import { useCallback, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { TCanvasConfettiInstance } from 'react-canvas-confetti/dist/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FilesCard, FormTextareaInput, SubmittingButton } from 'components';
import { ErrorMessageDisplay } from 'components/forms/helpers';
import { useAlert, useSensorFiles } from 'utils/hooks/data';
import FileBase from 'utils/types/FileBase';

export type ResolveAlertFormValues = {
  resolveReason: string;
  fileIds: string[];
};

export type ResolveAlertFormProps = {
  alertId: string;
  onSubmit: (
    resolveAlertFormValues: ResolveAlertFormValues,
    { resetForm }: { resetForm: () => void },
  ) => Promise<void>;
  existingResolveAlertValues?: ResolveAlertFormValues;
  hideSubmitButton?: boolean;
};

export const ResolveAlertForm: React.FC<ResolveAlertFormProps> = ({
  alertId,
  onSubmit = () => {},
  existingResolveAlertValues,
  hideSubmitButton = false,
}) => {
  const { t } = useTranslation('components');

  const resolveButtonContainerRef = useRef<HTMLDivElement>(null);
  const confettiRef = useRef<TCanvasConfettiInstance>();

  const [, setIsSubmitting] = useState(false);
  const [createdFiles, setCreatedFiles] = useState<FileBase[]>([]);

  const { alert } = useAlert(alertId);
  const { createFile } = useSensorFiles(alert?.sensor_id);

  const onFileSubmit = async (file: FileBase) => {
    const createdFile = await createFile(file);
    setCreatedFiles([...createdFiles, createdFile]);
  };

  const confettiInitHandler = ({ confetti }: { confetti: TCanvasConfettiInstance }) => {
    confettiRef.current = confetti;
  };

  const triggerConfetti = () => {
    if (confettiRef.current && resolveButtonContainerRef.current) {
      const rect = resolveButtonContainerRef.current.getBoundingClientRect();
      const originX = (rect.left + rect.width / 2) / window.innerWidth;
      const originY = (rect.top + rect.height / 2) / window.innerHeight;

      confettiRef.current({
        spread: 360,
        ticks: 60,
        startVelocity: 30,
        angle: 0,
        particleCount: 200,
        gravity: 0.5,
        origin: {
          x: originX,
          y: originY,
        },
      });
    }
  };

  const handleSubmit = useCallback(
    async (
      values: Omit<ResolveAlertFormValues, 'fileIds'>,
      { resetForm }: FormikHelpers<ResolveAlertFormValues>,
    ) => {
      setIsSubmitting(true);
      triggerConfetti();
      await onSubmit({ ...values, fileIds: createdFiles.map(file => file.id) }, { resetForm });
      setIsSubmitting(false);
    },
    [onSubmit, createdFiles],
  );

  const schema = yup.object({
    resolveReason: yup.string().nullable(),
  });

  const initialValues = schema.cast(existingResolveAlertValues, {
    assert: false,
    stripUnknown: true,
  }) as ResolveAlertFormValues;

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form id="resolveAlertForm" noValidate onSubmit={handleSubmit}>
          <FormTextareaInput
            label={t('forms.ResolveAlertForm.resolveReason.label')}
            name="resolveReason"
            placeholder={t('forms.ResolveAlertForm.resolveReason.placeholder')}
            required
          />

          {alert?.sensor_id && (
            <>
              <label className="mb-1.5">{t('forms.ResolveAlertForm.files.label')}</label>
              <FilesCard
                createFile={onFileSubmit}
                showMarkAsPrimary
                files={createdFiles}
                processFileBeforeUpload={file => {
                  if (alert?.sensor_id) {
                    file.sensor_id = alert?.sensor_id;
                  } else {
                    throw Error('Photo documentation only available for sensor alerts');
                  }
                  return file;
                }}
                dropZoneSize="sm"
              />
            </>
          )}

          {!hideSubmitButton && (
            <div ref={resolveButtonContainerRef} className="relative inline-block">
              <SubmittingButton
                buttonText={t('forms.ResolveAlertForm.button.resolve')}
                form="resolveAlertForm"
                submitting={isSubmitting}
                disabled={!values.resolveReason || isSubmitting}
              />
              <ReactCanvasConfetti
                className="fixed top-0 left-0 w-full h-full pointer-events-none"
                onInit={confettiInitHandler}
              />
            </div>
          )}

          <ErrorMessageDisplay />
        </form>
      )}
    </Formik>
  );
};
