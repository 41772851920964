import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  FileDropZone,
  FormTextareaInput,
  GlobalAddressInput,
  SubmittingButton,
  TagsInput,
} from 'components';
import { ErrorMessageDisplay } from 'components/forms/helpers';
import { Blueprint } from 'utils/types';
import EnrichedFile from 'utils/types/EnrichedFile';

export type BlueprintFormValues = Pick<Blueprint, 'description' | 'tags' | 'address'> & {
  file: EnrichedFile;
};

const BlueprintForm: React.FC<{
  onSubmit?: (
    blueprint: BlueprintFormValues,
    { resetForm }: FormikHelpers<BlueprintFormValues>,
  ) => Promise<void>;
  buttonText?: string;
  existingBlueprintValues?: BlueprintFormValues;
  hideSubmitButton?: boolean;
}> = ({ onSubmit = () => {}, buttonText, existingBlueprintValues, hideSubmitButton = false }) => {
  const { t } = useTranslation('components');

  const schema = yup.object({
    description: yup
      .string()
      .min(1, t('forms.BlueprintForm.requirements.lengthError'))
      .required(t('forms.BlueprintForm.requirements.requiredError')),
    file: yup.object({
      filename: yup.string().required(t('forms.BlueprintForm.requirements.fileRequirement')),
    }),
    tags: yup.string().nullable(),
    address: yup.string().nullable(),
  });

  const initialValues = schema.cast(existingBlueprintValues, {
    assert: false,
    stripUnknown: true,
  }) as BlueprintFormValues;

  const initialTags = (existingBlueprintValues?.tags || '')
    .split(',')
    .map((tag: string) => tag.trim())
    .filter((tag: string) => tag.length > 0) as string[];

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleSubmit, setFieldValue, errors, isSubmitting }) => {
        const onTagUpdate = (tags: string[]) => {
          const tagString = tags.join(',');
          setFieldValue('tags', tagString);
        };

        const onAddressSelected = ({ lon, lat }: { lon: number; lat: number }) => {
          setFieldValue('longitude', lon);
          setFieldValue('latitude', lat);
        };

        return (
          <form id="blueprintForm" noValidate onSubmit={handleSubmit}>
            {!existingBlueprintValues?.file && (
              <div className="mb-3">
                <FileDropZone
                  label={t('forms.BlueprintForm.imageLabel')}
                  showThumbs={true}
                  onSubmit={async (files: EnrichedFile[]) => {
                    setFieldValue('file', files[0]);
                  }}
                  boxPlaceholder={t('forms.BlueprintForm.fileDropZone.placeholder')}
                  singleUpload={true}
                />

                {errors.file && (
                  <small className="text-brand-orange mt-1">{errors.file.filename}</small>
                )}
                {values.file?.filename && (
                  <p className="text-brand-green-light-1 mt-1">{values.file.filename}</p>
                )}
              </div>
            )}

            <FormTextareaInput
              label={t('forms.BlueprintForm.description')}
              name="description"
              placeholder={t('forms.BlueprintForm.requirements.placeholder')}
              required
            />

            {!existingBlueprintValues && (
              <div className="mb-3">
                <label htmlFor="adress-input">{t('forms.SensorDetailsForm.address.label')}</label>
                <GlobalAddressInput
                  placeholder={t('forms.SensorDetailsForm.adress.placeholder')}
                  onAddressSelected={onAddressSelected}
                  initialValue={values.address}
                />
              </div>
            )}

            <div className="mb-3">
              <TagsInput
                label={
                  <>
                    {t('forms.FileForm.keywords.text.1')} ({t('forms.FileForm.keywords.text.2')}{' '}
                    <span className="font-semibold">Enter</span>{' '}
                    {t('forms.FileForm.keywords.text.3')})
                  </>
                }
                onUpdate={onTagUpdate}
                initialTags={initialTags}
              />
            </div>

            <ErrorMessageDisplay />

            {!hideSubmitButton && (
              <SubmittingButton buttonText={buttonText || ''} submitting={isSubmitting} />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default BlueprintForm;
