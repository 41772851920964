import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button, FormCheckboxInput, FormTextInput, SubmittingButton, TagsInput } from 'components';
import Form from 'components/forms/Form';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import FileBase from 'utils/types/FileBase';

const schemaObj = {
  description: yup.string().nullable(),
  tags: yup.string().nullable(),
  marked_as_primary: yup.boolean().nullable(),
};

const FileForm: React.FC<{
  onSubmit: (values: FileBase) => Promise<void>;
  onDelete?: () => void;
  values?: FileBase;
  buttonText: string;
  submittingText: string;
  showMarkAsPrimary?: boolean;
  hideButtons?: boolean;
  readOnly?: boolean;
}> = ({
  onSubmit,
  onDelete,
  values,
  buttonText,
  submittingText,
  showMarkAsPrimary = false,
  hideButtons = false,
  readOnly = false,
}) => {
  const { t } = useTranslation('components');

  const initialTags = (values?.tags || '')
    .split(',')
    .map((tag: string) => tag.trim())
    .filter(tag => tag.length > 0);

  return (
    <Form<FileBase>
      schemaObj={schemaObj}
      onSubmit={async _values => await onSubmit(_values)}
      values={values}
      body={props => {
        const onTagUpdate = (tags: string[]) => {
          const tagString = tags.join(',');
          props.setFieldValue('tags', tagString);
        };

        const dateString = dateToLocaleString(values?.created_date);
        const dateStringPretty = dateToPrettyDate(values?.created_date);

        return (
          <form id="fileForm" noValidate onSubmit={props.handleSubmit}>
            <a
              className="mb-2"
              href={values?.storage_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="max-h-64 w-auto mx-auto rounded-sm"
                src={values?.storage_url}
                alt={values?.description}
              />
            </a>
            <p
              className="text-xs text-center mt-1 mb-3"
              data-tooltip-content={dateStringPretty}
              data-tooltip-id="route-tooltip"
            >
              {t('forms.FileForm.uploadedDateText')} {dateString}
            </p>

            <FormTextInput
              label={t('forms.FileForm.description.label')}
              name="description"
              placeholder={t('forms.FileForm.description.placeholder')}
              readOnly={readOnly}
            />

            <div className="mb-3">
              <TagsInput
                label={
                  <>
                    {' '}
                    {t('forms.FileForm.keywords.text.1')} ({t('forms.FileForm.keywords.text.2')}{' '}
                    <samp>Enter</samp> {t('forms.FileForm.keywords.text.3')})
                  </>
                }
                onUpdate={onTagUpdate}
                initialTags={initialTags}
                readOnly={readOnly}
              />
            </div>

            {showMarkAsPrimary && (
              <FormCheckboxInput
                label={t('forms.FileForm.primary.label')}
                name="marked_as_primary"
                readOnly={readOnly}
              />
            )}

            {!hideButtons && (
              <div className="flex justify-start gap-x-2 mt-2">
                <SubmittingButton
                  submittingText={submittingText}
                  buttonText={buttonText}
                  submitting={props.isSubmitting}
                />

                {onDelete && (
                  <Button
                    variant="outline-danger"
                    disabled={props.isSubmitting}
                    onClick={() => onDelete()}
                  >
                    {t('forms.FileForm.deleteButtonText')}
                  </Button>
                )}
              </div>
            )}
          </form>
        );
      }}
    />
  );
};

export default FileForm;
