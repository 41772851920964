import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useCustomerAlertRules } from 'utils/hooks/data/customers';
import { useUserAlertRules } from 'utils/hooks/data/users';
import HookOptions from 'utils/types/HookOptions';

export const useUserAndCustomerAlertRules = (
  userId?: string,
  customerId?: string,
  hookOptions: HookOptions = {},
) => {
  const resultUser = useUserAlertRules(userId, hookOptions);
  const resultCustomer = useCustomerAlertRules(customerId, {
    ignoreAdminCustomerOverwrite: true,
    ...hookOptions,
  });

  const alertRules = useMemo(
    () =>
      // We merge the sensors from the user and the customer and remove duplicates.
      uniqBy([...(resultUser.alertRules || []), ...(resultCustomer.alertRules || [])], 'id'),
    [resultUser.alertRules, resultCustomer.alertRules],
  );

  return {
    alertRules,
    createAlertRuleForCustomer: resultCustomer.createAlertRuleForCustomer,
    createAlertRuleForUser: resultUser.createAlertRuleForUser,
    isPending: resultUser.isPending || resultCustomer.isPending,
  };
};
