import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import AlarmRule, { getQueryKeyAdminAlarmRules, parseAlarmRule } from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRules } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

export const useCustomerAlertRules = (
  customerId?: string,
  { enableGet = true, ignoreAdminCustomerOverwrite = false }: HookOptions = {},
) => {
  const { showAllCustomers } = useSelectedCustomer();

  const queryKey =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? getQueryKeyAdminAlarmRules()
      : getQueryKeyCustomerAlarmRules(customerId!);

  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/alarm_rules'
      : `/customers/${customerId}/alarm_rules`;

  const queryClient = useQueryClient();

  // Get
  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(url),
    select: (alarm_rules: any[]) => alarm_rules.map(parseAlarmRule),
    enabled: !!customerId && enableGet,
  });

  // Needed in order to update `alertRules` to undefined when `enabled` turns false (e.g. when switching customers)
  const alertRules = useMemo(
    () => (!!customerId && enableGet ? data : undefined),
    [data, customerId, enableGet],
  );

  // Create
  const { mutateAsync: createAlertRuleForCustomer } = useMutation({
    mutationFn: async (
      values: Pick<
        AlarmRule,
        'name' | 'type' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
      >,
    ) => {
      const alarmRules: any[] = await api.post(url, values);
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: alarmRules => {
      queryClient.setQueryData(queryKey, alarmRules);
    },
  });

  // Admin - Attach alarm rule by id
  const { mutateAsync: adminAttachAlarmRuleById } = useMutation({
    mutationFn: async (alarmRuleId: string): Promise<AlarmRule[]> => {
      const alarmRules = await api.post(
        `/admin/customers/${customerId}/alarm_rules/${alarmRuleId}`,
      );
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: async alarmRules => {
      queryClient.setQueryData(queryKey, alarmRules);
    },
  });

  // Admin - Detach alarm rule by id
  const { mutateAsync: adminDetatchAlarmRuleById } = useMutation({
    mutationFn: async (alarmRuleId: string): Promise<AlarmRule[]> => {
      const alarmRules = await api.delete(
        `/admin/customers/${customerId}/alarm_rules/${alarmRuleId}`,
      );
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: async alarmRules => {
      queryClient.setQueryData(queryKey, alarmRules);
    },
  });

  return {
    adminAttachAlarmRuleById,
    adminDetatchAlarmRuleById,
    alertRules,
    createAlertRuleForCustomer,
    isPending,
  };
};
