import { AlertSeverityLevel } from 'utils/enums';
import { Alarm } from 'utils/types';

export const getAlertTextColor = (alert: Alarm) => {
  if (alert?.severity_level === AlertSeverityLevel.Critical) {
    return !alert?.resolved_time ? 'text-brand-orange' : 'text-brand-blue';
  }
  if (alert?.severity_level === AlertSeverityLevel.Info || !alert?.severity_level) {
    return 'text-brand-gray-light-2';
  }
  return '';
};

export const getAlertBackgroundClass = (alert: Alarm) => {
  if (alert?.severity_level === AlertSeverityLevel.Critical) {
    return !alert?.resolved_time ? 'bg-brand-orange' : 'bg-brand-blue';
  }
  if (alert?.severity_level === AlertSeverityLevel.Info || !alert?.severity_level) {
    return 'bg-brand-gray-light-2';
  }
  return '';
};
