export const NoDataRow = ({
  placeholder,
  height,
  colSpan,
}: {
  placeholder: string;
  height: number;
  colSpan: number;
}) => (
  <tr className="w-full">
    <td
      colSpan={colSpan}
      style={{ height: `${height}px` }}
      className="text-brand-gray-light-1 text-center align-middle"
    >
      {placeholder}
    </td>
  </tr>
);
