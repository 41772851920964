import classNames from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DropZoneSize, FileDropZone } from 'components';
import { FileGallery } from 'components/cards/FilesCard/components';
import UpdateFileModal from 'components/modals/UpdateFileModal';
import { MySwal, notificationSuccess } from 'utils/notifications';
import FileBase from 'utils/types/FileBase';

export const FilesCard: React.FC<{
  file?: FileBase;
  files?: FileBase[];
  setFileId?: Dispatch<SetStateAction<string | undefined>>;
  createFile?: (file: FileBase) => Promise<void>;
  updateFile?: (file: FileBase) => Promise<void>;
  deleteFile?: (t: any) => Promise<void>;
  showDropZone?: boolean;
  processFileBeforeUpload?: (file: any) => FileBase;
  showMarkAsPrimary?: boolean;
  defaultToTakePicture?: boolean;
  dropZoneSize?: DropZoneSize;
}> = ({
  file,
  files,
  setFileId,
  createFile,
  updateFile,
  deleteFile,
  processFileBeforeUpload,
  showDropZone = true,
  showMarkAsPrimary,
  defaultToTakePicture,
  dropZoneSize,
  ...props
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [waitingForSwal, setWaitingForSwal] = useState(false);

  const { t } = useTranslation('components');

  const onFileSubmit = async (files: FileBase[]) => {
    if (!createFile) return;

    const n = files.length;
    const fileUploadPromises = files.map(file => {
      if (processFileBeforeUpload) file = processFileBeforeUpload(file);
      return createFile(file);
    });

    await Promise.all(fileUploadPromises);
    if (n > 1) {
      notificationSuccess(
        t('cards.FilesCard.onFileSubmit.successText.1', {
          n,
        }),
      );
    } else {
      notificationSuccess(t('cards.FilesCard.onFileSubmit.successText.2'));
    }
  };

  const onFileClick = (file: FileBase) => {
    if (!setFileId) return;
    setFileId(file.id);
    setShowUpdateModal(true);
  };

  const onModalSubmit = async (values: FileBase) => {
    if (!updateFile) return;
    await updateFile(values);
    notificationSuccess(t('cards.FilesCard.onModalSubmit.successText'));
    setShowUpdateModal(false);
  };

  const onDelete = async () => {
    if (!deleteFile || !setFileId) return;
    setWaitingForSwal(true);

    await MySwal.fire({
      icon: 'warning',
      showConfirmButton: false,
      title: t('cards.FilesCard.onDelete.title'),
      html: (
        <>
          <p className="mb-3">{t('cards.FilesCard.onDelete.text')}</p>
          <div className="flex gap-x-5 justify-center">
            <Button
              onClick={async () => {
                await deleteFile(() => setWaitingForSwal(false));
                setShowUpdateModal(false);
                await notificationSuccess(t('cards.FilesCard.onDelete.successText'));
                setFileId(undefined);
              }}
              size="lg"
              variant="danger"
            >
              {t('cards.FilesCard.onDelete.confirmButtonText')}
            </Button>
            <Button onClick={() => MySwal.close()} size="lg" variant="outline-secondary">
              {t('cards.FilesCard.onDelete.cancelButtonText')}
            </Button>
          </div>
        </>
      ),
    });
    setWaitingForSwal(false);
  };

  return (
    <>
      {showDropZone && createFile && (
        <FileDropZone
          className="mb-5"
          /** @ts-ignore **/
          onSubmit={onFileSubmit}
          defaultToTakePicture={defaultToTakePicture}
          size={dropZoneSize}
        />
      )}
      <div className={classNames({ 'mb-5': !!files?.length })}>
        <FileGallery files={files} onClick={setFileId ? onFileClick : undefined} />
      </div>
      <UpdateFileModal
        onSubmit={updateFile ? onModalSubmit : undefined}
        show={showUpdateModal}
        setShow={setShowUpdateModal}
        values={file}
        showMarkAsPrimary={showMarkAsPrimary}
        buttonText={t('cards.FilesCard.UpdateFileModal.buttonText')}
        submittingText={t('cards.FilesCard.UpdateFileModal.submittingText')}
        onDelete={deleteFile && setFileId ? onDelete : undefined}
        closeOnClickOutside={!waitingForSwal}
        {...props}
      />
      {!showDropZone && files?.length === 0 && (
        <p className="text-center mt-3">{t('cards.FilesCard.noFilesFound')}</p>
      )}
    </>
  );
};
