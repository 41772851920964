import { t } from 'translations';
import { DataField } from 'utils/types/PlotTypes';

export const legendFormatter = (name: DataField) => {
  switch (name) {
    case 'moisture':
      return t('utils:plots.legends.moisture');
    case 'raw_moisture':
      return t('utils:plots.legends.rawMoisture');
    case 'humidity':
      return t('utils:plots.legends.humidity');
    case 'temperature':
      return t('utils:plots.legends.temperature');
    case 'precip':
      return t('utils:plots.legends.weatherPrecip');
    case 'humidity_weather':
      return t('utils:plots.legends.weatherHumidity');
    case 'temperature_weather':
      return t('utils:plots.legends.weatherTemperature');
    case 'co2':
      return t('utils:plots.legends.co2');
    case 'voc':
      return t('utils:plots.legends.voc');
    case 'anomalies':
      return t('utils:plots.legends.anomalies');
    case 'moisture_forecast':
      return t('utils:plots.legends.moistureForecast');
    case 'moisture_forecast_mean':
      return t('utils:plots.legends.moistureForecastMean');
    case 'temperature_forecast':
      return t('utils:plots.legends.temperatureForecast');
    case 'temperature_forecast_mean':
      return t('utils:plots.legends.temperatureForecastMean');
    case 'humidity_forecast':
      return t('utils:plots.legends.humidityForecast');
    case 'humidity_forecast_mean':
      return t('utils:plots.legends.humidityForecastMean');
    case 'wind':
      return t('utils:plots.legends.wind');
    case 'reference_values':
      return t('utils:plots.legends.referenceValues');
    case 'emc':
      return t('utils:plots.legends.emc');
    case 'events':
      return t('utils:plots.legends.events');
    case 'risk_score':
      return t('utils:plots.legends.risk_score');
    default:
      return name;
  }
};

// From: node_modules/echarts/types/dist/shared.d.ts
interface LegendTooltipFormatterParams {
  componentType: 'legend';
  legendIndex: number;
  name: string;
}

export const legendTooltipFormatter = (params: LegendTooltipFormatterParams) => {
  switch (params.name) {
    case 'moisture':
    case 'raw_moisture':
      return t('utils:plots.legends.tooltips.moisture');
    case 'humidity':
      return t('utils:plots.legends.tooltips.humidity');
    case 'temperature':
      return t('utils:plots.legends.tooltips.temperature');
    case 'precip':
      return t('utils:plots.legends.tooltips.weatherPrecip');
    case 'humidity_weather':
      return t('utils:plots.legends.tooltips.weatherHumidity');
    case 'temperature_weather':
      return t('utils:plots.legends.tooltips.weatherTemperature');
    case 'co2':
      return t('utils:plots.legends.tooltips.co2');
    case 'voc':
      return t('utils:plots.legends.tooltips.voc');
    case 'anomalies':
      return t('utils:plots.legends.tooltips.anomalies');
    case 'moisture_forecast':
    case 'moisture_forecast_mean':
      return t('utils:plots.legends.tooltips.moistureForecast');
    case 'temperature_forecast':
    case 'temperature_forecast_mean':
      return t('utils:plots.legends.tooltips.temperatureForecast');
    case 'humidity_forecast':
    case 'humidity_forecast_mean':
      return t('utils:plots.legends.tooltips.humidityForecast');
    case 'wind':
      return t('utils:plots.legends.tooltips.wind');
    case 'reference_values':
      return t('utils:plots.legends.tooltips.referenceValues');
    case 'emc':
      return t('utils:plots.legends.tooltips.emc');
    case 'events':
      return t('utils:plots.legends.tooltips.events');
    case 'risk_score':
      return t('utils:plots.legends.tooltips.risk_score');
    default:
      return params.name;
  }
};

export const legendTooltip = {
  triggerOn: 'mousemove',
  position: 'bottom',
  show: true,
  formatter: legendTooltipFormatter,
  appendToBody: true,
  confine: true,
};
