import { useTranslation } from 'react-i18next';

import { StatsTable, StatsTableProps } from 'components';
import ExpandableDiv from 'components/ExpandableDiv';

export const StatsTableWithTitle: React.FC<
  StatsTableProps & {
    expandable?: boolean;
  }
> = ({ expandable = false, ...props }) => {
  const { t } = useTranslation('components');

  const elements = (
    <>
      <div className="mb-3">
        <p className="text-brand-gray-light-1 inline text-xs lg:text-base">
          {t('tables.StatsTableWithTitle.text')}
        </p>
        <p className="inline-flex text-brand-gray-light-1 text-xs lg:text-base flex-row gap-2">
          <span className="text-brand-gray-light-2">{t('tables.StatsTableWithTitle.min')}</span>
          <span>/</span>
          <span className="font-bold">{t('tables.StatsTableWithTitle.mean')}</span>
          <span>/</span>
          <span className="text-brand-gray-light-2">
            <>{t('tables.StatsTableWithTitle.max')}.</>
          </span>
        </p>
      </div>

      <StatsTable {...props} />
    </>
  );

  return expandable ? (
    <ExpandableDiv
      title={t('tables.StatsTableWithTitle.title')}
      tooltipExpand={t('tables.StatsTableWithTitle.ExpandButton.hide')}
      tooltipCollapse={t('tables.StatsTableWithTitle.ExpandButton.show')}
    >
      {elements}
    </ExpandableDiv>
  ) : (
    elements
  );
};
