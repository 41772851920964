/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faCloudRain,
  faCloudShowersHeavy,
  faCloudShowersWater,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { differenceInHours, formatISO } from 'date-fns';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { PlacesType, Tooltip } from 'react-tooltip';

import { brandBlue, brandBlueLight1, brandBlueLight2 } from 'utils/colors';
import { Event } from 'utils/types';

export const WeatherIcon: React.FC<{
  id: string;
  icon: IconDefinition;
  color: string;
  weatherEvent: Event;
  xOffsetPercentage?: string;
  size?: string;
  tooltipPosition?: PlacesType;
  translateToCenter?: boolean;
}> = ({
  id,
  icon,
  color,
  weatherEvent,
  xOffsetPercentage = '0%',
  size = 'text-2xl',
  tooltipPosition = 'top',
  translateToCenter = true,
}) => {
  const { t } = useTranslation('components');
  return (
    <div className={classNames('absolute', size)} style={{ left: xOffsetPercentage }}>
      <FontAwesomeIcon
        className={classNames({ '-translate-x-[12px]': translateToCenter })}
        id={id}
        icon={icon}
        style={{ color }}
      />
      {createPortal(
        <Tooltip
          anchorSelect={`#${id}`}
          place={tooltipPosition}
          className="tooltip w-fit max-w-[280px] p-4 z-50 rounded-2xl shadow-xl bg-gradient-to-b from-brand-blue to-brand-blue-light-1 opacity-95"
        >
          <div className="flex flex-col">
            {/* Message */}
            {weatherEvent.body_text.split('\n').map(line => (
              <span>{line}</span>
            ))}
            <span className="w-full text-right mr-8 mt-2 text-xs text-white/75">
              {t('blueprints.PlayableTimeline.weatherIconTooltip')}
              {formatISO(weatherEvent.timestamp, { representation: 'date' })}
            </span>
          </div>
        </Tooltip>,
        document.body,
      )}
    </div>
  );
};

// Used when creating the fixed weather icon on the blueprint
export const createWeatherIconFromEvent = (
  event: Event,
  size: string,
  tooltipPosition?: PlacesType,
  translateToCenter: boolean = false,
) => {
  if (event.type !== 'weather') {
    throw new Error('Tried to create WeatherIcon from non-weather event');
  }
  const rainAmount = parseRainAmountFromEventBodyText(event.body_text);
  const [color, icon] = getIconAndColorFromRainAmount(rainAmount);
  return (
    <WeatherIcon
      id="weather-icon"
      icon={icon}
      color={color}
      weatherEvent={event}
      size={size}
      tooltipPosition={tooltipPosition}
      translateToCenter={translateToCenter}
    />
  );
};

// Used when creating weather icons for the timeline
export const createOffsetWeatherIconFromEvent = (
  event: Event,
  startTimestamp: Date,
  hoursInTimePeriod: number,
  index: number,
) => {
  if (event.type !== 'weather') {
    throw new Error('Tried to create WeatherIcon from non-weather event');
  }

  const rainAmount = parseRainAmountFromEventBodyText(event.body_text);
  const [color, icon] = getIconAndColorFromRainAmount(rainAmount);

  const xOffset = Math.floor(
    getOffsetFromDate(event.timestamp, startTimestamp, hoursInTimePeriod) * 100,
  );
  const id = `weather-icon-${index}`;

  return (
    <WeatherIcon
      key={id}
      id={id}
      icon={icon}
      color={color}
      xOffsetPercentage={`${xOffset}%`}
      weatherEvent={event}
    />
  );
};

const getIconAndColorFromRainAmount = (rainAmount: number): [string, IconDefinition] => {
  // Definitions loosely from here https://en.wikipedia.org/wiki/Rain#Intensity
  // We don't show light rain, so 'Moderate rain' is split into two
  if (rainAmount < 5.0) {
    return [brandBlueLight2, faCloudRain];
  } else if (rainAmount < 7.6) {
    return [brandBlueLight1, faCloudShowersHeavy];
  } else {
    return [brandBlue, faCloudShowersWater];
  }
};

const getOffsetFromDate = (
  eventTimestamp: Date,
  startTimestamp: Date,
  hoursInTimePeriod: number,
) => {
  const hoursFromStart = differenceInHours(eventTimestamp, startTimestamp);
  return hoursFromStart / hoursInTimePeriod;
};

export const parseRainAmountFromEventBodyText = (bodyText: string) => {
  const regex = /\d*\.?\d*mm/;
  const match = bodyText.match(regex);
  return parseFloat(match ? match[0] : '0.0');
};
