import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

type SensorsBulkUpdate = {
  customer_id: string;
};

export const useSensorGroupSensors = (
  sensorGroupId?: string,
  { enableGet = true, disableErrorNotifications = false }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupSensors', sensorGroupId];

  const queryClient = useQueryClient();

  // Get
  const {
    data: sensors,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/sensors`, {
        _disableErrorNotifications: disableErrorNotifications,
      }),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: enableGet && !!sensorGroupId,
  });

  // Update sensors in group
  const { mutateAsync: updateSensorsInGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      ...props
    }: { sensorGroupId: string } & SensorsBulkUpdate): Promise<any[]> =>
      api.put(`/sensor_groups/${sensorGroupId}/sensors`, props),
    onSuccess: async (sensorsRaw, sensorGroupId) => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  // Update sensors in current group
  const updateSensorsInGroup = async (props: SensorsBulkUpdate) =>
    await updateSensorsInGroupById({ sensorGroupId: sensorGroupId!, ...props });

  // Callbacks
  const onSuccessAddSensors = async (
    sensorsRaw: any[],
    { sensorGroupId }: { sensorGroupId: string },
  ) => {
    queryClient.setQueryData(['sensorGroupSensors', sensorGroupId], sensorsRaw);
    queryClient.invalidateQueries({
      queryKey: ['sensorGroupBlueprints', sensorGroupId],
    });
  };

  const onSuccessRemoveSensors = async (sensorsRaw: any[]) => {
    queryClient.setQueryData(['sensorGroupSensors', sensorGroupId], sensorsRaw);
    queryClient.invalidateQueries({
      queryKey: ['sensorGroupBlueprints', sensorGroupId],
    });
  };

  // Add sensor to any group
  const { mutateAsync: addSensorToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      sensorId,
    }: {
      sensorGroupId: string;
      sensorId: string;
    }): Promise<any[]> => api.post(`/sensor_groups/${sensorGroupId}/sensors/${sensorId}`),
    onSuccess: onSuccessAddSensors,
  });

  // Add sensors to any group (bulk)
  const { mutateAsync: addSensorsToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      sensorIds,
    }: {
      sensorGroupId: string;
      sensorIds: string[];
    }): Promise<any[]> => {
      if (sensorIds.length === 0) throw new Error('No sensors to add');
      return api.post(
        `/sensor_groups/${sensorGroupId}/sensors_bulk?sensor_ids=${sensorIds.join(',')}`,
      );
    },
    onSuccess: onSuccessAddSensors,
  });

  // Add sensors from other group to any group
  const { mutateAsync: addSensorsFromOtherGroupToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      otherGroupId,
    }: {
      sensorGroupId: string;
      otherGroupId: string;
    }): Promise<any[]> =>
      api.post(`/sensor_groups/${sensorGroupId}/sensors/add_from_other_group/${otherGroupId}`),
    onSuccess: onSuccessAddSensors,
  });

  // Add sensor to current group
  const addSensorToGroup = async (sensorId: string) =>
    await addSensorToGroupById({ sensorGroupId: sensorGroupId!, sensorId });

  // Add sensors to current group (bulk)
  const addSensorsToGroup = async (sensorIds: string[]) =>
    await addSensorsToGroupById({ sensorGroupId: sensorGroupId!, sensorIds });

  // Add sensors from other group to current group
  const addSensorsFromOtherGroupToGroup = async (otherGroupId: string) =>
    await addSensorsFromOtherGroupToGroupById({ sensorGroupId: sensorGroupId!, otherGroupId });

  // Remove sensor from group
  const { mutateAsync: removeSensorFromGroup } = useMutation({
    mutationFn: (sensorId: string): Promise<any[]> =>
      api.delete(`/sensor_groups/${sensorGroupId}/sensors/${sensorId}`),
    onSuccess: onSuccessRemoveSensors,
  });

  // Remove sensors from current group (bulk)
  const { mutateAsync: removeSensorsFromGroup } = useMutation({
    mutationFn: (sensorIds: string[]): Promise<any[]> => {
      if (sensorIds.length === 0) throw new Error('No sensors to remove');
      return api.delete(
        `/sensor_groups/${sensorGroupId}/sensors_bulk?sensor_ids=${sensorIds.join(',')}`,
      );
    },
    onSuccess: onSuccessRemoveSensors,
  });

  return {
    addSensorsFromOtherGroupToGroup,
    addSensorsFromOtherGroupToGroupById,
    addSensorsToGroup,
    addSensorsToGroupById,
    addSensorToGroup,
    addSensorToGroupById,
    isPending: isPending || isLoading || isFetching,
    removeSensorFromGroup,
    removeSensorsFromGroup,
    sensors,
    updateSensorsInGroup,
    updateSensorsInGroupById,
  };
};
