import { differenceInHours, roundToNearestHours, subHours } from 'date-fns';
import Konva from 'konva';
import 'konva/lib/shapes/Image';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Text';
import { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/';
import { SensorIconAndLabelConfig } from 'components/BlueprintCanvas/components/types';
import CanvasStage, {
  CanvasStageContext,
  DEFAULT_STAGE_STATE,
  ImageSize,
  StageState,
} from 'components/CanvasStage';
import Spinner from 'components/Spinner';
import { getSensorStateColor } from 'utils/colors';
import { sensorId2NameFunc } from 'utils/formatting';
import { useImage, useTimePeriod, useWindowSize } from 'utils/hooks';
import {
  useBlueprint,
  useBlueprintPositions,
  useBlueprintSensors,
  useSensorBlueprintSnippetLocation,
  useSensorsBulkTransmissions,
} from 'utils/hooks/data';
import { getSensorTransmission } from 'utils/sensor/transmissions';
import { BlueprintViewStateType } from 'utils/types';
import Transmission from 'utils/types/Transmission';

const FALLBACK_CANVAS_HEIGHT_PX = 500;

export const CanvasSnippet: React.FC<{
  blueprintId: string;
  stageHeightPercentage?: number; // Used to calculate height based on viewport height
  onlyEnableSensorIds?: string[];
  onlyShowSensorIds?: string[];
  onlyShowTooltipforSensorIds?: string[];
  enableToolbox?: boolean;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
  focusSensorIds?: string[];
}> = ({
  blueprintId,
  onlyEnableSensorIds,
  onlyShowTooltipforSensorIds,
  onlyShowSensorIds,
  stageHeightPercentage = 1,
  focusSensorIds = [],
}) => {
  const { t } = useTranslation('components');

  // only when there is a single sensor id, mean that we are in the sensor values page
  const { snippetLocation, isPending: isPendingSnippetLocation } =
    useSensorBlueprintSnippetLocation(
      onlyEnableSensorIds?.length === 1 ? onlyEnableSensorIds[0] : undefined,
    );

  // Contexts
  const {
    hours,
    setHours,
    activeBlueprintPosition,
    editModeEnabled,
    hiddenBlueprintPositionIds,
    blueprintHeight,
    setBlueprintHeight,
    isFullscreen,
  } = useContext(BlueprintCanvasContext);

  // Blueprint view state is risk score for the snippet, because we show the snippet only for flat roofs as of now
  const blueprintViewState = BlueprintViewStateType.RiskScore;

  // States
  const [blueprintImageSize, setBlueprintImageSize] = useState<ImageSize>({ width: 0, height: 0 });
  const mainStageRef = useRef<Konva.Stage>() as MutableRefObject<Konva.Stage> | undefined;
  const [stageState, setStageState] = useState<StageState>(DEFAULT_STAGE_STATE);

  // Canvas size
  const [windowHeight] = useWindowSize();
  useEffect(() => {
    setBlueprintHeight(windowHeight * stageHeightPercentage || FALLBACK_CANVAS_HEIGHT_PX);
  }, [windowHeight, stageHeightPercentage, setBlueprintHeight, isFullscreen]);

  const canvasDifRef = useRef<HTMLDivElement>(null);

  // Tooltips are enabled if any of the three options are enabled
  const highlightCertainSensors = !!onlyEnableSensorIds && onlyEnableSensorIds.length > 0;

  // Refs to store references to the Label and Image components for highlighted sensors
  const highlightedImagesRefs = useRef<(Konva.Image | null)[]>([]);

  // Hooks
  const { blueprint, isPending: isPendingBlueprint } = useBlueprint(blueprintId);
  const { sensors, isPending: isPendingSensors } = useBlueprintSensors(blueprintId);

  const { blueprintPositions, isPending: isPendingPositions } = useBlueprintPositions(blueprintId);

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();
  const max = 0;

  const { transmissions } = useSensorsBulkTransmissions(
    sensors?.map(sensor => sensor.id) ?? [],
    {
      fromTimestamp: subHours(timeTo, 24), // Use only latest day transmissions for generating the blueprint snippet
      toTimestamp: timeTo,
      fields: [
        'id',
        'timestamp',
        'moisture',
        'spreading_factor',
        'sensor_id',
        'hardware_id',
        'risk_score',
        'risk_reasons_int_keys',
      ],
    },
    {
      enableGet: !isPendingSensors && (sensors?.length ?? 0) > 0,
    },
  );

  // Split transmissions by sensor id
  const sensorIdToTransmission = useMemo(() => {
    if (!transmissions) return {};

    return transmissions.reduce(
      (acc, transmission) => {
        const { sensor_id } = transmission;
        if (!acc[sensor_id]) {
          acc[sensor_id] = [];
        }
        acc[sensor_id].push(transmission);
        return acc;
      },
      {} as { [sensorId: string]: Transmission[] },
    );
  }, [transmissions]);

  // Images
  const [blueprintImage] = useImage(blueprint?.storage_url);

  // Mappings
  const sensorId2Name = sensorId2NameFunc(sensors);
  const isSensorIdEnabled = Array.isArray(onlyEnableSensorIds)
    ? Object.fromEntries(
        (sensors || []).map(sensor => [sensor.id, onlyEnableSensorIds.includes(sensor.id)]),
      )
    : Object.fromEntries((sensors || []).map(sensor => [sensor.id, true]));

  const tooltipText = (sensorId?: string) => {
    if (!sensorIdToTransmission || !sensorId) return;

    const transmission = getSensorTransmission(
      timeTo,
      max,
      hours,
      sensorIdToTransmission,
      sensorId,
      blueprintViewState,
    );
    const name = sensorId2Name[sensorId];

    const tooltipLines: string[] = [];
    tooltipLines.push(`${name}`);
    const { risk_score } = transmission;
    if (blueprintViewState === BlueprintViewStateType.RiskScore) {
      tooltipLines.push(
        `${typeof risk_score === 'number' ? `Risk: ${risk_score.toFixed(1)}` : 'n/a'}`,
      );
    }

    return tooltipLines.join('\n');
  };

  // Determine what images to show
  const blueprintPositionsToShow = onlyShowSensorIds
    ? (blueprintPositions || []).filter(blueprintPosition =>
        onlyShowSensorIds.includes(blueprintPosition.sensor_id),
      )
    : blueprintPositions || [];

  //Create sensor icons + label configs
  const sensorIconAndLabelConfigs: SensorIconAndLabelConfig[] = blueprintPositionsToShow
    .filter(blueprintPosition => blueprintPosition.isPositionDefined)
    .map(blueprintPosition => {
      // Boolean config
      const isFocused = focusSensorIds.includes(blueprintPosition.sensor_id);
      const isActive = activeBlueprintPosition?.id === blueprintPosition.id || isFocused;
      const isEnabled = isSensorIdEnabled[blueprintPosition.sensor_id];
      const isHighlighted = highlightCertainSensors && isEnabled;
      const isVisible = !hiddenBlueprintPositionIds.includes(blueprintPosition.id);
      const isDraggable = isEnabled && editModeEnabled;
      const showTooltip = onlyShowTooltipforSensorIds
        ? onlyShowTooltipforSensorIds.includes(blueprintPosition.sensor_id)
        : true;

      // Content config
      const color = getSensorStateColor(
        timeTo,
        max,
        hours,
        sensorIdToTransmission,
        blueprintPosition.sensor_id,
        blueprintViewState,
      );

      const labelText = `${tooltipText(blueprintPosition.sensor_id)}`;
      //Position
      const xPos = blueprintPosition.position_x || 0;
      const yPos = blueprintPosition.position_y || 0;

      return {
        positionId: blueprintPosition.id,
        isActive,
        isEnabled,
        isVisible,
        isHighlighted,
        isDraggable,
        isFocused,
        showTooltip,
        xPos,
        yPos,
        labelText,
        color,
        onDragEnd: async () => {},
        onDragStart: async () => {},
        onMouseEnterPin: () => {},
        onMouseLeave: () => {},
      };
    });

  useEffect(() => {
    setHours(differenceInHours(roundToNearestHours(timeTo), roundToNearestHours(timeFrom)));
  }, [timeFrom, timeTo, setHours]);

  // Move highlighted sensors on top
  if (highlightCertainSensors) {
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
  }

  const isPending =
    isPendingBlueprint || isPendingSnippetLocation || isPendingSensors || isPendingPositions;

  return (
    <>
      {isPending && (
        <div className="flex h-full w-full text-center items-center justify-center">
          <Spinner />
        </div>
      )}
      {!isPending && (
        <>
          {!!snippetLocation ? (
            <div
              className="flex flex-col relative h-full w-full"
              ref={canvasDifRef}
              style={!isFullscreen ? { maxHeight: blueprintHeight } : {}}
            >
              {/* Canvas */}
              <div className="relative flex w-full h-full grow overflow-hidden">
                <CanvasStageContext.Provider value={{ stageState, setStageState, mainStageRef }}>
                  <CanvasStage
                    stageHeight={blueprintHeight}
                    imageSize={blueprintImageSize}
                    setImageSize={setBlueprintImageSize}
                    backgroundImage={blueprintImage}
                    snippetLocation={snippetLocation}
                    sensorIconAndLabelConfigs={sensorIconAndLabelConfigs}
                  />
                </CanvasStageContext.Provider>
              </div>
            </div>
          ) : (
            <div className="flex h-full w-full text-center items-center justify-center text-brand-gray-light-1">
              <span>{t('blueprints.CanvasSnippet.noBlueprintPreviewAvailable')}</span>
            </div>
          )}
        </>
      )}
    </>
  );
};
