import { t } from 'translations';
import AlarmRuleSubType from 'utils/enums/AlarmRuleSubType';

export const getAlarmRuleSubtypeText = (alarmRuleSubType: AlarmRuleSubType) => {
  switch (alarmRuleSubType) {
    case AlarmRuleSubType.BuiltInCustom:
      return t('utils:texts.AlarmRuleSubType.getAlarmRuleSubTypeText.BuiltInCustom');
    case AlarmRuleSubType.MissingValues48Hours:
      return t('utils:texts.AlarmRuleSubType.getAlarmRuleSubTypeText.MissingValues48Hours');
    case AlarmRuleSubType.MissingValues1Week:
      return t('utils:texts.AlarmRuleSubType.getAlarmRuleSubTypeText.MissingValues1Week');
    case AlarmRuleSubType.MissingValues4Weeks:
      return t('utils:texts.AlarmRuleSubType.getAlarmRuleSubTypeText.MissingValues4Weeks');
    case AlarmRuleSubType.Custom:
      return t('utils:texts.AlarmRuleSubType.getAlarmRuleSubTypeText.Custom');
    default:
      return 'n/a';
  }
};
