import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import TransmissionAnomaly from 'utils/types/TransmissionAnomaly';

export const useSensorAnomalies = (sensorId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateAnomaliesInPeriod } = useMutation({
    mutationFn: async ({
      fromTimestamp,
      toTimestamp,
      ...values
    }: Pick<TransmissionAnomaly, 'type' | 'description' | 'validation'> & {
      fromTimestamp: Date;
      toTimestamp: Date;
    }) =>
      api.patch(`/sensors/${sensorId}/anomalies`, {
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
        ...values,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['sensorTransmissions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsCustomers'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsTransmissions'],
        exact: false,
      });
    },
  });

  return {
    updateAnomaliesInPeriod,
  };
};
