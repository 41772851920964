import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';

import {
  AlertActionsStatus,
  AssignResponsibleButton,
  MarkAsReadButton,
  ResolveButton,
} from 'components';
import Card from 'components/cards/Card';
import { faSquirtyAlert } from 'components/icons';
import { getAlertBackgroundClass, getAlertTextColor } from 'utils/alertColor';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { AlertSeverityLevel } from 'utils/enums';
import environment from 'utils/environment';
import {
  useAlarmGateway,
  useAlarmSensor,
  useAlertDescription,
  useAlertRule,
} from 'utils/hooks/data';
import { isFeatureEnabled } from 'utils/isFeatureEnabled';
import { getAlertName } from 'utils/texts/AlertName';
import Alarm from 'utils/types/Alarm';

export const AlertCard = ({
  alert,
  isPending,
  isPreview = false,
  overwriteLinkTo,
}: {
  alert: Alarm;
  isPending: boolean;
  isPreview?: boolean;
  overwriteLinkTo?: (pathname: string, alertId: string) => string;
}) => {
  const location = useLocation();

  const { t } = useTranslation('components');

  const { sensor, isPending: isSensorPending } = useAlarmSensor(alert.id);
  const { gateway, isPending: isGatewayPending } = useAlarmGateway(alert.id);

  const { description } = useAlertDescription(alert.id);

  const { alertRule, isPending: isAlarmRulePending } = useAlertRule(alert?.alarm_rule_id);

  const isLoading = isPending || isAlarmRulePending || isSensorPending || isGatewayPending;

  const isResolveAlertEnabled = isFeatureEnabled('resolveAlert', environment);

  const linkTo = overwriteLinkTo
    ? `${overwriteLinkTo(location.pathname, alert.id)}${location.search}`
    : `${location.pathname}/${alert.id}${location.search}`;

  const heightAlertCard: string = isPreview ? 'h-16' : 'h-24';

  return (
    <div className="relative w-full">
      {isLoading ? (
        <Skeleton className={classNames('rounded-md w-full', heightAlertCard)} />
      ) : (
        <Card
          noPaddingBody
          body={
            <Link to={linkTo}>
              <div className={classNames('relative text-xs p-2', heightAlertCard)}>
                {/* Stripe: colored based on incident severity */}
                <div
                  className={classNames('absolute top-0 left-0 w-2 h-full', {
                    [getAlertBackgroundClass(alert)]: true,
                  })}
                />

                {/* Content */}
                <div className="flex flex-row gap-2 ml-2 justify-between items-start">
                  <div className="flex items-start gap-2">
                    {' '}
                    {/* Alert Icon */}
                    <FontAwesomeIcon
                      size="lg"
                      icon={faSquirtyAlert}
                      className={classNames({
                        [getAlertTextColor(alert)]: true,
                      })}
                    />
                    {/* Alert details */}
                    <div className="flex flex-col gap-1 leading-none mt-auto">
                      <div
                        className={classNames(
                          'flex items-start justify-start sm:items-center truncate gap-2',
                          {
                            [getAlertTextColor(alert)]: true,
                            'flex-col': isPreview,
                            'flex-row': !isPreview,
                          },
                        )}
                      >
                        <div className="flex flex-row gap-2 justify-start">
                          {/* Device name */}
                          <div className="text-xs w-[70px] truncate">
                            {sensor?.name ?? gateway?.id ?? ''}
                          </div>
                          <FontAwesomeIcon size="2xs" icon={faCircle} className="my-auto" />
                          {/* Time */}
                          <div
                            className="text-xs w-[70px] truncate"
                            data-tooltip-content={dateToLocaleString(alert.created_date)}
                            data-tooltip-id="route-tooltip"
                          >
                            {dateToPrettyDate(alert.created_date)}
                          </div>
                        </div>

                        <div className="flex flex-row gap-2 justify-start w-full">
                          {!isPreview && (
                            <FontAwesomeIcon size="2xs" icon={faCircle} className="my-auto" />
                          )}
                          {/* Title */}
                          <div className="flex text-xs truncate overflow-hidden justify-start">
                            {alert?.type
                              ? getAlertName(alert.type, alertRule ?? undefined)
                              : t('user.Alerts.AlertCard.unknownAlert')}
                          </div>
                        </div>
                      </div>
                      {!isPreview && (
                        <>
                          {/* Description */}
                          <div className="flex text-xs text-brand-gray-light-1 w-56 sm:w-full">
                            <span className="truncate overflow-hidden whitespace-nowrap">
                              {description}
                            </span>
                          </div>
                          {/* Action buttons */}
                          <div className="flex flex-row gap-2 mt-2">
                            {alert.severity_level === AlertSeverityLevel.Critical && (
                              <>
                                {isResolveAlertEnabled && <ResolveButton alertId={alert.id} />}
                                <AssignResponsibleButton alertId={alert.id} />
                              </>
                            )}

                            <MarkAsReadButton alertId={alert.id} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Seen by, resolved by, responsible */}
                  {!isPreview && <AlertActionsStatus alertId={alert.id} />}
                </div>
              </div>
            </Link>
          }
        />
      )}
    </div>
  );
};
