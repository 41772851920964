import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import HookOptions from 'utils/types/HookOptions';
import { DataTuple } from 'utils/types/PlotTypes';

export const useSensorReferenceValues = (
  sensorId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
    limit?: number;
  },
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = fromTimestamp && floorToLatest5Minutes(fromTimestamp);
  toTimestamp = toTimestamp && floorToLatest5Minutes(toTimestamp);

  const queryKey = ['useSensorReferenceValues', sensorId, fromTimestamp, toTimestamp, limit];

  const {
    data: referenceValues,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/reference_values`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          limit,
        },
      }),
    select: (results: any) => ({
      timestamp: results.timestamp.map((timestamp: string) => new Date(timestamp)) as Date[],
      referenceValues: results.reference_values as number[],
    }),
    enabled: !!sensorId && enableGet && !!fromTimestamp && !!toTimestamp,
  });

  const referenceValuesTuples = referenceValues
    ? referenceValues.timestamp.map(
        (timestamp, index) =>
          [timestamp, referenceValues.referenceValues[index], 'reference_values'] as DataTuple,
      )
    : [];

  return {
    referenceValues,
    referenceValuesTuples,
    isPending: isPending || isLoading || isFetching,
  };
};
