import { faCheck, faHandPointer, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ComponentPropsWithoutRef, useCallback, useEffect, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { faSquirtyImages } from 'components/icons';
import PDFLogo from 'images/icons/file-formats/pdf-icon.svg';
import { toBase64 } from 'utils/files';
import { useIsMounted, useMobileDetect } from 'utils/hooks';
import { notificationError } from 'utils/notifications';
import EnrichedFile from 'utils/types/EnrichedFile';

export type DropZoneSize = 'sm' | 'lg';

interface ExtendedFile extends File {
  preview?: string;
}

export const FileDropZone: React.FC<
  {
    showThumbs?: boolean;
    onSubmit: (files: EnrichedFile[]) => Promise<void>;
    boxPlaceholder?: string;
    singleUpload?: boolean;
    label?: string;
    defaultToTakePicture?: boolean;
    size?: DropZoneSize;
  } & Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'>
> = ({
  onSubmit,
  boxPlaceholder,
  showThumbs,
  singleUpload,
  label,
  defaultToTakePicture = false,
  size = 'lg',
  ...props
}) => {
  const [submitInitiated, setSubmitInitiated] = useState(false);
  const [files, setFiles] = useState<ExtendedFile[]>();
  const [uploadState, setUploadState] = useState<'ready' | 'uploading' | 'done'>('ready');

  const isMountedComponent = useIsMounted();
  const { isMobile, isAndroid, isSamsung } = useMobileDetect();

  // Show certain "ugly" html to allow file upload from unsupported devices
  const showLegacySupport = isAndroid() && isSamsung();

  const { t } = useTranslation('components');

  const enrichedFilesSubmit = useCallback(
    async (files: ExtendedFile[]) => {
      setSubmitInitiated(true);
      const filePromises = files.map(file => toBase64(file));

      const values = await Promise.all(filePromises);
      const filesEnriched = files.map((file, index) => {
        if (file.size > 30000000) {
          throw Error(t('inputs.FileDropZone.fileSizeTooBig'));
        }

        return {
          filename: file.name,
          base64_str: values[index],
          preview: file.preview,
          type: file.type,
          size: file.size,
          description: '',
          tags: '',
        } as EnrichedFile;
      });
      if (!isMountedComponent()) return;
      setUploadState('uploading');
      await onSubmit(filesEnriched);
      setSubmitInitiated(false);
      setUploadState('done');
    },
    [onSubmit, isMountedComponent, t],
  );

  useEffect(() => {
    if (uploadState !== 'done') return;

    // Reset upload state after 2 seconds
    const timeout = setTimeout(() => setUploadState('ready'), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [uploadState]);

  const onDropAccepted = useCallback(
    async (acceptedFiles: ExtendedFile[], event: DropEvent) => {
      const files = acceptedFiles.map(file => {
        if (file.type.startsWith('image')) {
          file.preview = URL.createObjectURL(file);
        }
        return file;
      });
      setFiles(files);
      files.forEach(file => {
        if (file.size > 10000000) {
          notificationError(t('inputs.FileDropZone.fileSizeTooBig'));
          return;
        }
      });

      await enrichedFilesSubmit(files);
    },
    [enrichedFilesSubmit, t],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
      // '.heic': [],
      // 'application/vnd.openxmlformaofficedocument.wordprocessingml.document',
      // 'text/plain',
    },
    onDropAccepted,
    multiple: !singleUpload,
  });

  const thumbs = files?.map(file => (
    <div className="inline-flex h-24 w-24" key={file.size}>
      <img alt="thumbnail" src={file.preview || PDFLogo} />
    </div>
  ));

  return (
    <div {...props}>
      {label && (
        <label className="mb-1.5" htmlFor="file-drop-zone">
          {label}
        </label>
      )}
      <div
        {...getRootProps({
          className: classnames(
            'items-center cursor-pointer bg-white rounded-sm border-dashed border-brand-gray-light-3 flex border-2 flex-col outline-none px-5',
            'transition-border duration-200',
            'hover:border-brand-blue',
            {
              'py-20': size === 'lg',
              'py-10': size === 'sm',
            },
          ),
        })}
      >
        <input
          data-testid="fileInput"
          id="file-drop-zone"
          disabled={submitInitiated}
          {...getInputProps({
            capture: 'environment',
          })}
        />
        <h3 className="text-base lg:text-xl flex items-center">
          {uploadState === 'done' ? (
            <>
              <FontAwesomeIcon
                className="text-brand-green-light-1 text-xl lg:text-3xl mr-2"
                icon={faCheck}
              />
              <span className="text-brand-green-light-1">
                {t('inputs.FileDropZone.singleFileUpload.uploadSuccess')}
              </span>
            </>
          ) : uploadState === 'uploading' ? (
            <>
              <FontAwesomeIcon
                className="text-brand-green-light-1 text-xl lg:text-3xl mr-2 animate-spin"
                icon={faSpinner}
              />
              <span className="text-brand-green-light-1">
                {t('inputs.FileDropZone.singleFileUpload.uploadInProgress')}
              </span>
            </>
          ) : defaultToTakePicture && isMobile() ? (
            <>
              <FontAwesomeIcon className="text-xl lg:text-3xl mr-2" icon={faHandPointer} />
              <span data-testid="filedropzone-placeholder">
                {boxPlaceholder ? boxPlaceholder : t('inputs.FileDropZone.text.1')}
              </span>
            </>
          ) : (
            <>
              <FontAwesomeIcon className="text-xl lg:text-3xl mr-2" icon={faSquirtyImages} />
              <span data-testid="filedropzone-placeholder">
                {boxPlaceholder ? boxPlaceholder : t('inputs.FileDropZone.text.2')}
              </span>
            </>
          )}
        </h3>
      </div>
      {showLegacySupport && (
        <button type="button" onClick={open}>
          {t('inputs.FileDropZone.text.legacy')}
        </button>
      )}
      {showThumbs && <aside className="flex flex-row flex-wrap mt-4">{thumbs}</aside>}
    </div>
  );
};
