import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensorFile } from 'utils/types/SensorFile';

export const useAlertFiles = (alertId: string) => {
  const queryKey = ['useAlertFiles', alertId];

  const {
    data: files,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alertId}/files`),
    select: (files: any[]) => files.map(parseSensorFile),
    enabled: !!alertId,
  });

  return {
    files,
    isPending: isPending || isLoading || isFetching,
  };
};
