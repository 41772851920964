import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { useSensorsBulk } from 'utils/hooks/data/sensors-bulk/useSensorsBulk';
import HookOptions from 'utils/types/HookOptions';
import Sensor from 'utils/types/Sensor';
import SensorStats from 'utils/types/SensorStats';

type SensorStatsResult = {
  [hardwareId: string]: {
    [column: string]: {
      mean: number;
      min: number;
      max: number;
    };
  };
};

export const useSensorsBulkStats = (
  sensorIds: string[],
  {
    fromTimestamp = undefined,
    toTimestamp = undefined,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
  } = {},
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp!);
  toTimestamp = floorToLatest5Minutes(toTimestamp!);

  const queryKey = ['useSensorsBulkStats', sensorIds, fromTimestamp, toTimestamp];

  const {
    data: statsRaw,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk/stats', {
        sensor_ids: sensorIds,
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
      }),
    select: (statsRaw: any) => statsRaw as SensorStatsResult,
    enabled: enableGet && sensorIds && sensorIds.length > 0 && !!fromTimestamp && !!toTimestamp,
  });

  // Get sensors
  const { sensors } = useSensorsBulk(sensorIds);

  // Parse stats
  const stats = useMemo(() => {
    if (!statsRaw || !sensors) return [];

    const hardwareId2Sensor: { [key: string]: Sensor } = sensors?.reduce(
      (o: any, sensor: Sensor) => ({ ...o, [sensor.hardware_id]: sensor }),
      {},
    );

    return Object.entries(statsRaw)
      .map(([hardwareId, sensorStats]) => {
        const sensor = hardwareId2Sensor[hardwareId];
        if (!sensorStats) return { sensor } as SensorStats;
        (sensorStats as SensorStats).sensor = sensor;
        return sensorStats as SensorStats;
      })
      .filter(sensorStats => sensorStats.sensor);
  }, [statsRaw, sensors]);

  return {
    stats,
    isPending: isPending || isLoading || isFetching,
  };
};
