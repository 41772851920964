import { RiskScoreState } from 'utils/enums';
import MoistureState from 'utils/enums/MoistureState';
import SignalStrengthState from 'utils/enums/SignalStrengthState';

export const moisture2State = (val?: number, isTransmissionInvalid?: boolean) => {
  if (isTransmissionInvalid) {
    return MoistureState.INVALID;
  }
  if (!val) {
    return MoistureState.INACTIVE;
  }
  if (val >= 0 && val < 10) {
    return MoistureState.MOISTURE_0_10;
  }
  if (val >= 10 && val < 15) {
    return MoistureState.MOISTURE_10_15;
  }
  if (val >= 15 && val < 20) {
    return MoistureState.MOISTURE_15_20;
  }
  if (val >= 20 && val < 25) {
    return MoistureState.MOISTURE_20_25;
  }
  if (val >= 25 && val < 30) {
    return MoistureState.MOISTURE_25_30;
  }
  if (val >= 30 && val < 35) {
    return MoistureState.MOISTURE_30_35;
  }
  if (val >= 35 && val < 40) {
    return MoistureState.MOISTURE_35_40;
  } else {
    return MoistureState.MOISTURE_40_100;
  }
};

export const signalStrengthToState = (val?: number) => {
  if (!val) {
    return SignalStrengthState.INACTIVE;
  }
  if (val === 7) {
    return SignalStrengthState.VERY_GOOD;
  }
  if (val === 8) {
    return SignalStrengthState.GOOD;
  }
  if (val === 9) {
    return SignalStrengthState.FAIR;
  }
  if (val === 10) {
    return SignalStrengthState.MODERATE;
  }
  if (val === 11) {
    return SignalStrengthState.POOR;
  }
  if (val === 12) {
    return SignalStrengthState.VERY_POOR;
  } else {
    return SignalStrengthState.UNKNOWN;
  }
};

export const riskScoreToState = (val?: number) => {
  if (typeof val !== 'number') return RiskScoreState.UNKNOWN;
  if (val < 1) return RiskScoreState.RISK_SCORE_VERY_LOW;
  if (val < 2) return RiskScoreState.RISK_SCORE_LOW;
  if (val < 3) return RiskScoreState.RISK_SCORE_MODERATE;
  if (val < 4) return RiskScoreState.RISK_SCORE_HIGH;
  return RiskScoreState.RISK_SCORE_VERY_HIGH;
};
