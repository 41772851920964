import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunctionNonStrict } from 'i18next';
import { SetStateAction } from 'react';

import {
  ActivityStateBadge,
  HumidityStateBadge,
  MoistureStateBadge,
  MoldGrowthRiskStateBadge,
  SignalStrengthStateBadge,
  TemperatureStateBadge,
} from 'components';
import {
  SensorTagCasingBadge,
  SensorTagClimateBadge,
  SensorTagComplementaryElementBadge,
  SensorTagConstructionPhaseBadge,
  SensorTagConstructionPrinciplesBadge,
  SensorTagInsulationBadge,
  SensorTagInsulationTypeBadge,
  SensorTagMeasurementPlacementBadge,
  SensorTagOrientationBadge,
  SensorTagPrimaryElementCategoryBadge,
  SensorTagPrimaryElementTypeBadge,
  SensorTagRoomTypeBadge,
  SensorTagScrewTypeBadge,
  SensorTagUsageBadge,
  SensorTagVentilationBadge,
  SensorTagVerticalPlacementBadge,
} from 'components/badges/SensorTagBadges';
import EditOnHoverText from 'components/EditOnHoverText';
import SensorLink from 'components/SensorLink';
import SensorUsersInlineList from 'components/sensors/SensorUsersInlineList';
import LatestTransmissionStatus from 'components/signals/LatestTransmissionStatus';
import {
  AlarmCell,
  BlueprintCell,
  MissingTagCell,
  RemoveFromGroupCell,
} from 'components/tables/SensorsPaginatedTable/components';
import { SensorTableData } from 'components/tables/SensorsPaginatedTable/helpers/types';
import SensorThumbnail from 'components/thumbnails/SensorThumbnail';
import { lg } from 'utils/breakpoints';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import {
  getActivityStateText,
  getHumidityStateText,
  getMoistureStateText,
  getMoldGrowthRiskStateText,
  getSignalStrengthStateText,
  getTemperatureStateText,
} from 'utils/sensor/texts';
import {
  sortingFnActivityState,
  sortingFnDateTime,
  sortingFnHumidityState,
  sortingFnMoldGrowthRiskState,
  sortingFnSensorState,
  sortingFnSignalStrengthState,
  sortingFnTemperatureState,
} from 'utils/tables/sort-functions';
import {
  getSensorTagCasingTextShort,
  getSensorTagClimateTextShort,
  getSensorTagComplementaryElementTextShort,
  getSensorTagConstructionPhaseTextShort,
  getSensorTagConstructionPrinciplesTextShort,
  getSensorTagInsulationTextShort,
  getSensorTagInsulationTypeTextShort,
  getSensorTagMeasurementPlacementTextShort,
  getSensorTagOrientationTextShort,
  getSensorTagPrimaryElementCategoryTextShort,
  getSensorTagPrimaryElementTypeTextShort,
  getSensorTagRoomTypeTextShort,
  getSensorTagScrewTypeTextShort,
  getSensorTagUsageTextShort,
  getSensorTagVentilationTextShort,
  getSensorTagVerticalPlacementTextShort,
} from 'utils/texts/sensor-tags';
import Sensor from 'utils/types/Sensor';

export const configureColumns = ({
  fromSensorGroupId,
  onDeleteClick,
  onTagClick,
  timeFrom,
  timeTo,
  width,
  setActiveSensorId,
  setShowUpdateModal,
  setShowSensorTagsModal,
  setShowAlarmRulesModal,
  setShowSensorDownlinksModal,
  t,
}: {
  fromSensorGroupId: string | undefined;
  onDeleteClick: ((sensor: Sensor) => void) | undefined;
  onTagClick: (sensorId: string) => void;
  timeFrom: Date;
  timeTo: Date;
  width: number;
  setActiveSensorId: (value: SetStateAction<string | undefined>) => void;
  setShowUpdateModal: (value: SetStateAction<boolean>) => void;
  setShowSensorTagsModal: (value: SetStateAction<boolean>) => void;
  setShowAlarmRulesModal: (value: SetStateAction<boolean>) => void;
  setShowSensorDownlinksModal: (value: SetStateAction<boolean>) => void;
  t: TFunctionNonStrict<'components', undefined>;
}) => {
  const columnHelper = createColumnHelper<SensorTableData>();

  const tagClassNames = 'text-xs inline-block cursor-pointer hover:shadow';

  return [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => t('components:tables.SensorsPaginatedTable.columns.name'),
      size: width > lg ? 350 : 250,
      minSize: 250,
      enableColumnFilter: false,
      enableHiding: false,
      enablePinning: true,
      meta: {
        className: 'flex-grow text-left',
      },
      cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <div className="flex items-center justify-start max-h-full gap-2">
          <SensorLink
            className="min-w-[80px]"
            sensorId={original.id}
            fromSensorGroupId={fromSensorGroupId}
            timeFrom={timeFrom}
            timeTo={timeTo}
          >
            <SensorThumbnail
              sensorId={original.id}
              sensorFileId={original.sensor_file_id}
              height={34}
            />
          </SensorLink>

          <p className="text-brand-green underline hover:text-blue-500">
            <SensorLink
              sensorId={original.id}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
            >
              <span className="mb-2">
                {original.name
                  ? `${original.name}`
                  : t('components:tables.SensorsPaginatedTable.columns.unknown')}
              </span>
            </SensorLink>
          </p>
        </div>
      ),
    }),

    columnHelper.accessor('description', {
      id: 'description',
      header: () => t('components:tables.SensorsPaginatedTable.columns.description'),
      size: width > lg ? 250 : 150,
      sortingFn: 'basic',
      enableColumnFilter: false,
      cell: ({
        getValue,
        cell: {
          row: { original },
        },
      }) => (
        <EditOnHoverText
          text={
            getValue() ||
            t('components:tables.SensorsPaginatedTable.columns.descriptionPlaceholder')
          }
          onClick={() => {
            setActiveSensorId(original.id);
            setShowUpdateModal(true);
          }}
        />
      ),
    }),

    columnHelper.accessor('moisture_state', {
      id: 'moisture_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.moisture'),
      sortingFn: sortingFnSensorState,
      filterFn: 'equalsString',
      size: 190,
      meta: {
        filterVariant: 'select',
        transform: getMoistureStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <MoistureStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('activity_state', {
      id: 'activity_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.activity'),
      sortingFn: sortingFnActivityState,
      filterFn: 'equalsString',
      size: 190,
      meta: {
        filterVariant: 'select',
        transform: getActivityStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <ActivityStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('humidity_state', {
      id: 'humidity_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.humidity'),
      sortingFn: sortingFnHumidityState,
      filterFn: 'equalsString',
      size: 190,
      meta: {
        filterVariant: 'select',
        transform: getHumidityStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <HumidityStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('mold_growth_risk_state', {
      id: 'mold_growth_risk_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.mold_growth_risk'),
      sortingFn: sortingFnMoldGrowthRiskState,
      filterFn: 'equalsString',
      size: 190,
      meta: {
        filterVariant: 'select',
        transform: getMoldGrowthRiskStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <MoldGrowthRiskStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('temperature_state', {
      id: 'temperature_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.temperature'),
      sortingFn: sortingFnTemperatureState,
      filterFn: 'equalsString',
      size: 190,
      meta: {
        filterVariant: 'select',
        transform: getTemperatureStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <TemperatureStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('signal_strength_state', {
      id: 'signal_strength_state',
      header: () => t('components:tables.SensorsPaginatedTable.columns.signal_strength'),
      sortingFn: sortingFnSignalStrengthState,
      size: 190,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSignalStrengthStateText,
        className: 'text-left',
      },
      cell: ({ getValue }) => <SignalStrengthStateBadge state={getValue()} />,
    }),

    columnHelper.accessor('send_alarms', {
      id: 'send_alarms',
      header: () => t('components:tables.SensorsPaginatedTable.columns.alarms'),
      sortingFn: 'basic',
      enableColumnFilter: false,
      meta: {
        className: 'text-left',
      },
      size: 150,
      cell: ({
        getValue,
        cell: {
          row: { original },
        },
      }) => (
        <AlarmCell
          id={original.id}
          checked={!!getValue()}
          onEditClick={() => {
            setActiveSensorId(original.id);
            setShowAlarmRulesModal(true);
          }}
          showEditButton={!!getValue()}
        />
      ),
    }),

    columnHelper.accessor('blueprint', {
      id: 'blueprint',
      header: () => t('components:tables.SensorsPaginatedTable.columns.blueprint'),
      enableColumnFilter: false,
      size: 200,
      cell: ({
        cell: {
          row: { original },
        },
      }) => <BlueprintCell sensorId={original.id} />,
    }),

    columnHelper.accessor('access_links', {
      id: 'access_links',
      header: () => t('components:tables.SensorsPaginatedTable.columns.access_links'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 150,
      cell: ({
        cell: {
          row: { original },
        },
      }) => <SensorUsersInlineList sensorId={original.id} />,
    }),

    columnHelper.accessor('setup_completed_at', {
      id: 'setup_completed_at',
      header: () => t('components:tables.SensorsPaginatedTable.columns.setup'),
      enableColumnFilter: false,
      sortingFn: sortingFnDateTime,
      size: 200,
      cell: ({ getValue }) => (
        <span data-tooltip-content={dateToLocaleString(getValue())} data-tooltip-id="route-tooltip">
          {dateToPrettyDate(getValue())}
        </span>
      ),
    }),

    columnHelper.accessor('primary_element_category', {
      id: 'primary_element_category',
      header: () => t('components:tables.SensorsPaginatedTable.columns.category'),
      size: 300,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagPrimaryElementCategoryTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagPrimaryElementCategoryBadge
            className={tagClassNames}
            sensorTagPrimaryElementCategory={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('primary_element_type', {
      id: 'primary_element_type',
      header: () => t('components:tables.SensorsPaginatedTable.columns.type'),
      size: 275,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagPrimaryElementTypeTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagPrimaryElementTypeBadge
            className={tagClassNames}
            sensorTagPrimaryElementType={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('complementary_element', {
      id: 'complementary_element',
      header: () => t('components:tables.SensorsPaginatedTable.columns.complementary'),
      size: 300,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagComplementaryElementTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagComplementaryElementBadge
            className={tagClassNames}
            sensorTagComplementaryElement={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('climate', {
      id: 'climate',
      header: () => t('components:tables.SensorsPaginatedTable.columns.climate'),
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagClimateTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagClimateBadge
            className={tagClassNames}
            sensorTagClimate={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('ventilation', {
      id: 'ventilation',
      header: () => t('components:tables.SensorsPaginatedTable.columns.ventilation'),
      size: 200,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagVentilationTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagVentilationBadge
            className={tagClassNames}
            sensorTagVentilation={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('construction_phase', {
      id: 'construction_phase',
      header: () => t('components:tables.SensorsPaginatedTable.columns.construction_phase'),
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagConstructionPhaseTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagConstructionPhaseBadge
            className={tagClassNames}
            sensorTagConstructionPhase={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('construction_principles', {
      id: 'construction_principles',
      header: () => t('components:tables.SensorsPaginatedTable.columns.construction_principles'),
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagConstructionPrinciplesTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagConstructionPrinciplesBadge
            className={tagClassNames}
            sensorTagConstructionPrinciples={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('insulation', {
      id: 'insulation',
      header: () => t('components:tables.SensorsPaginatedTable.columns.insulation'),
      size: 225,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagInsulationTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagInsulationBadge
            className={tagClassNames}
            sensorTagInsulation={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('insulation_type', {
      id: 'insulation_type',
      header: () => t('components:tables.SensorsPaginatedTable.columns.insulation_type'),
      size: 225,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagInsulationTypeTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagInsulationTypeBadge
            className={tagClassNames}
            sensorTagInsulationType={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('room_type', {
      id: 'room_type',
      header: () => t('components:tables.SensorsPaginatedTable.columns.roomType'),
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagRoomTypeTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagRoomTypeBadge
            className={tagClassNames}
            sensorTagRoomType={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('orientation', {
      id: 'orientation',
      header: () => t('components:tables.SensorsPaginatedTable.columns.orientation'),
      size: 200,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagOrientationTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagOrientationBadge
            className={tagClassNames}
            sensorTagOrientation={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('vertical_placement', {
      header: () => t('components:tables.SensorsPaginatedTable.columns.verticalPlacement'),
      id: 'vertical_placement',
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagVerticalPlacementTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagVerticalPlacementBadge
            className="text-xs inline-block"
            sensorTagVerticalPlacement={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('casing', {
      header: () => t('components:tables.SensorsPaginatedTable.columns.casing'),
      id: 'casing',
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagCasingTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagCasingBadge
            className="text-xs inline-block"
            sensorTagCasing={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('screw_type', {
      header: () => t('components:tables.SensorsPaginatedTable.columns.screwType'),
      id: 'screw_type',
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagScrewTypeTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagScrewTypeBadge
            className="text-xs inline-block"
            sensorTagScrewType={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('usage', {
      header: () => t('components:tables.SensorsPaginatedTable.columns.usage'),
      id: 'usage',
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagUsageTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagUsageBadge
            className="text-xs inline-block"
            sensorTagUsage={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('measurement_placement', {
      header: () => t('components:tables.SensorsPaginatedTable.columns.measurementPlacement'),
      id: 'measurement_placement',
      size: 250,
      filterFn: 'equalsString',
      meta: {
        filterVariant: 'select',
        transform: getSensorTagMeasurementPlacementTextShort,
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return value ? (
          <SensorTagMeasurementPlacementBadge
            className="text-xs inline-block"
            sensorTagMeasurementPlacement={value}
            onClick={async () => onTagClick(original.id)}
            useColors
          />
        ) : (
          <MissingTagCell
            sensorId={original.id}
            setActiveSensorId={setActiveSensorId}
            setShowSensorTagsModal={setShowSensorTagsModal}
          />
        );
      },
    }),

    columnHelper.accessor('ttn_dev_id', {
      id: 'ttn_dev_id',
      header: () => t('components:tables.SensorsPaginatedTable.columns.ttn_dev_id'),
      enableColumnFilter: false,
    }),

    columnHelper.accessor('_device_number', {
      id: '_device_number',
      header: () => t('components:tables.SensorsPaginatedTable.columns.device_number'),
      size: 180,
      enableColumnFilter: false,
      cell: ({ getValue }) => <samp>{getValue()}</samp>,
    }),

    columnHelper.accessor('hardware_id', {
      id: 'hardware_id',
      header: () => t('components:tables.SensorsPaginatedTable.columns.hardware_id'),
      size: 200,
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ getValue }) => <samp>{getValue()}</samp>,
    }),

    columnHelper.accessor('heartbeat_interval', {
      id: 'heartbeat_interval',
      header: () => t('components:tables.SensorsPaginatedTable.columns.heartbeat_interval.1'),
      size: 180,
      enableColumnFilter: false,
      meta: {
        className: 'text-left',
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        return typeof value === 'number' ? (
          <EditOnHoverText
            text={`${value}. min`}
            data-tooltip-content={t(
              'components:tables.SensorsPaginatedTable.columns.heartbeat_interval.2',
              {
                getValue,
              },
            )}
            data-tooltip-id="route-tooltip"
            onClick={() => {
              setActiveSensorId(original.id);
              setShowSensorDownlinksModal(true);
            }}
          />
        ) : (
          <></>
        );
      },
    }),

    columnHelper.accessor('latest_transmission_received_at', {
      id: 'latest_transmission_received_at',
      header: () =>
        t('components:tables.SensorsPaginatedTable.columns.latest_transmission_received_at'),
      sortingFn: sortingFnDateTime,
      enableColumnFilter: false,
      size: width > lg ? 210 : 170,
      cell: ({ getValue }) => (
        <LatestTransmissionStatus latestTransmissionReceivedAt={getValue()} />
      ),
    }),

    columnHelper.accessor('_remove_from_group', {
      id: '_remove_from_group',
      header: '',
      size: 200,
      enableSorting: false,
      enableColumnFilter: false,
      enableHiding: false,
      enablePinning: true,
      enableResizing: false,
      meta: {
        className: 'text-center',
        hidden: !onDeleteClick,
        hideFromToggleModal: true,
      },
      cell: ({
        cell: {
          row: { original },
        },
      }) =>
        onDeleteClick ? (
          <RemoveFromGroupCell sensor={original} onDeleteClick={onDeleteClick} />
        ) : (
          <></>
        ),
    }),

    columnHelper.accessor('id', {
      header: '',
      id: 'id',
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      enablePinning: true,
      enableResizing: false,
      meta: {
        className: 'text-center',
        hideFromToggleModal: true,
      },
      size: 75,
      cell: ({ getValue }) => (
        <div>
          <FontAwesomeIcon
            onClick={() => {
              setActiveSensorId(getValue());
              setShowUpdateModal(true);
            }}
            data-tooltip-content={t(
              'components:tables.SensorsPaginatedTable.columns.editSensor.tooltip',
            )}
            data-tooltip-id="route-tooltip"
            className="text-brand-green-light-3 hover:text-brand-blue w-5 h-5 cursor-pointer"
            icon={faEdit}
          />
        </div>
      ),
    }),
  ];
};
