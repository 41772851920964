import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FormTextInput, SensorTagSelectInput, SubmittingButton } from 'components';
import Form from 'components/forms/Form';
import { getSensorTagIcon } from 'components/icons';
import RouterPromptModal from 'components/modals/RouterPromptModal';
import {
  SensorTagsEnvironment,
  SensorTagsPrimaryPlacement,
  SensorTagsRoomAndOrientation,
  SensorTagsTheSensor,
} from 'components/sensors/tags';
import Spinner from 'components/Spinner';
import {
  SensorTagCasing,
  SensorTagClimate,
  SensorTagComplementaryElement,
  SensorTagConstructionPhase,
  SensorTagConstructionPrinciples,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagMeasurementPlacement,
  SensorTagOrientation,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
  SensorTagRoomType,
  SensorTagScrewType,
  SensorTagUsage,
  SensorTagVentilation,
  SensorTagVerticalPlacement,
} from 'utils/enums';
import { useIsMounted } from 'utils/hooks';
import { useSensorTagPredictions, useUtilsPossibleTags } from 'utils/hooks/data';
import { getNumericValues } from 'utils/numeric-enums';
import {
  getSensorTagCasingText,
  getSensorTagClimateText,
  getSensorTagComplementaryElementText,
  getSensorTagConstructionPhaseText,
  getSensorTagConstructionPrinciplesText,
  getSensorTagInsulationText,
  getSensorTagInsulationTypeText,
  getSensorTagMeasurementPlacementText,
  getSensorTagOrientationText,
  getSensorTagPrimaryElementCategoryText,
  getSensorTagPrimaryElementTypeText,
  getSensorTagRoomTypeText,
  getSensorTagScrewTypeText,
  getSensorTagUsageText,
  getSensorTagVentilationText,
  getSensorTagVerticalPlacementText,
} from 'utils/texts/sensor-tags';
import getSensorTagText from 'utils/texts/SensorTags';
import { SensorTagProbabilities } from 'utils/types/SensorTagPredictions';
import SensorTags, {
  SensorTag,
  SensorTagDescriptionName,
  SensorTagName,
} from 'utils/types/SensorTags';

const SensorTagsForm: React.FC<{
  onSubmit: (sensorTags: SensorTags) => Promise<void>;
  sensorTags?: Partial<SensorTags>;
  showBadges?: boolean;
  customSubmitComponent?: JSX.Element;
  buttonText?: string;
  showPrimaryPlacementTags?: boolean;
  showEnvironmentTags?: boolean;
  showRoomAndOrientationTags?: boolean;
  showTheSensorTags?: boolean;
  showCasingTag?: boolean;
  showUsageTag?: boolean;
  showMeasurementPlacementTag?: boolean;
  showTitles?: boolean;
  hideSubmitButton?: boolean;
  sensorIdForPredictions?: string;
  isPending?: boolean;
}> = ({
  onSubmit,
  sensorTags,
  customSubmitComponent,
  buttonText,
  showBadges = false,
  showPrimaryPlacementTags = false,
  showEnvironmentTags = false,
  showRoomAndOrientationTags = false,
  showTheSensorTags = false,
  showCasingTag = false,
  showUsageTag = false,
  showMeasurementPlacementTag = false,
  showTitles = false,
  hideSubmitButton = false,
  sensorIdForPredictions,
  isPending,
}) => {
  const [selectedSensorTags, setSelectedSensorTags] = useState<Partial<SensorTags>>();

  const isMounted = useIsMounted();

  const { possibleSensorTags, isFetched: possibleSensorTagsIsFetched } =
    useUtilsPossibleTags(selectedSensorTags);
  const { sensorTagPredictions } = useSensorTagPredictions(
    sensorIdForPredictions,
    selectedSensorTags,
  );

  const { t } = useTranslation('components');

  const noOptionsTooltipText = t('forms.SensorTagsForm.noOptionsTooltipText');
  const selectOptionTooltipText = t('forms.SensorTagsForm.selectOptionTooltipText');

  const sensorTagsClassName = 'text-xs';
  const sensorTagsDivClassName = 'flex flex-wrap gap-1';

  useEffect(() => {
    setSelectedSensorTags(sensorTags);
  }, [sensorTags]);

  const yupEnumProperty = (enumType: any) =>
    yup
      .number()
      .integer()
      .positive()
      .nullable()
      .oneOf([...getNumericValues(enumType), null]);

  const schemaObj = {
    primary_element_category: yupEnumProperty(SensorTagPrimaryElementCategory),
    primary_element_category_description: yup.string().nullable(),
    primary_element_type: yupEnumProperty(SensorTagPrimaryElementType),
    primary_element_type_description: yup.string().nullable(),
    construction_principles: yupEnumProperty(SensorTagConstructionPrinciples),
    complementary_element: yupEnumProperty(SensorTagComplementaryElement),
    complementary_element_description: yup.string().nullable(),
    orientation: yupEnumProperty(SensorTagOrientation),
    room_type: yupEnumProperty(SensorTagRoomType),
    room_type_description: yup.string().nullable(),
    vertical_placement: yupEnumProperty(SensorTagVerticalPlacement),
    casing: yupEnumProperty(SensorTagCasing),
    climate: yupEnumProperty(SensorTagClimate),
    construction_phase: yupEnumProperty(SensorTagConstructionPhase),
    insulation: yupEnumProperty(SensorTagInsulation),
    insulation_type: yupEnumProperty(SensorTagInsulationType),
    screw_type: yupEnumProperty(SensorTagScrewType),
    ventilation: yupEnumProperty(SensorTagVentilation),
    usage: yupEnumProperty(SensorTagUsage),
    measurement_placement: yupEnumProperty(SensorTagMeasurementPlacement),
  };

  // Write about BIM7AA and add link
  // Write about the reference values that will be available when chosing tags

  return (
    <Form<SensorTags>
      schemaObj={schemaObj}
      onSubmit={onSubmit}
      values={sensorTags}
      body={({ handleSubmit, setFieldValue, values, touched, dirty, isSubmitting }) => {
        const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
          // Cast selected value to integer (or null)
          const name = event.target.name;
          const value = event.target.value;
          const valueParsed = parseInt(value, 10);
          const newValue = isNaN(valueParsed) ? null : valueParsed;
          setFieldValue(name, newValue, true);
          const newValues = { ...values, [name]: newValue } as Partial<SensorTags>;
          setSelectedSensorTags(newValues);
        };

        const appendElement = (name: SensorTagName) => {
          const isTouched = touched[name];
          const hasValue = values[name];
          const options = possibleSensorTags[name];
          const noOptions = options ? options.length === 0 : false;
          if (noOptions)
            return (
              <FontAwesomeIcon
                className={classNames('text-brand-gray-light-2 w-5 h-5')}
                icon={faCheck}
                data-tooltip-content={noOptionsTooltipText}
                data-tooltip-id="route-tooltip"
              />
            );

          if (isSubmitting) return <Spinner role="status" aria-hidden="true" />;
          if (!hasValue || isTouched)
            return (
              <FontAwesomeIcon
                className={classNames('text-brand-green w-5 h-5')}
                icon={faExclamationCircle}
                data-tooltip-content={selectOptionTooltipText}
                data-tooltip-id="route-tooltip"
              />
            );
          return <FontAwesomeIcon className="text-brand-green-light-2 w-5 h-5" icon={faCheck} />;
        };

        const selectInputArguments = <T extends SensorTag>(
          name: SensorTagName,
          placeholder: string,
        ) => {
          const options = possibleSensorTags[name] || []; // TODO: Remove when possible tags endpoint is updated
          const noOptions = options.length === 0;
          const sensorTagIcon = getSensorTagIcon(name);
          const sensorTagProbabilities = sensorTagPredictions[name] as SensorTagProbabilities<T>;

          return {
            name,
            label: getSensorTagText(name),
            append: appendElement(name),
            prepend: <FontAwesomeIcon className="w-5 h-5" icon={sensorTagIcon} />,
            prependTooltip: noOptions ? undefined : placeholder,
            options,
            placeholder,
            tooltip: noOptions ? noOptionsTooltipText : undefined,
            disabled: noOptions,
            selectedValue: values[name] as T,
            sensorTagProbabilities,
          };
        };

        const appendElementText = (name: SensorTagDescriptionName) => {
          const hasValue = !!values[name];
          const isTouched = !!touched[name];
          if (!hasValue || isTouched)
            return (
              <FontAwesomeIcon className="text-brand-green w-5 h-5" icon={faExclamationCircle} />
            );
          return <FontAwesomeIcon className="text-brand-green-light-2 w-5 h-5" icon={faCheck} />;
        };

        const textInputArguments = (name: SensorTagDescriptionName, placeholder: string) => ({
          name,
          className: 'mb-8',
          append: appendElementText(name),
          prepend: <FontAwesomeIcon className="w-5 h-5" icon={getSensorTagIcon(name)} />,
          prependTooltip: placeholder,
          placeholder,
          inputClassName: 'pl-12',
          appendClassName: 'translate-x-5',
        });

        if (possibleSensorTagsIsFetched && isMounted()) {
          Object.entries(possibleSensorTags).forEach(
            ([name, possibleValues]: [string, number[]]) => {
              const currentValue = values[name as SensorTagName] as number;
              if (currentValue) {
                if (possibleValues.length === 0) {
                  setFieldValue(name, null);
                } else if (!possibleValues.includes(currentValue)) {
                  setFieldValue(name, null);
                }
              }
            },
          );
        }

        return (
          <>
            <form id="sensorTagsForm" noValidate onSubmit={handleSubmit}>
              {/* Primary location tags */}
              {showPrimaryPlacementTags && (
                <div className="mb-5 lg:mb-10">
                  <div className="mb-3">
                    {showTitles && (
                      <h2 className="mb-3">{t('forms.SensorTagsForm.location.title')}</h2>
                    )}
                    {showBadges && (
                      <div className={sensorTagsDivClassName}>
                        <SensorTagsPrimaryPlacement
                          className={sensorTagsClassName}
                          sensorTagPrimaryElementCategory={values.primary_element_category}
                          sensorTagPrimaryElementType={values.primary_element_type}
                          sensorTagComplementaryElement={values.complementary_element}
                          useColors
                        />
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagPrimaryElementCategory>
                      getEnumTypeText={getSensorTagPrimaryElementCategoryText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagPrimaryElementCategory>(
                        'primary_element_category',
                        t('forms.SensorTagsForm.primary_element_category.placeholder'),
                      )}
                    />
                  </div>

                  {values.primary_element_category ===
                    SensorTagPrimaryElementCategory.OtherPrimary && (
                    <FormTextInput
                      label={t('forms.SensorTagsForm.primary_element_category_description.label')}
                      {...textInputArguments(
                        'primary_element_category_description',
                        t('forms.SensorTagsForm.primary_element_category_description.placeholder'),
                      )}
                    />
                  )}

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagPrimaryElementType>
                      getEnumTypeText={getSensorTagPrimaryElementTypeText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagPrimaryElementType>(
                        'primary_element_type',
                        t('forms.SensorTagsForm.primary_element_type.placeholder'),
                      )}
                    />

                    <SensorTagSelectInput<SensorTagConstructionPrinciples>
                      getEnumTypeText={getSensorTagConstructionPrinciplesText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagConstructionPrinciples>(
                        'construction_principles',
                        t('forms.SensorTagsForm.construction_principles.placeholder'),
                      )}
                    />
                  </div>

                  {values.primary_element_type === SensorTagPrimaryElementType.Other && (
                    <FormTextInput
                      label={t('forms.SensorTagsForm.primary_element_type_description.label')}
                      {...textInputArguments(
                        'primary_element_type_description',
                        t('forms.SensorTagsForm.primary_element_type_description.placeholder'),
                      )}
                    />
                  )}

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagComplementaryElement>
                      getEnumTypeText={getSensorTagComplementaryElementText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagComplementaryElement>(
                        'complementary_element',
                        t('forms.SensorTagsForm.complementary_element.placeholder'),
                      )}
                    />
                  </div>

                  {values.complementary_element === SensorTagComplementaryElement.Other && (
                    <FormTextInput
                      label={t('forms.SensorTagsForm.complementary_element_description.label')}
                      {...textInputArguments(
                        'complementary_element_description',
                        t('forms.SensorTagsForm.complementary_element_description.placeholder'),
                      )}
                    />
                  )}
                </div>
              )}

              {showEnvironmentTags && (
                <div className="mb-5 lg:mb-10">
                  <div
                    className={classNames('mb-3', {
                      'pt-2': showPrimaryPlacementTags && !showTitles,
                    })}
                  >
                    {showTitles && (
                      <h2 className="mb-3">
                        {t('forms.SensorTagsForm.environmentAndVentilation.title')}
                      </h2>
                    )}
                    {showBadges && (
                      <div className={sensorTagsDivClassName}>
                        <SensorTagsEnvironment
                          className={sensorTagsClassName}
                          sensorTagClimate={values.climate}
                          sensorTagConstructionPhase={values.construction_phase}
                          sensorTagInsulation={values.insulation}
                          sensorTagInsulationType={values.insulation_type}
                          sensorTagVentilation={values.ventilation}
                          useColors
                        />
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagClimate>
                      getEnumTypeText={getSensorTagClimateText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagClimate>(
                        'climate',
                        t('forms.SensorTagsForm.climate.placeholder'),
                      )}
                    />
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagInsulation>
                      getEnumTypeText={getSensorTagInsulationText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagInsulation>(
                        'insulation',
                        t('forms.SensorTagsForm.insulation.placeholder'),
                      )}
                    />

                    <SensorTagSelectInput<SensorTagInsulationType>
                      getEnumTypeText={getSensorTagInsulationTypeText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagInsulationType>(
                        'insulation_type',
                        t('forms.SensorTagsForm.insulation_type.placeholder'),
                      )}
                    />
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagVentilation>
                      getEnumTypeText={getSensorTagVentilationText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagVentilation>(
                        'ventilation',
                        t('forms.SensorTagsForm.ventilation.placeholder'),
                      )}
                    />

                    <SensorTagSelectInput<SensorTagConstructionPhase>
                      getEnumTypeText={getSensorTagConstructionPhaseText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagConstructionPhase>(
                        'construction_phase',
                        t('forms.SensorTagsForm.construction_phase.placeholder'),
                      )}
                    />
                  </div>
                </div>
              )}

              {showRoomAndOrientationTags && (
                <div className="mb-5 lg:mb-10">
                  {/* Custom tags */}
                  <div
                    className={classNames('mb-3', {
                      'pt-2': (showPrimaryPlacementTags || showEnvironmentTags) && !showTitles,
                    })}
                  >
                    {showTitles && (
                      <h2 className="mb-3">
                        {t('forms.SensorTagsForm.roomOrientationAndVerticalPlacement.title')}
                      </h2>
                    )}
                    {showBadges && (
                      <div className={sensorTagsDivClassName}>
                        <SensorTagsRoomAndOrientation
                          className={sensorTagsClassName}
                          sensorTagRoomType={values.room_type}
                          sensorTagOrientation={values.orientation}
                          sensorTagVerticalPlacement={values.vertical_placement}
                          useColors
                        />
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagRoomType>
                      getEnumTypeText={getSensorTagRoomTypeText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagRoomType>(
                        'room_type',
                        t('forms.SensorTagsForm.room_type.placeholder'),
                      )}
                    />
                  </div>

                  {values.room_type === SensorTagRoomType.Other && (
                    <FormTextInput
                      label={t('forms.SensorTagsForm.room_type_description.label')}
                      {...textInputArguments(
                        'room_type_description',
                        t('forms.SensorTagsForm.room_type_description.placeholder'),
                      )}
                    />
                  )}

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagOrientation>
                      getEnumTypeText={getSensorTagOrientationText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagOrientation>(
                        'orientation',
                        t('forms.SensorTagsForm.orientation.placeholder'),
                      )}
                    />

                    <SensorTagSelectInput<SensorTagVerticalPlacement>
                      getEnumTypeText={getSensorTagVerticalPlacementText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagVerticalPlacement>(
                        'vertical_placement',
                        t('forms.SensorTagsForm.vertical_placement.placeholder'),
                      )}
                    />
                  </div>
                </div>
              )}

              {showTheSensorTags && (
                <div className="mb-5 lg:mb-8">
                  {/* Global tags */}
                  <div
                    className={classNames('mb-3', {
                      'pt-2':
                        (showPrimaryPlacementTags ||
                          showEnvironmentTags ||
                          showRoomAndOrientationTags) &&
                        !showTitles,
                    })}
                  >
                    {showTitles && (
                      <h2 className="mb-3">{t('forms.SensorTagsForm.sensorSpecifics.title')}</h2>
                    )}
                    {showBadges && (
                      <div className={sensorTagsDivClassName}>
                        <SensorTagsTheSensor
                          className={sensorTagsClassName}
                          sensorTagCasing={values.casing}
                          sensorTagScrewType={values.screw_type}
                          useColors
                        />
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8 mb-5 lg:mb-8">
                    <SensorTagSelectInput<SensorTagScrewType>
                      getEnumTypeText={getSensorTagScrewTypeText}
                      onChange={onChange}
                      {...selectInputArguments<SensorTagScrewType>(
                        'screw_type',
                        t('forms.SensorTagsForm.screw_type.placeholder'),
                      )}
                    />

                    {showCasingTag && (
                      <SensorTagSelectInput<SensorTagCasing>
                        getEnumTypeText={getSensorTagCasingText}
                        onChange={onChange}
                        {...selectInputArguments<SensorTagCasing>(
                          'casing',
                          t('forms.SensorTagsForm.casing.placeholder'),
                        )}
                      />
                    )}

                    {showUsageTag && (
                      <SensorTagSelectInput<SensorTagUsage>
                        getEnumTypeText={getSensorTagUsageText}
                        onChange={onChange}
                        {...selectInputArguments<SensorTagUsage>(
                          'usage',
                          t('forms.SensorTagsForm.usage.placeholder'),
                        )}
                      />
                    )}

                    {showMeasurementPlacementTag && (
                      <SensorTagSelectInput<SensorTagMeasurementPlacement>
                        getEnumTypeText={getSensorTagMeasurementPlacementText}
                        onChange={onChange}
                        {...selectInputArguments<SensorTagMeasurementPlacement>(
                          'measurement_placement',
                          t('forms.SensorTagsForm.measurement_placement.placeholder'),
                        )}
                      />
                    )}
                  </div>
                </div>
              )}

              {!hideSubmitButton && (
                <>
                  {customSubmitComponent || (
                    <SubmittingButton
                      buttonText={buttonText || t('forms.SensorTagsForm.buttonText')}
                      submitting={isSubmitting}
                      disabled={isPending}
                    />
                  )}
                </>
              )}
            </form>
            <RouterPromptModal when={dirty} />
          </>
        );
      }}
    />
  );
};

export default SensorTagsForm;
